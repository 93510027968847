import DatePickerInput from 'ui/pickers/DatePickerInput';
import TextArea from 'ui/textArea';
import SimpleCheckbox from 'ui/checkbox';
import MainSidebar from 'ui/sidebar';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';
import { useStore } from 'store';
import { useState, useCallback, useEffect } from 'react';
import dayjs from 'dayjs';

const StopCooperationSidebar: React.FC = () => {
  const {
    showStopCooperationSidebar,
    setShowStopCooperationSidebar,
    stopCooperationDateError,
    setStopCooperationDateError,
    setShowStopCooperationPopup,
    setStopCooperationAllPositions,
    stopCooperationAllPositions,
  } = useFacilityReportStore();

  const [localStopCooperationComment, setLocalStopCooperationComment] = useState<string>();
  const [localStopCooperationDate, setLocalStopCooperationDate] = useState<dayjs.Dayjs | null>(null);

  const { stopCooperationComment, stopCooperationDate, setStopCooperationComment, setStopCooperationDate } = useStore();

  useEffect(() => {
    setLocalStopCooperationComment(stopCooperationComment);
    setLocalStopCooperationDate(stopCooperationDate);
  }, [stopCooperationComment, stopCooperationDate]);

  const onChangeStopCooperationDate = useCallback((value: dayjs.Dayjs | null) => {
    setLocalStopCooperationDate(value);
  }, []);

  const onChangeStopCooperationComment = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
      setLocalStopCooperationComment(value);
    },
    [],
  );

  const onChangeStopCooperationAllFacilities = useCallback(
    ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
      setStopCooperationAllPositions(checked);
    },
    [setStopCooperationAllPositions],
  );

  const onSaveChanges = useCallback(() => {
    if (localStopCooperationComment !== stopCooperationComment) {
      setStopCooperationComment(localStopCooperationComment);
    }
    if (!localStopCooperationDate?.isSame(stopCooperationDate)) {
      setStopCooperationDate(localStopCooperationDate);
    }
  }, [
    localStopCooperationComment,
    localStopCooperationDate,
    stopCooperationComment,
    stopCooperationDate,
    setStopCooperationComment,
    setStopCooperationDate,
  ]);

  const onShowChangeStatus = useCallback(() => {
    if (!localStopCooperationDate?.isValid()) {
      return setStopCooperationDateError('Обязательное поле');
    }
    setStopCooperationDateError('');
    setShowStopCooperationSidebar(false);
    setShowStopCooperationPopup(true);
    onSaveChanges();
  }, [
    localStopCooperationDate,
    setStopCooperationDateError,
    setShowStopCooperationSidebar,
    setShowStopCooperationPopup,
    onSaveChanges,
  ]);

  return (
    <MainSidebar
      show={showStopCooperationSidebar}
      setShow={setShowStopCooperationSidebar}
      title={'Прекратить сотрудничество'}
    >
      <div className={'flex flex-col gap-y-6'}>
        <DatePickerInput
          label={'Дата конца сотрудничества'}
          onChange={onChangeStopCooperationDate}
          value={localStopCooperationDate}
          error={stopCooperationDateError}
        />
        <TextArea
          style={{
            height: '75px',
          }}
          name={'stopCooperationComment'}
          divClassName={''}
          label={'Комментарий (если нужно)'}
          value={localStopCooperationComment}
          placeholder={'Оставьте комментарий'}
          onChange={onChangeStopCooperationComment}
        />
        <SimpleCheckbox
          label={'Прекратить сотрудничество по всем профессиям этого объекта'}
          checked={stopCooperationAllPositions}
          divClassName={'flex items-center'}
          name={'stopCooperationAllPositions'}
          onChange={onChangeStopCooperationAllFacilities}
        />
        <div className={'flex justify-end'}>
          <button className={'btn-primary_small'} onClick={onShowChangeStatus}>
            Изменить статус
          </button>
        </div>
      </div>
    </MainSidebar>
  );
};

export default StopCooperationSidebar;
