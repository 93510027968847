import MultiSelect from 'ui/multiSelect';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';
import { GetSupervisorWorkSheetQuery } from 'pages/facilityReport/api/queries/generated/GetSupervisorWorkSheet';
import { useMemo } from 'react';
import { getUserFIO } from 'utils/helpers/userFullName';

interface Props {
  filterData?: GetSupervisorWorkSheetQuery['getSupervisorWorkSheet']['filterData'];
}

const Selects: React.FC<Props> = ({ filterData }) => {
  const { userIds, positionIds, setField } = useFacilityReportStore();

  const userOptions = useMemo(() => {
    return filterData?.users.map(user => {
      return {
        value: user?.id,
        label: getUserFIO({ firstname: user.firstname, lastname: user.lastname, patronymic: user.patronymic }),
      };
    });
  }, [filterData]);

  const positionOptions = useMemo(() => {
    return filterData?.workPosts.map(workpost => {
      return {
        value: workpost.position?.id,
        label: workpost.position?.name,
      };
    });
  }, [filterData]);

  return (
    <>
      <MultiSelect
        divClassName="w-[200px]"
        placeholder="Выберите исполнителя"
        label=""
        onChange={selectedValues => setField('userIds', selectedValues)}
        value={userIds}
        name="userIds"
        options={userOptions}
      />
      <MultiSelect
        divClassName="w-[200px]"
        placeholder="Выберите профессию"
        label=""
        onChange={selectedValues => setField('positionIds', selectedValues)}
        value={positionIds}
        name="positionIds"
        options={positionOptions}
      />
    </>
  );
};

export default Selects;
