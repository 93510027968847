interface Props {
  row?: any;
}

const RowStats: React.FC<Props> = ({ row }) => {
  const shifts = row?.shifts || [];
  const shiftsCount = shifts.length;

  // const calculatePlanFact = (shift: any) => {
  //   const shiftDuration = shift.duration;
  //   const lunchHour = shift.lunchDuration;
  //   const durationPlan = shiftDuration > lunchHour ? shiftDuration - lunchHour : 0;

  //   let durationFact = 0;
  //   if (shift.marks?.length > 1) {
  //     const shiftStart = dayjs(shift.marks[0]?.time);
  //     const shiftEnd = dayjs(shift.marks[shift.marks?.length - 1]?.time);
  //     const minutes = Number(shiftEnd.diff(shiftStart, 'minutes'));

  //     durationFact = minutes > lunchHour ? minutes - lunchHour : 0;
  //   }

  //   if (shift?.moderation?.duration) {
  //     durationFact = shift?.moderation?.duration;
  //   }

  //   let valuePlan = 0;
  //   if (shift.unit === 'POINT') {
  //     valuePlan = 100;
  //   } else if (shift.unit === 'PIECE') {
  //     valuePlan = (durationPlan / 60) * 100;
  //   }

  //   return {
  //     durationFact,
  //     durationPlan,
  //     valuePlan,
  //   };
  // };

  // const { durationFactTotal, durationPlanTotal, valuePlanTotal } = shifts.reduce(
  //   (acc: any, shift: any) => {
  //     const { durationFact, durationPlan, valuePlan } = calculatePlanFact(shift);
  //     return {
  //       durationFactTotal: acc.durationFactTotal + durationFact,
  //       durationPlanTotal: acc.durationPlanTotal + durationPlan,
  //       valuePlanTotal: acc.valuePlanTotal + valuePlan,
  //     };
  //   },
  //   {
  //     durationFactTotal: 0,
  //     durationPlanTotal: 0,
  //     valuePlanTotal: 0,
  //   },
  // );

  return (
    <div className="flex border-b-[1px] Body2">
      <div className="w-[90px] pt-2 pb-2 pl-4 pr-4 border-r-[1px] h-[36px]">{row ? shiftsCount : null}</div>
      {/* <div className="w-[80px] pt-2 pb-2 pl-4 pr-4 border-r-[1px] h-[36px]"></div>
      <div className="w-[155px] pt-2 pb-2 pl-4 pr-4 border-r-[1px] h-[36px]">{row ? durationFactTotal : null}</div>
      <div className="w-[80px] pt-2 pb-2 pl-4 pr-4 border-r-[1px] h-[36px]">
        {row ? durationPlanTotal + ' ч.' : null}
      </div>
      <div className="w-[80px] pt-2 pb-2 pl-4 pr-4 h-[36px]">{row ? valuePlanTotal + ' ч.' : null} </div> */}
    </div>
  );
};

export default RowStats;
