import { Popover } from 'antd';
import DMark from 'assets/icons/DMark';
import LegendIcon from 'assets/icons/LegendIcon';
import NMark from 'assets/icons/NMark';
import SelectArrow from 'assets/icons/SelectArrow';
import clsx from 'clsx';
import { useState, useRef, useEffect } from 'react';

const Legend: React.FC = () => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const popoverRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (e: MouseEvent) => {
    const target = e.target as Node;
    if (!buttonRef.current?.contains(target) && !popoverRef.current?.contains(target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <Popover
      open={open}
      title={<div className="H4 px-3 pt-2 mb-2 text-smena_text">Легенда</div>}
      placement="bottomRight"
      content={
        <div className="px-3 pb-3 text-smena_text" ref={popoverRef}>
          <div className="mb-4">
            <div className="Subtitle2 mb-2">Общая</div>
            <div className="flex items-center gap-3 mb-2">
              <span className="bg-[#F9D15B] w-[14px] h-[14px] flex justify-center items-center rounded-[3px]">
                <DMark />
              </span>
              Дневная смена
            </div>
            <div className="flex items-center gap-3 mb-2">
              <span className="bg-[#4A579D] w-[14px] h-[14px] flex justify-center items-center rounded-[3px]">
                <NMark />
              </span>
              Ночная смена
            </div>
            <div className="flex items-center gap-3 mb-2">
              <span className="bg-smena_purple w-[14px] h-[14px] rounded-full" />
              Пробные смены
            </div>
          </div>

          <div className="mb-4">
            <div className="Subtitle2 mb-2">Модерация</div>
            <div className="flex items-center gap-3 mb-2">
              <span className="bg-smena_green-light w-[14px] h-[14px] rounded-full" />
              Смены к оплате
            </div>
            <div className="flex items-center gap-3 mb-2">
              <span className="bg-smena_red-light w-[14px] h-[14px] rounded-full" />
              Смены без оплаты
            </div>
            <div className="flex items-center gap-3 mb-2">
              <span className="bg-smena_yellow-light w-[14px] h-[14px] rounded-full" />
              Смены к модерации
            </div>
          </div>

          <div>
            <div className="Subtitle2 mb-2">График</div>
            <div className="flex items-center gap-3 mb-2">
              <span className="bg-smena_green-extra_light w-[14px] h-[14px] rounded-full" />
              Принятые смены
            </div>
            <div className="flex items-center gap-3 mb-2">
              <span className="bg-smena_orange-extra_light w-[14px] h-[14px] rounded-full" />
              Ожидают подтверждения исполнителя
            </div>
            <div className="flex items-center gap-3 mb-2">
              <span className="bg-smena_yellow-extra_light w-[14px] h-[14px] rounded-full" />
              Ожидают подтверждения супервайзера
            </div>
            <div className="flex items-center gap-3">
              <span className="bg-smena_red-extra_light_tabel w-[14px] h-[14px] rounded-full" />
              Отмененные
            </div>
          </div>
        </div>
      }
    >
      <button
        ref={buttonRef}
        className="flex items-center gap-1 text-primary"
        onClick={e => {
          e.stopPropagation(); // Останавливаем всплытие события
          setOpen(prevState => !prevState);
        }}
      >
        <LegendIcon />
        <SelectArrow className={clsx('transition-all', { ['rotate-180']: open })} />
      </button>
    </Popover>
  );
};

export default Legend;
