import { ShiftStage } from 'generated/graphql';
import { ShiftFactFragment } from 'pages/moderationReport/api/fragments/generated/ShiftFact.fragment';

export const getShiftBgColorFuture = (shift: ShiftFactFragment) => {
  const { stage } = shift;

  if (stage === ShiftStage.Refused || stage === ShiftStage.Ineligible) {
    return 'bg-smena_red-extra_light_tabel';
  }

  if (stage === ShiftStage.Accepted) {
    return 'bg-smena_green-extra_light';
  }

  if (stage === ShiftStage.New) {
    return 'bg-smena_orange-extra_light';
  }

  if (stage === ShiftStage.Wasted) {
    return 'bg-smena_yellow-extra_light';
  }
};
