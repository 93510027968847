import dayjs from 'dayjs';

export const isFutureOrToday = (day: number, dateParam: string | null): boolean => {
  const referenceDate = dateParam ? dayjs(dateParam) : dayjs();
  const currentDate = dayjs();

  const dayDate = referenceDate.date(day);

  return !dayDate.isBefore(currentDate, 'day');
};

export const isFutureDay = (day: number, dateParam: string | null): boolean => {
  const referenceDate = dateParam ? dayjs(dateParam) : dayjs();
  const currentDate = dayjs();

  const dayDate = referenceDate.date(day);

  return dayDate.isAfter(currentDate, 'day');
};
