import React from 'react';

const DMark: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
      <path
        d="M4.00049 1.36938H5.35547L5.23828 4.33569C5.20898 5.08765 5.12354 5.73706 4.98193 6.28394C4.84033 6.83081 4.65967 7.29712 4.43994 7.68286C4.22021 8.06372 3.97363 8.38354 3.7002 8.64233C3.43164 8.90112 3.15332 9.11841 2.86523 9.29419H2.19141L2.22803 8.18823L2.50635 8.18091C2.66748 7.99048 2.82373 7.79517 2.9751 7.59497C3.12646 7.38989 3.26562 7.14819 3.39258 6.86987C3.51953 6.58667 3.62451 6.24243 3.70752 5.83716C3.79541 5.427 3.85156 4.92651 3.87598 4.33569L4.00049 1.36938ZM4.3374 1.36938H9.10547V9.29419H7.75049V2.62183H4.3374V1.36938ZM1.88379 8.18091H10.1235V11.6306H8.76855V9.29419H3.23877V11.6306H1.87646L1.88379 8.18091Z"
        fill="#393B55"
      />
    </svg>
  );
};

export default DMark;
