import { useState } from 'react';
import { useStore } from 'store';
import { lunchDurationTypes } from 'utils/helpers/lists/lunchDurationTypes';
import { timeDifference } from 'utils/helpers/timeDifference';
import { lunchDurationHandle } from 'utils/helpers/lunchDuration';
import TimePickerInput from 'ui/pickers/TimePickerInput';
import MainSidebar from 'ui/sidebar';
import { DayjsOrNull } from 'interfaces/CustomTypes';
import SimpleSelect from 'ui/select';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';
import { useParams } from 'react-router';
import { useChangeCustomScheduleMutation } from 'pages/userSchedule/api/mutations/generated/ChangeCustomSchedule';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { GetSupervisorWorkSheetDocument } from 'pages/facilityReport/api/queries/generated/GetSupervisorWorkSheet';
import { errorHandler } from 'utils/helpers/errorHandler';
import { successToast, errorToast } from 'utils/helpers/notify';
import { shiftTimeDifference } from 'pages/userSchedule/helpers';

const NewShift = () => {
  const { facilityId } = useParams();
  const [params] = useSearchParams();
  const { showCreateRequestShiftSidebar, setShowCreateRequestShiftSidebar } = useStore();
  const { user, selectedDay } = useFacilityReportStore();

  const dateParam = params.get('date');

  const [shiftStartTime, setShiftStartTime] = useState<DayjsOrNull>(null);
  const [shiftEndTime, setShiftEndTime] = useState<DayjsOrNull>(null);
  const [lunchDuration, setLunchDuration] = useState(0);

  const getFormattedDateTime = (time: DayjsOrNull) => {
    if (!time) return null;

    const [year, month] = dateParam?.split('-') || [dayjs().year().toString(), (dayjs().month() + 1).toString()];
    const day = selectedDay ?? dayjs().date();

    return dayjs()
      .year(Number(year))
      .month(Number(month) - 1)
      .date(day)
      .hour(time.hour())
      .minute(time.minute())
      .second(0)
      .millisecond(0)
      .toISOString();
  };

  const [changeCustomSchedule] = useChangeCustomScheduleMutation({ refetchQueries: [GetSupervisorWorkSheetDocument] });

  const dateFrom = getFormattedDateTime(shiftStartTime);
  const shiftEndDate = getFormattedDateTime(shiftEndTime);

  const onSubmit = () => {
    if (shiftTimeDifference(dayjs(dateFrom), dayjs(shiftEndDate))) {
      return errorToast('Смена не может быть меньше 1 часа');
    }

    if (user?.user?.id && shiftStartTime && shiftEndDate) {
      changeCustomSchedule({
        variables: {
          inputs: {
            userId: user?.user?.id,
            facilityId: facilityId || '',
            positionId: user?.workPost?.position.id,
            dateFrom,
            shiftEndDate,
            lunchDuration,
          },
        },
      })
        .then(() => {
          successToast('Вы успешно предложили смену');
        })
        .catch(e => errorToast(`Произошла ошибка: ${errorHandler(e)}`))
        .finally(() => setShowCreateRequestShiftSidebar(false));
    }
  };

  return (
    <MainSidebar title="Добавить смену" show={showCreateRequestShiftSidebar} setShow={setShowCreateRequestShiftSidebar}>
      <div className="flex flex-col gap-y-6">
        <div className="grid grid-cols-2 gap-x-6 gap-y-4">
          <TimePickerInput
            label="Начало смены"
            className="col-span-1"
            popupClassName={'fixed'}
            value={shiftStartTime}
            onChange={timeValue => {
              setShiftStartTime(timeValue);
              setLunchDuration(lunchDurationHandle(timeDifference(timeValue, shiftEndTime)));
            }}
          />
          <TimePickerInput
            label="Конец смены"
            className="col-span-1"
            popupClassName={'fixed'}
            value={shiftEndTime}
            onChange={timeValue => {
              setShiftEndTime(timeValue);
              setLunchDuration(lunchDurationHandle(timeDifference(shiftStartTime, timeValue)));
            }}
          />
          <SimpleSelect
            divClassName="col-span-1"
            label="Обед"
            sort={false}
            onChange={value => {
              setLunchDuration(Number(value));
            }}
            value={lunchDuration}
            options={lunchDurationTypes}
          />
        </div>
        <div className="flex justify-end gap-x-6">
          <button className="btn-primary" onClick={onSubmit}>
            Предложить смену
          </button>
        </div>
      </div>
    </MainSidebar>
  );
};

export default NewShift;
