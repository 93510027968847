import { Popover } from 'antd';
import { DoNotShowShiftEnum } from 'generated/graphql';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';
import SimpleCheckbox from 'ui/checkbox';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

const MoreFiltersPopup: React.FC = () => {
  const [params, setParams] = useSearchParams();
  const { doNotShowShifts, setField, resetFilters, setIsFilterLoaded } = useFacilityReportStore();

  const updateUrlParams = (shiftEnum: DoNotShowShiftEnum) => {
    const updatedParams = new URLSearchParams(params.toString());
    const shifts = updatedParams.getAll('doNotShowShifts');

    const shiftValue = shiftEnum.toString();

    if (shifts.includes(shiftValue)) {
      updatedParams.delete('doNotShowShifts', shiftValue);
    } else {
      updatedParams.append('doNotShowShifts', shiftValue);
    }

    setParams(updatedParams);
  };

  const handleCheckboxChange = (shiftEnum: DoNotShowShiftEnum) => {
    setField('doNotShowShifts', shiftEnum);
    updateUrlParams(shiftEnum);
  };

  const handleResetFilters = () => {
    resetFilters();

    const updatedParams = new URLSearchParams(params.toString());
    updatedParams.delete('doNotShowShifts');
    setParams(updatedParams);
  };

  useEffect(() => {
    const shiftsFromUrl = params.getAll('doNotShowShifts');

    const validShifts = shiftsFromUrl.filter(shift =>
      Object.values(DoNotShowShiftEnum).includes(shift as DoNotShowShiftEnum),
    );

    setField('doNotShowShifts', validShifts);
    setIsFilterLoaded(true);
  }, [params, setField, setIsFilterLoaded]);

  return (
    <Popover
      placement="bottomLeft"
      trigger="click"
      title={<div className="px-3 pt-3 pb-1 H4 text-smena_text">Еще фильтры</div>}
      content={
        <div className="p-3 flex flex-col gap-y-5 items-end w-[305px] text-smena_text">
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-3">
              <div className="Subtitle2 mb-2">Не отображать смены</div>
              <SimpleCheckbox
                label="Отмененные"
                onChange={() => handleCheckboxChange(DoNotShowShiftEnum.Refused)}
                checked={doNotShowShifts.includes(DoNotShowShiftEnum.Refused)}
                name="canceled"
              />
              <SimpleCheckbox
                label="Требующие модерации"
                onChange={() => handleCheckboxChange(DoNotShowShiftEnum.Wasted)}
                checked={doNotShowShifts.includes(DoNotShowShiftEnum.Wasted)}
                name="moderationRequired"
              />
              <SimpleCheckbox
                label="К оплате"
                onChange={() => handleCheckboxChange(DoNotShowShiftEnum.ToPayment)}
                checked={doNotShowShifts.includes(DoNotShowShiftEnum.ToPayment)}
                name="toPay"
              />
              <SimpleCheckbox
                label="Без оплаты"
                onChange={() => handleCheckboxChange(DoNotShowShiftEnum.WithoutPayment)}
                checked={doNotShowShifts.includes(DoNotShowShiftEnum.WithoutPayment)}
                name="noPayment"
              />
            </div>

            <div>
              <SimpleCheckbox
                label="Не отображать смены в будущем"
                onChange={() => handleCheckboxChange(DoNotShowShiftEnum.InFuture)}
                checked={doNotShowShifts.includes(DoNotShowShiftEnum.InFuture)}
                name="futureShifts"
              />
            </div>

            <div>
              <SimpleCheckbox
                label="Не отображать исполнителей без смен"
                onChange={() => handleCheckboxChange(DoNotShowShiftEnum.UserWithoutShifts)}
                checked={doNotShowShifts.includes(DoNotShowShiftEnum.UserWithoutShifts)}
                name="noShifts"
              />
            </div>
          </div>

          <button className="btn-stroke" onClick={handleResetFilters}>
            Сбросить
          </button>
        </div>
      }
    >
      <button className="flex items-center gap-2 btn-stroke">
        Еще фильтры
        {Boolean(doNotShowShifts.length) && (
          <span className="text-primary rounded-full bg-primary bg-opacity-20 h-[20px] w-[20px] flex items-center justify-center">
            {String(doNotShowShifts.length)}
          </span>
        )}
      </button>
    </Popover>
  );
};

export default MoreFiltersPopup;
