import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { WorkerFacilityWorkPostsFragmentDoc } from '../../fragments/generated/WorkerFacilityWorkPosts.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnassignedWorkerFromPositionMutationVariables = Types.Exact<{
  input: Types.UnassignWorkerFromPositionInput;
}>;


export type UnassignedWorkerFromPositionMutation = { __typename?: 'Mutation', unassignWorkerFromPosition: Array<{ __typename?: 'WorkerFacilityWorkPostsOutput', workposts: Array<{ __typename?: 'WorkPostExperienceOutput', assignedAt: any, positionId: string, facilityId: string, userId: string, status: Types.WorkPostStatus, userExperience: Array<{ __typename?: 'UserExperience', assignedAt?: any | null, unassignAt?: any | null }> }> }> };


export const UnassignedWorkerFromPositionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UnassignedWorkerFromPosition"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UnassignWorkerFromPositionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"unassignWorkerFromPosition"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"WorkerFacilityWorkPosts"}}]}}]}},...WorkerFacilityWorkPostsFragmentDoc.definitions]} as unknown as DocumentNode;
export type UnassignedWorkerFromPositionMutationFn = Apollo.MutationFunction<UnassignedWorkerFromPositionMutation, UnassignedWorkerFromPositionMutationVariables>;

/**
 * __useUnassignedWorkerFromPositionMutation__
 *
 * To run a mutation, you first call `useUnassignedWorkerFromPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignedWorkerFromPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignedWorkerFromPositionMutation, { data, loading, error }] = useUnassignedWorkerFromPositionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnassignedWorkerFromPositionMutation(baseOptions?: Apollo.MutationHookOptions<UnassignedWorkerFromPositionMutation, UnassignedWorkerFromPositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnassignedWorkerFromPositionMutation, UnassignedWorkerFromPositionMutationVariables>(UnassignedWorkerFromPositionDocument, options);
      }
export type UnassignedWorkerFromPositionMutationHookResult = ReturnType<typeof useUnassignedWorkerFromPositionMutation>;
export type UnassignedWorkerFromPositionMutationResult = Apollo.MutationResult<UnassignedWorkerFromPositionMutation>;
export type UnassignedWorkerFromPositionMutationOptions = Apollo.BaseMutationOptions<UnassignedWorkerFromPositionMutation, UnassignedWorkerFromPositionMutationVariables>;