import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';

import DatePickerIcon from 'assets/icons/DatePickerIcon';
import CalendarRightArrow from 'assets/icons/CalendarRightArrow';
import CalendarLeftArrow from 'assets/icons/CalendarLeftArrow';
import { ISO_DATE } from 'utils/helpers/constVariables';
import { useSearchParams } from 'react-router-dom';
import DatePickerInput from 'ui/pickers/DatePickerInput';
import clsx from 'clsx';
import OutsideClick from 'components/outsideComponent';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';

const DataPicker: React.FC = () => {
  const [params, setParams] = useSearchParams();
  const [openCalendar, setOpenCalendar] = useState(false);
  const calendarIcon = useRef<HTMLButtonElement>(null);

  const { date, setField } = useFacilityReportStore();
  const dateParam = params.get('date');

  useEffect(() => {
    if (!dateParam) {
      params?.set('date', dayjs().format(ISO_DATE));
      setParams(params);
    }
  }, [dateParam, params, setParams]);

  useEffect(() => {
    if (dateParam) {
      setField('date', dayjs(dateParam));
    }
  }, [dateParam, setField]);

  return (
    <div className="flex items-center gap-x-3">
      <button
        onClick={() => {
          params?.set('date', dayjs(date).subtract(1, 'month').format(ISO_DATE));
          setParams(params);
        }}
      >
        <CalendarLeftArrow />
      </button>

      <div className="text-smena_text text-center w-[110px]">{dayjs(date).format('MMMM YYYY')}</div>
      <div className="flex items-end relative">
        <button
          onClick={() => {
            setOpenCalendar(openCalendar => !openCalendar);
          }}
          ref={calendarIcon}
        >
          <DatePickerIcon className="text-primary opacity-100" />
        </button>
        <OutsideClick
          setShow={setOpenCalendar}
          show={openCalendar}
          className={clsx('fixed z-30')}
          triggerRef={calendarIcon}
        >
          <DatePickerInput
            picker="month"
            placeholder="За весь период"
            value={dayjs(date)}
            pickerClassName="invisible absolute opacity-0"
            onChange={newValue => {
              params?.set('date', String(newValue?.startOf('month').format(ISO_DATE)));
              setParams(params);
            }}
            maxDate={dayjs()}
            open={openCalendar}
          />
        </OutsideClick>
      </div>

      <button
        className="cursor-pointer"
        onClick={() => {
          params?.set('date', dayjs(date).add(1, 'month').format(ISO_DATE));
          setParams(params);
        }}
      >
        <CalendarRightArrow />
      </button>
    </div>
  );
};

export default DataPicker;
