import dayjs from 'dayjs';
import clsx from 'clsx';
import { useEffect, useMemo } from 'react';
import { GetSupervisorWorkSheetQuery } from 'pages/facilityReport/api/queries/generated/GetSupervisorWorkSheet';
import ShiftCell from './components/ShiftCell/ShiftCell';
import { useSearchParams } from 'react-router-dom';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';
import CustomShiftCell from './components/CustomShiftCell';
import { isFutureDay } from 'pages/facilityReport/utils/helpers/isFutureOrToday';

interface Props {
  data?: GetSupervisorWorkSheetQuery['getSupervisorWorkSheet']['data'];
}

const Days = (props: Props) => {
  const { data } = props;
  const [params] = useSearchParams();
  const dateParam = params.get('date');

  const { isCalendar, setIsCalendar, selectedRow } = useFacilityReportStore();

  const currentDay = dayjs().date();

  const daysArray = useMemo(() => Array.from({ length: 31 }, (_, i) => i + 1), []);

  const getDayFromShift = (shiftDate: string) => {
    return dayjs(shiftDate).date();
  };

  useEffect(() => {
    if (dateParam) {
      setIsCalendar(false);
    }
  }, [dateParam, setIsCalendar]);

  const groupedData = data?.reduce(
    (acc, item) => {
      const professionId = item.workPost?.position?.id;

      const key = `${professionId}-${item.user.id}`;

      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    },
    {} as Record<string, typeof data>,
  );

  return (
    <div className="flex-1 overflow-auto h-full">
      <div className="flex border-b-[1px] border-smena_gray-30 bg-smena_gray-5 min-w-max Table-H1 sticky top-0">
        {daysArray.map(day => (
          <div
            key={day}
            className={clsx('pt-2 pb-2 w-[70px] text-center border-r-[1px] border-smena_gray-30 font-medium h-[36px]', {
              ['border-l-[2px] border-r-[2px] border-l-primary border-r-primary bg-primary-extra_light text-primary']:
                currentDay === day &&
                dayjs().month() === dayjs(dateParam).month() &&
                dayjs().year() === dayjs(dateParam).year(),
            })}
          >
            {day}
          </div>
        ))}
      </div>

      <div>
        {groupedData &&
          Object.entries(groupedData).map(([, users], index) =>
            users.map((userItem, userIndex) => {
              return (
                <div className="flex Body2 border-b-[1px] min-w-max" key={index + userIndex}>
                  {daysArray.map(day => {
                    const shiftForDay = userItem.shifts?.find((shift: any) => getDayFromShift(shift.dateFrom) === day);
                    if (isCalendar && !shiftForDay && index === selectedRow && isFutureDay(day, dateParam)) {
                      return (
                        <CustomShiftCell
                          groupIndex={index}
                          userIndex={userIndex}
                          dayIndex={day}
                          key={`${index}-${day}`}
                        />
                      );
                    }

                    return (
                      <ShiftCell
                        key={`${index}-${day}`}
                        shift={shiftForDay}
                        supervisor={userItem}
                        dayIndex={day}
                        rowIndex={userIndex}
                        groupIndex={index}
                      />
                    );
                  })}
                </div>
              );
            }),
          )}

        <div className="flex Body2 min-w-max">
          {daysArray.map(day => {
            const shiftCount = data?.reduce((count, supervisor) => {
              const shiftsForDay = supervisor.shifts?.filter((shift: any) => getDayFromShift(shift.dateFrom) === day);
              return count + Number(shiftsForDay?.length);
            }, 0);

            return (
              <div
                key={day}
                onClick={() => {}}
                className={clsx(
                  'pt-2 pb-2 min-w-[70px] text-center border-r-[1px] font-medium border-b-[1px] h-[36px]',
                  {
                    ['border-l-[2px] border-r-[2px] border-l-primary border-r-primary']:
                      currentDay === day &&
                      dayjs().month() === dayjs(dateParam).month() &&
                      dayjs().year() === dayjs(dateParam).year(),
                  },
                )}
              >
                {Number(shiftCount) > 0 ? shiftCount : 0}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Days;
