import { Radio } from 'antd';
import DataPicker from '../facilityReportFilter/components/datePicker';
import { useState } from 'react';

const FacilityReportHeader: React.FC = () => {
  const [tableType, setTableType] = useState('supervisor');

  return (
    <div className="p-4 flex justify-between items-center border-b-[1px]">
      <div className="flex gap-6">
        <Radio.Group
          className="border-r-[1px] pr-6 flex"
          value={tableType}
          onChange={e => setTableType(e.target.value)}
        >
          <Radio.Button value="supervisor" className="Button1 content-center text-primary">
            Табель супервайзера
          </Radio.Button>
          <Radio.Button disabled value="buh" className="Button1 content-center text-primary">
            Табель бухгалтера
          </Radio.Button>
        </Radio.Group>

        <DataPicker />
      </div>

      <button className="btn-primary" disabled>
        Скачать отчёт
      </button>
    </div>
  );
};

export default FacilityReportHeader;
