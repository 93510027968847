export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Date custom scalar type */
  Date: { input: any; output: any; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

export type AcceptRequestShiftsSuggestionsInput = {
  /** Массив идентификаторов строк откликнувшихся исполнителей в таблице request_shift_suggestions */
  acceptedSuggestionIds: Array<Scalars['String']['input']>;
  /** Идентификатор графика заявки */
  requestScheduleId: Scalars['String']['input'];
};

export type Achievement = {
  __typename?: 'Achievement';
  bonus: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
  /** Дата и время создания объекта */
  createdAt: Scalars['Date']['output'];
  descr: Scalars['String']['output'];
  /** Уникальный идентификатор объекта */
  id: Scalars['ID']['output'];
  progress: Scalars['Int']['output'];
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
  /** Дата и время обновления объекта */
  updatedAt: Scalars['Date']['output'];
};

export type AddReferralInput = {
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type AddUserModerationReportInput = {
  /** Id должности */
  positionId: Scalars['String']['input'];
  /** Id табеля */
  reportId: Scalars['String']['input'];
  /** Id сотрудника */
  userId: Scalars['String']['input'];
};

export type Address = {
  __typename?: 'Address';
  address: Scalars['String']['output'];
  city?: Maybe<Scalars['String']['output']>;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Расчетное поле для расстояния от гео точки в километрах */
  distance?: Maybe<Scalars['Float']['output']>;
  /** Объект */
  facility: Facility;
  fiasId?: Maybe<Scalars['String']['output']>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  /** Анкета */
  questionnaire: Questionnaire;
  settlement?: Maybe<Scalars['String']['output']>;
  /** Часовой пояс */
  timezone: Scalars['String']['output'];
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
};

export type AddressInput = {
  /** Адрес */
  address?: InputMaybe<Scalars['String']['input']>;
  /** Город */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Код ФИАС */
  fiasId?: InputMaybe<Scalars['String']['input']>;
  /** Широта */
  latitude?: InputMaybe<Scalars['String']['input']>;
  /** Долгота */
  longitude?: InputMaybe<Scalars['String']['input']>;
  /** Населенный пункт */
  settlement?: InputMaybe<Scalars['String']['input']>;
  /** Временная зона */
  timezone?: InputMaybe<Scalars['String']['input']>;
};

/** Статус подписания АД и согласия на обработку ПД в Наймикс */
export enum ApAndPdStateEnum {
  NotSign = 'NOT_SIGN',
  Sign = 'SIGN',
  SigningInProgress = 'SIGNING_IN_PROGRESS'
}

/** App type */
export enum AppType {
  All = 'ALL',
  Mobile = 'MOBILE',
  Web = 'WEB'
}

export type ArchiveReferralProgramInput = {
  /** Идентификатор реферальной программы */
  id: Scalars['String']['input'];
};

export type AreThereAnyFreeShiftsModel = {
  __typename?: 'AreThereAnyFreeShiftsModel';
  accepted: Scalars['Int']['output'];
  free: Scalars['Int']['output'];
  waiting: Scalars['Int']['output'];
};

export type AssignTemporaryShiftInput = {
  /** Id назначенного пользователя */
  assigneeId: Scalars['String']['input'];
  /** Id подработки */
  temporaryShiftId: Scalars['String']['input'];
};

export type Auth = {
  __typename?: 'Auth';
  /** JWT access token */
  accessToken: Scalars['String']['output'];
  /** JWT refresh token */
  refreshToken: Scalars['String']['output'];
  user: User;
};

/** Модель с информацией о способе входа и типе приложения для которого вход */
export type AuthOption = {
  __typename?: 'AuthOption';
  /** В для какого типа ПО вход - админка или мобилка dashboard|mobile */
  applicationType?: Maybe<Scalars['String']['output']>;
  /** Способ входа - пароль или смс (password|sms-message) */
  loginMethod?: Maybe<Scalars['String']['output']>;
  smsError?: Maybe<Scalars['String']['output']>;
};

/** Dto с набором данных необходимых при создании/редактировании объекта */
export type BalanceOperationCardInput = {
  /** Сумма операции */
  amount: Scalars['Float']['input'];
  /** Дата операции */
  dayAndTime: Scalars['Date']['input'];
  /** Директора группы объектов */
  description: Scalars['String']['input'];
  /** Id группы объекта */
  facilityGroupId: Scalars['String']['input'];
  /** Группа объекта */
  operation: BalanceOperationType;
};

/** Типы операций с балансом (Пополнение/Списание) */
export enum BalanceOperationType {
  Addition = 'ADDITION',
  Subtraction = 'SUBTRACTION'
}

/** Параметры для пакетного создания профессии для группы объектов */
export type BatchCasingCardInput = {
  /** Массив ID городов + ID объектов */
  citiesAndFacilitiesIds: Array<Scalars['String']['input']>;
  /** Описание ставки */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Id группы объектов */
  facilityGroupId: Scalars['String']['input'];
  /** Id Объекта */
  facilityId?: InputMaybe<Scalars['String']['input']>;
  /** Возможность обучения */
  hasEducation?: InputMaybe<Scalars['Boolean']['input']>;
  /** Возможность стажировки */
  hasProbation?: Scalars['Boolean']['input'];
  /** Размер оплаты в час */
  hourRate: Scalars['Float']['input'];
  /** Проверять ли статус мед. книжки при назначении смен/графиков */
  isMedBookRequired?: Scalars['Boolean']['input'];
  /** Возможность оформления самозанятости на этой должности */
  isSelfEmployed?: Scalars['Boolean']['input'];
  /** Тип выплаты за работу */
  payout?: InputMaybe<PayoutType>;
  /** Id Должности */
  positionId: Scalars['String']['input'];
  /** Период стажировки в календарных днях */
  probationPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** Ставка за смену на период стажировки */
  probationRate?: InputMaybe<Scalars['Int']['input']>;
  requiredDocuments?: Array<CasingDocumentType>;
  /** Надбавка к часовой ставке за самозанятость у работника */
  selfEmployedRate?: InputMaybe<Scalars['Int']['input']>;
  /** Единицы измерения для оплаты */
  unit?: InputMaybe<UnitType>;
};

export type BatchCreateVacancyCoverPhotoInput = {
  coverPhotoId: Scalars['ID']['input'];
  facilityId: Scalars['String']['input'];
};

export type BatchVacancyInput = {
  /** Средняя стоимость оплаты по смене */
  averageCost?: Scalars['Float']['input'];
  /** Массив ID городов + ID объектов */
  citiesAndFacilitiesIds: Array<Scalars['String']['input']>;
  /** IDs стран */
  citizenshipsIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Идентификатор фотографии альбома */
  coverPhotoId?: InputMaybe<Scalars['ID']['input']>;
  coverPhotos?: InputMaybe<Array<BatchCreateVacancyCoverPhotoInput>>;
  /** Описание */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Примечание к документам */
  documentsNotes?: InputMaybe<Scalars['String']['input']>;
  /** Group Id in ERP */
  erpGroupId?: InputMaybe<Scalars['String']['input']>;
  /** Source Id in ERP */
  erpSourceId?: InputMaybe<Scalars['String']['input']>;
  facilityGroupId: Scalars['String']['input'];
  facilityId?: InputMaybe<Scalars['String']['input']>;
  /** Возможность образования */
  hasEducation?: InputMaybe<Scalars['Boolean']['input']>;
  hourRate: Scalars['Float']['input'];
  isActive?: Scalars['Boolean']['input'];
  isDeleted?: Scalars['Boolean']['input'];
  /** публиковать стоимость смены */
  isPublishCostOfShift?: Scalars['Boolean']['input'];
  /** Отображать в вакансии диапазон стоимости ОТ-ДО */
  isRangeCost?: Scalars['Boolean']['input'];
  /** Длительность обеденного перерыва в минутах */
  lunchDuration?: InputMaybe<Scalars['Int']['input']>;
  /** Максимальный возраст */
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  /** Минимальный возраст */
  minAge?: InputMaybe<Scalars['Int']['input']>;
  /** Примечание */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Тип выплаты за работу */
  payout?: InputMaybe<PayoutType>;
  /** Частота выплат */
  payoutFrequency?: InputMaybe<PayoutFrequency>;
  /** День / Ночь */
  period: ShiftTime;
  positionId?: InputMaybe<Scalars['String']['input']>;
  /** Кол-во дней стажировки */
  probationPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** Необходимые документы */
  requiredDocuments?: Array<CasingDocumentType>;
  /** Требования */
  requirements?: InputMaybe<Scalars['String']['input']>;
  /** Обязанности */
  responsibilities?: InputMaybe<Scalars['String']['input']>;
  /** IDs типов графиков */
  scheduleTypesIds?: InputMaybe<Array<Scalars['String']['input']>>;
  section?: InputMaybe<Scalars['String']['input']>;
  /** Пол */
  sex?: InputMaybe<Sex>;
  /** Минимальная стоимость оплаты по смене от */
  shiftCostFrom?: Scalars['Float']['input'];
  /** Максимальная стоимость оплаты по смене до */
  shiftCostUpTo?: Scalars['Float']['input'];
  timeEnd?: InputMaybe<Scalars['String']['input']>;
  timeStart?: InputMaybe<Scalars['String']['input']>;
  /** Транспорт */
  transport?: InputMaybe<Scalars['String']['input']>;
  /** Единицы измерения для оплаты */
  unit?: InputMaybe<UnitType>;
  /** Спец. одежда */
  workwear?: Scalars['Boolean']['input'];
};

export type BonusAndRate = {
  __typename?: 'BonusAndRate';
  achievements: Array<Achievement>;
  /** заработанная сумма с начала месяца */
  amountEarned: Scalars['Float']['output'];
  bonuses: Array<Achievement>;
  /** Дата и время создания объекта */
  createdAt: Scalars['Date']['output'];
  /** Сухой прогноз заработка за весь месяц без вычетов */
  dryAmountPerMonth: Scalars['Float']['output'];
  /** Уникальный идентификатор объекта */
  id: Scalars['ID']['output'];
  payments: Array<Payment>;
  /** Должность и ставка по объекту */
  rates: Array<RatesOutput>;
  shifts: Array<Shift>;
  /** Итоговый бонус к ставке */
  totalBonus: Scalars['Float']['output'];
  /** Прогноз заработка за остающиеся смены до конца месяца */
  upcomingAmount: Scalars['Float']['output'];
  /** Дата и время обновления объекта */
  updatedAt: Scalars['Date']['output'];
  userId: Scalars['String']['output'];
};

export type BookAShiftInTheScheduleInput = {
  requestScheduleId: Scalars['ID']['input'];
  requestShiftIds: Array<Scalars['ID']['input']>;
};

export type CancelPlanCasingRateInput = {
  casingRateId: Scalars['String']['input'];
};

/** Модель ставки (часовой оплаты по профессии на объекте) */
export type Casing = {
  __typename?: 'Casing';
  /** Галерея фотографий профессии объекта */
  casingGallery?: Maybe<Array<CasingGalleryEntity>>;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  currentProbationRate?: Maybe<CasingRateEntity>;
  currentRate: CasingRateEntity;
  description?: Maybe<Scalars['String']['output']>;
  doCheckMedBook: Scalars['Boolean']['output'];
  facility: Facility;
  facilityId: Scalars['String']['output'];
  /** Возможность образования */
  hasEducation?: Maybe<Scalars['Boolean']['output']>;
  hasProbation: Scalars['Boolean']['output'];
  /**
   * Размер оплаты в час
   * @deprecated Теперь хранится отдельной сущностью
   */
  hourRate: Scalars['Float']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Проверять ли статус мед. книжки при назначении смен/графиков */
  isMedBookRequired: Scalars['Boolean']['output'];
  isSelfEmployed?: Maybe<Scalars['Boolean']['output']>;
  payout: PayoutType;
  plannedProbationRate?: Maybe<CasingRateEntity>;
  plannedRate?: Maybe<CasingRateEntity>;
  position: Position;
  positionId: Scalars['String']['output'];
  probationPeriod?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Теперь хранится отдельной сущностью */
  probationRate?: Maybe<Scalars['Int']['output']>;
  probationRateHistory?: Maybe<Array<CasingRateEntity>>;
  rateHistory?: Maybe<Array<CasingRateEntity>>;
  /** Необходимые документы */
  requiredDocuments: Array<CasingDocumentType>;
  selfEmployedRate?: Maybe<Scalars['Int']['output']>;
  unit: UnitType;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
};

/** DTO инпутов карточки работы с моделью ставки (часовой оплаты по профессии на объекте) */
export type CasingCardInput = {
  /** Описание ставки */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Id Объекта */
  facilityId: Scalars['String']['input'];
  /** Возможность обучения */
  hasEducation?: InputMaybe<Scalars['Boolean']['input']>;
  /** Возможность стажировки */
  hasProbation?: Scalars['Boolean']['input'];
  /** Размер оплаты в час */
  hourRate: Scalars['Float']['input'];
  /** Проверять ли статус мед. книжки при назначении смен/графиков */
  isMedBookRequired?: Scalars['Boolean']['input'];
  /** Возможность оформления самозанятости на этой должности */
  isSelfEmployed?: Scalars['Boolean']['input'];
  /** Тип выплаты за работу */
  payout?: InputMaybe<PayoutType>;
  /** Id Должности */
  positionId: Scalars['String']['input'];
  /** Период стажировки в календарных днях */
  probationPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** Ставка за смену на период стажировки */
  probationRate?: InputMaybe<Scalars['Int']['input']>;
  requiredDocuments?: Array<CasingDocumentType>;
  /** Надбавка к часовой ставке за самозанятость у работника */
  selfEmployedRate?: InputMaybe<Scalars['Int']['input']>;
  /** Единицы измерения для оплаты */
  unit?: InputMaybe<UnitType>;
};

/** Необходимые документы для профессии на объекте */
export enum CasingDocumentType {
  BankCard = 'BANK_CARD',
  Inn = 'INN',
  MedBook = 'MED_BOOK',
  Passport = 'PASSPORT',
  Registration = 'REGISTRATION'
}

export type CasingGalleryEntity = {
  __typename?: 'CasingGalleryEntity';
  casing: Casing;
  /** Идентификатор профессии на объекте */
  casingId: Scalars['String']['output'];
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  file?: Maybe<File>;
  /** Идентификатор файла с фотографией */
  fileId?: Maybe<Scalars['String']['output']>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Дата и время обновления */
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type CasingRateEntity = {
  __typename?: 'CasingRateEntity';
  authorId: Scalars['String']['output'];
  casing: Casing;
  casingId: Scalars['String']['output'];
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  isProbation: Scalars['Boolean']['output'];
  rate: Scalars['Float']['output'];
  startDatetime: Scalars['Date']['output'];
  /** Дата и время обновления */
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

/** Подписка на событие пакетного создания профессий */
export type CasingsForemanSubscriptionOutput = {
  __typename?: 'CasingsForemanSubscriptionOutput';
  /** Массив профессий на объекте */
  casings: Array<Casing>;
  /** Id бригадира */
  foremanId: Scalars['String']['output'];
};

export type ChangeModerationInput = {
  /** Причина комментария */
  cause?: InputMaybe<Scalars['String']['input']>;
  /** Комментарий модерации */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Количество сотрудников в отчете */
  duration?: Scalars['Int']['input'];
  /** Ставка отредактированная */
  hourRate: Scalars['Float']['input'];
  /** Ставка за смену на период стажировки */
  probationRate?: InputMaybe<Scalars['Float']['input']>;
  /** Id смены для которой создана модерация */
  shiftId: Scalars['String']['input'];
  /** К оплате? */
  toPayment?: InputMaybe<Scalars['Boolean']['input']>;
  /** Учитывать смену в бонусной системе? */
  toPaymentBonus?: InputMaybe<Scalars['Boolean']['input']>;
  /** Значение выработки для сдельной формы работы */
  value: Scalars['Float']['input'];
};

export type ChangeModerationUserPosition = {
  /** Id должности */
  positionId: Scalars['String']['input'];
  /** Id сотрудника */
  userId: Scalars['String']['input'];
};

export type ChangeModerationUserRowInput = {
  /** Id табеля */
  reportId: Scalars['String']['input'];
  /** Статус оплаты, который необходимо установить */
  toPayment: Scalars['Boolean']['input'];
  /** Массив смен в дне */
  userPositions: Array<ChangeModerationUserPosition>;
};

export type ChangePasswordInput = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export type ChangeResolveReportUserInput = {
  /** Исключить из отчета */
  disable?: Scalars['Boolean']['input'];
  /** id */
  id: Scalars['String']['input'];
  /** Фамилия и Имя */
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type ChangeScheduleInput = {
  /** Обязательное! время окончания графика */
  dateEnd: Scalars['DateTime']['input'];
  /** Обязательное! время начала графика */
  dateStart: Scalars['DateTime']['input'];
  /** объект на который устанавливается график */
  facilityId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lunch?: Scalars['Boolean']['input'];
  /** длительность обеда в минутах */
  lunchDuration?: InputMaybe<Scalars['Int']['input']>;
  /** позиция на которую устанавливается график */
  positionId?: InputMaybe<Scalars['String']['input']>;
  shiftDuration?: InputMaybe<Scalars['String']['input']>;
  /** Обязательное! Время окончания первой смены в графике для определения длительности смены */
  shiftEndDate: Scalars['DateTime']['input'];
  /** Тип графика: 1 - 2/2; 2 - 4/2; 3 - 5/2; 4 - 6/1; 5 - 7/0; 6 - 3/3; 7 - 3/4; 8 - 4/3, 9 - 3/2 (рабочих дней / выходные) */
  type?: InputMaybe<Scalars['Int']['input']>;
  /** Идентификатор типа графика */
  typeId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type ChangeShiftStageInput = {
  /** Комментарий бригадира */
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  /** новый статус смены */
  stage: ShiftStage;
};

export type ChangeUserInfoMainInput = {
  /** Дата рождения пользователя */
  birthDate: Scalars['String']['input'];
  /** Идентификатор гражданства пользователя */
  citizenshipId: Scalars['String']['input'];
  /** Имя пользователя */
  firstname: Scalars['String']['input'];
  /** Фамилия пользователя */
  lastname: Scalars['String']['input'];
  /** Отчество пользователя */
  patronymic?: InputMaybe<Scalars['String']['input']>;
};

export type ChangeUserInfoMainModel = {
  __typename?: 'ChangeUserInfoMainModel';
  /** Дата рождения пользователя */
  birthDate: Scalars['String']['output'];
  /** Идентификатор гражданства пользователя */
  citizenshipId: Scalars['String']['output'];
  /** Имя пользователя */
  firstname: Scalars['String']['output'];
  /** Идентификатор пользователя */
  id: Scalars['String']['output'];
  /** Фамилия пользователя */
  lastname: Scalars['String']['output'];
  /** Отчество пользователя */
  patronymic?: Maybe<Scalars['String']['output']>;
};

export type ChartDataOutput = {
  __typename?: 'ChartDataOutput';
  /** it is a date */
  createdAt: Scalars['Date']['output'];
  /** it is a value */
  value: Scalars['Int']['output'];
};

export type ChartOutput = {
  __typename?: 'ChartOutput';
  /** Данные для графика */
  data: Array<ChartDataOutput>;
  /** Id группы объектов */
  facilityGroupId?: Maybe<Scalars['ID']['output']>;
  /** Id объекта */
  facilityId?: Maybe<Scalars['ID']['output']>;
  /** Тип графика */
  type: ChartType;
};

/** Тип графика для дашборда */
export enum ChartType {
  AcceptedShifts = 'ACCEPTED_SHIFTS',
  CountForemen = 'COUNT_FOREMEN',
  CountSupervisors = 'COUNT_SUPERVISORS',
  CountWorkers = 'COUNT_WORKERS',
  FinishedShifts = 'FINISHED_SHIFTS',
  GoodFinishedShiftsRatio = 'GOOD_FINISHED_SHIFTS_RATIO',
  GoodShifts = 'GOOD_SHIFTS',
  Latecomers = 'LATECOMERS',
  Registrations = 'REGISTRATIONS',
  WorkingShifts = 'WORKING_SHIFTS'
}

export type CheckNaimixContractorStatusInput = {
  inn: Scalars['String']['input'];
  /** Признак подтверждения пользователем объединения аккаунтов */
  mergeAccounts?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Статусы проверки ИНН пользователя перед регистрацией в Наймикс */
export enum CheckNaimixContractorStatusResponseEnum {
  /** Пользователь может продолжить регистрацию */
  ContinueRegistration = 'CONTINUE_REGISTRATION',
  /** ИНН занят другим пользователем, но в Наймикс ИНН не зарегистрирован */
  InnIsBusyInSmena = 'INN_IS_BUSY_IN_SMENA',
  /** Аккаунты успешно объединены */
  Merged = 'MERGED',
  /** Готов к объединению профилей */
  SuccessToMerge = 'SUCCESS_TO_MERGE',
  /** Номер пользователя отличается от того, что указан в Наймикс */
  TheNumberIsDifferentFromTheNumberOfNaimix = 'THE_NUMBER_IS_DIFFERENT_FROM_THE_NUMBER_OF_NAIMIX',
  /** Пользователь уже зарегистрирован в Наймикс */
  UserIsAlreadyRegistered = 'USER_IS_ALREADY_REGISTERED'
}

export type CheckNaimixContractorStatusV2Model = {
  __typename?: 'CheckNaimixContractorStatusV2Model';
  /** Сопутствующая информация к статусу */
  message?: Maybe<Scalars['String']['output']>;
  /** Статус проверки ИНН */
  status: CheckNaimixContractorStatusResponseEnum;
};

export type City = {
  __typename?: 'City';
  /** Id of the country */
  countryId?: Maybe<Scalars['String']['output']>;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** id города в ERP / ФИАС ID */
  externalId?: Maybe<Scalars['String']['output']>;
  /** Станции метро относящиеся к этому городу */
  facilities: Array<Facility>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** широта */
  latitude: Scalars['String']['output'];
  /** Долгота */
  longitude: Scalars['String']['output'];
  /** Станции метро в городе */
  metroStations: Array<Facility>;
  name: Scalars['String']['output'];
  publicConditions?: Maybe<Array<PublicCondition>>;
  /** Часовой пояс города */
  timeZone: Scalars['String']['output'];
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
};

export type CityInput = {
  /** ID страны */
  countryId?: InputMaybe<Scalars['String']['input']>;
  /** ID города */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Широта */
  latitude: Scalars['String']['input'];
  /** Долгота */
  longitude: Scalars['String']['input'];
  /** Название города */
  name: Scalars['String']['input'];
  /** Часовой пояс */
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

export type ClientUser = {
  __typename?: 'ClientUser';
  /** Объекты, на которых пользователь является Бригадиром */
  Facility_FOREMAN?: Maybe<Array<Facility>>;
  /** Объекты в которых пользователь Супервайзер */
  Facility_SUPERVISOR?: Maybe<Array<Facility>>;
  /** Столбец Активные Смены - есть ли у работника смена на сегодня или в будущем */
  activeShifts: Scalars['Boolean']['output'];
  /** Документы, которые пользователь рассматривает */
  allDocumentsReviews: Array<DocumentReview>;
  /** Разрешить работать без Наймикс */
  allowWorkWithoutNaimix: Scalars['Boolean']['output'];
  /** Гражданство */
  citizenship?: Maybe<Country>;
  citizenshipId?: Maybe<Scalars['String']['output']>;
  /** Город */
  city?: Maybe<City>;
  /** Id города */
  cityId?: Maybe<Scalars['String']['output']>;
  /** Комментарии к сменам */
  comments: Array<CommentEntity>;
  contactPhones?: Maybe<Array<ContactPhone>>;
  /** Контракты пользователя */
  contracts?: Maybe<Array<UserContract>>;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  currentVersion?: Maybe<Scalars['String']['output']>;
  deletedAt: Scalars['Date']['output'];
  /** Файлы документов загруженные пользователю */
  documents: Array<Document>;
  /** Статус проверки документов */
  documentsStatus: DocumentsStatus;
  /** Email address */
  email?: Maybe<Scalars['String']['output']>;
  /** Id пользователя в ERP */
  externalId?: Maybe<Scalars['String']['output']>;
  /** Объекты в которых пользователь Менеджер */
  facilitiesManager?: Maybe<Array<Facility>>;
  facilityToUsers: Array<FacilityToUser>;
  /** Имя */
  firstname: Scalars['String']['output'];
  /** Количество хороших смен пользователя */
  goodShiftCount?: Maybe<Scalars['Int']['output']>;
  groupManagers: Array<GroupManager>;
  /** Группы в которых пользователь Директор */
  groupsDirector?: Maybe<Array<FacilityGroup>>;
  /** Группы в которых пользователь назначен как Менеджер */
  groupsManager?: Maybe<Array<FacilityGroup>>;
  /** Скрывать ли рейтинг пользователя */
  hideRating: Scalars['Boolean']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  lastLoginAt?: Maybe<Scalars['Date']['output']>;
  /** Фамилия */
  lastname: Scalars['String']['output'];
  /** Лид */
  lead?: Maybe<Lead>;
  legalEntities: Array<LegalEntity>;
  manualAccrualItems: Array<ManualAccrualItem>;
  manualUserAccruals: Array<ManualUserAccrual>;
  marks?: Maybe<Array<Mark>>;
  /** Документы пользователя на рассмотрении */
  myDocumentsReviews: Array<DocumentReview>;
  notifications: Array<Notification>;
  /** Отчество */
  patronymic?: Maybe<Scalars['String']['output']>;
  /** Телефон */
  phone: Scalars['String']['output'];
  /** Id должности */
  positionId?: Maybe<Scalars['String']['output']>;
  professions: Array<Profession>;
  /** Анкета */
  questionnaire?: Maybe<Questionnaire>;
  referrals?: Maybe<Array<User>>;
  referrer?: Maybe<User>;
  referrerId?: Maybe<Scalars['String']['output']>;
  /** Состояние регистрации пользователя в Наймикс */
  registrationState?: Maybe<UserRegistrationState>;
  reports: Array<Report>;
  requestShiftSuggestions: Array<RequestShiftSuggestionEntity>;
  /** Реквизиты пользователя (отельная модель) */
  requisite?: Maybe<Requisite>;
  respondedVacancies?: Maybe<Array<Vacancy>>;
  /** Роль */
  role: Role;
  schedule?: Maybe<Array<Schedule>>;
  shiftForemen: Array<ShiftForeman>;
  shiftPenalties: Array<ShiftPenalty>;
  /** Смены пользователя */
  shifts?: Maybe<Array<Shift>>;
  /** подписка на объекты, о желании на них работать */
  subscribedFacility?: Maybe<Array<Facility>>;
  subscribers: Array<Subscribers>;
  supervisors: Array<Supervisors>;
  temporaryShifts: Array<TemporaryShift>;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  userActivityLogs: Array<UserActivityLog>;
  /** Автоматический рейтинг пользователя */
  userAutoRating?: Maybe<UserAutoRating>;
  userDevice: UserDevice;
  /** История опыта работника */
  userExperience?: Maybe<Array<UserExperience>>;
  /** История опыта работника */
  userGrade?: Maybe<Array<UserGrade>>;
  userGradeAuthor?: Maybe<Array<UserGrade>>;
  /** История опыта работника */
  userReview?: Maybe<Array<UserReview>>;
  userReviewAuthor: Array<UserReview>;
  /** Рабочие места пользователя */
  workposts?: Maybe<Array<WorkPost>>;
};

export type ClientUserListInput = {
  /** Фильтрация по активным сменам (true - только с активными сменами, false - без активных, null - без фильтра) */
  activeShifts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Статус подписания договоров */
  contractStatus?: InputMaybe<UserContractStatus>;
  facilityId?: InputMaybe<Scalars['String']['input']>;
  /** номер страницы */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Заполненены ли платежные данные */
  paymentInfo?: InputMaybe<PaymentInfoEnum>;
  /** Статус заполнения персональной информации */
  personalInfo?: InputMaybe<UserPersonalInfoStatus>;
  /** Фильтрация по рейтингу (в строке через запятую от,до - '0,4', null - без оценки, '' - все оценки) */
  rating?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  /** Количество элементов на странице */
  size?: InputMaybe<Scalars['Int']['input']>;
  /** поле по которому сортировка, по умолчанию updatedAt */
  sortBy?: InputMaybe<UserFieldsForSorting>;
  /** Направление сортировки */
  sortWay?: InputMaybe<SortingWay>;
  /** Добавлено ли приложение "Мой налог" в партнеры */
  taxPartnerInfo?: InputMaybe<TaxPartnerInfo>;
};

export type CommentEntity = {
  __typename?: 'CommentEntity';
  /** Автор коммента */
  author: User;
  authorId: Scalars['String']['output'];
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  isRead: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
  /** Смена */
  shift: Shift;
  shiftId: Scalars['String']['output'];
  /** Дата и время обновления */
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ContactPhone = {
  __typename?: 'ContactPhone';
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  deletedAt: Scalars['Date']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Номер телефона с цифры 9 (без 8 или +7) */
  phone: Scalars['String']['output'];
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  /** Объект пользователя, к которому относится номер телефона */
  user: User;
  /** Id пользователя которому принадлежит телефон */
  userId: Scalars['String']['output'];
};

export type Country = {
  __typename?: 'Country';
  code: Scalars['String']['output'];
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  externalId: Scalars['String']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  isAvailableInRegistrationNaimix: Scalars['Boolean']['output'];
  isSelfEmployed: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  users: Array<User>;
};

export type CreateDailyReportInput = {
  /** Дата в формате YYYY-MM-DD */
  date: Scalars['String']['input'];
};

export type CreateDraftReferralProgramInput = {
  /** Идентификатор реферальной программы */
  id: Scalars['String']['input'];
};

export type CreateDraftStoryInput = {
  referralProgramId: Scalars['String']['input'];
};

export type CreateManyReferralProgramsInput = {
  /** Описание реферальной программы */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Дата и время окончания реферальной программы */
  endDatetime?: InputMaybe<Scalars['String']['input']>;
  /** Идентификатор групп объектов, к которым будет привязана реферальная программа */
  facilityGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Идентификатор объектов, к которым будет привязана реферальная программа */
  facilityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Название реферальной программы */
  name: Scalars['String']['input'];
  /** Количество хороших смен */
  numberOfGoodShifts: Scalars['Int']['input'];
  /** Сумма выплаты пригласившему */
  rewardForTheReferer: Scalars['Int']['input'];
  /** Дата и время старта реферальной программы */
  startDatetime: Scalars['String']['input'];
};

export type CreateMarkInput = {
  /** Точность координат, в метрах */
  accuracy?: InputMaybe<Scalars['Float']['input']>;
  /** Широта */
  latitude: Scalars['String']['input'];
  /** Долгота */
  longitude: Scalars['String']['input'];
  /** Id смены */
  shiftId: Scalars['String']['input'];
  /** Тип отметки: чекин или чекаут */
  type: MarkType;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateModerationInput = {
  /** Причина комментария */
  cause?: InputMaybe<Scalars['String']['input']>;
  /** Комментарий модерации */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Количество сотрудников в отчете */
  duration?: Scalars['Int']['input'];
  /** Ставка отредактированная */
  hourRate: Scalars['Float']['input'];
  /** Ставка за смену на период стажировки */
  probationRate?: InputMaybe<Scalars['Float']['input']>;
  /** К оплате? */
  toPayment?: InputMaybe<Scalars['Boolean']['input']>;
  /** Учитывать смену в бонусной системе? */
  toPaymentBonus?: InputMaybe<Scalars['Boolean']['input']>;
  /** Значение выработки для сдельной формы работы */
  value: Scalars['Float']['input'];
};

export type CreateModerationReportInput = {
  /** Дата конца периода модерации */
  dateEnd: Scalars['DateTime']['input'];
  /** Дата начала периода модерации */
  dateStart: Scalars['DateTime']['input'];
  /** Id объекта на который создан период */
  facilityId: Scalars['String']['input'];
  /** Тип периода (WEEK, Неделя / TWO_WEEK, Две недели / MONTH, Месяц) */
  periodType: PeriodType;
  /** Статус модерации */
  status?: ModerationStatus;
};

export type CreateNewPromotionInput = {
  /** Количество плохих смен для аннулирования */
  badShiftCount: Scalars['Int']['input'];
  /** Дата и время окончания бонусной программы */
  dateTimeEnd?: InputMaybe<Scalars['String']['input']>;
  /** Дата и время начала бонусной программы */
  dateTimeStart: Scalars['String']['input'];
  /** Идентификатор группы объектов, которой принадлежит бонусная программа */
  facilityGroupId: Scalars['String']['input'];
  /** Название бонусной программы */
  name: Scalars['String']['input'];
  /** Тип повтора бонусной программы (условия запуска бонусной программы с нуля) */
  repeatCondition: PromotionRepeatConditionEnum;
  /** Количество смен для выполнения условий получения бонуса */
  requirementShiftCount: Scalars['Int']['input'];
  /** Сумма вознаграждения */
  rewardSum: Scalars['Int']['input'];
  /** Тип вознаграждения */
  rewardType: PromotionRewardTypeEnum;
};

export type CreateNotificationInput = {
  /** Текст пуша */
  body: Scalars['String']['input'];
  /** Роут пуша */
  route: Scalars['String']['input'];
  /** Id роута */
  routeId: Scalars['String']['input'];
  /** Заголовок пуша */
  title: Scalars['String']['input'];
  /** Id пользователя */
  userId: Scalars['String']['input'];
};

export type CreateNotifyLetterInput = {
  referralProgramId: Scalars['String']['input'];
};

export type CreatePartInput = {
  /** Дата и время начала смены в формате ISO */
  dateFrom: Scalars['DateTime']['input'];
  duration?: InputMaybe<Scalars['String']['input']>;
  /** id объекта, на который ставится смена */
  facilityId: Scalars['String']['input'];
  lunch?: InputMaybe<Scalars['Boolean']['input']>;
  /** Длительность обеда в минутах */
  lunchDuration: Scalars['Int']['input'];
  /** id должности на которую ставится смена */
  positionId: Scalars['String']['input'];
  /** Дата и время окончания смены в формате ISO */
  shiftEndDate: Scalars['DateTime']['input'];
  /** id пользователя, на которого создается смена */
  userId: Scalars['String']['input'];
};

export type CreatePollsReportInput = {
  /** Id объекта */
  facilityId?: InputMaybe<Scalars['String']['input']>;
  /** Месяц */
  month: Scalars['Int']['input'];
};

export type CreateShiftPenaltyInput = {
  /** Причина */
  cause?: InputMaybe<Scalars['String']['input']>;
  /** Комментарий */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Стоимость */
  cost?: InputMaybe<Scalars['Int']['input']>;
  /** Id смены */
  shiftId: Scalars['String']['input'];
  /** Статус */
  shiftPenaltyStatus: ShiftPenaltyStatus;
};

export type CreateTemporaryShiftInput = {
  /** Id автора смены */
  authorId?: InputMaybe<Scalars['String']['input']>;
  /** Дата и время конца */
  dateEnd: Scalars['DateTime']['input'];
  /** Дата начала смены */
  dateFrom: Scalars['DateTime']['input'];
  /** Массив Id Объектов смены для отправки уведломления */
  facilityIds: Array<Scalars['String']['input']>;
  /** длительность обеда в минутах */
  lunchDuration?: InputMaybe<Scalars['Int']['input']>;
  /** Тип выплаты за работу */
  payout?: InputMaybe<PayoutType>;
  /** Id позиции смены */
  positionId: Scalars['String']['input'];
  /** Id объекта смены */
  shiftFacilityId: Scalars['String']['input'];
  /** Единицы измерения для оплаты */
  unit?: InputMaybe<UnitType>;
};

export type CreateTimesheetByMonthAndFacilityGroupInput = {
  /** Id города */
  cityId?: InputMaybe<Scalars['String']['input']>;
  /** Id группы объектов */
  facilityGroupId: Scalars['String']['input'];
  /** Начало месяца в формате YYYY-MM-DD */
  startOfMonth: Scalars['String']['input'];
};

export type CreateTimesheetByMonthAndFacilityInput = {
  /** Id объекта */
  facilityId?: InputMaybe<Scalars['String']['input']>;
  /** Начало месяца в формате YYYY-MM-DD */
  startOfMonth: Scalars['String']['input'];
};

export type CreateUserByPersonalInfoMainInput = {
  /** Разрешить работать без Наймикс */
  allowWorkWithoutNaimix?: InputMaybe<Scalars['Boolean']['input']>;
  /** Идентификатор страны, гражданином которой является пользователь */
  citizenshipId?: InputMaybe<Scalars['String']['input']>;
  /** Имя пользователя */
  firstname: Scalars['String']['input'];
  /** Фамилия пользователя */
  lastname: Scalars['String']['input'];
  /** Отчество пользователя */
  patronymic?: InputMaybe<Scalars['String']['input']>;
  /** Номер телефона пользователя */
  phone: Scalars['String']['input'];
};

export type CreateUserErpInput = {
  citizenshipId?: InputMaybe<Scalars['String']['input']>;
  /** id города пользователя */
  cityId?: InputMaybe<Scalars['String']['input']>;
  /** Id Пользователя в ERP */
  externalId?: InputMaybe<Scalars['String']['input']>;
  facilityId?: InputMaybe<Scalars['String']['input']>;
  /** Массив id объектов на которые должен быть назначен пользователь */
  facilityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  patronymic?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  /** Id основной должности пользователя */
  positionId?: InputMaybe<Scalars['String']['input']>;
  /** Массив id должностей которые пользователь выбрал при регистрации */
  positionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  role: Role;
  sendDownloadSms?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateUserGradeInput = {
  character: Scalars['Int']['input'];
  facilityId: Scalars['String']['input'];
  positionId: Scalars['String']['input'];
  responsiveness: Scalars['Int']['input'];
  speed: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
};

export type CreateUserReviewInput = {
  facilityId: Scalars['String']['input'];
  isGood?: InputMaybe<Scalars['Boolean']['input']>;
  positionId: Scalars['String']['input'];
  text: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type CronDashboardStatisticInput = {
  /** Период сбора статистики */
  period: StatsAggregationPeriod;
};

export type CropDataInput = {
  height: Scalars['Int']['input'];
  left: Scalars['Int']['input'];
  top: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export type DashBoardDataModel = {
  __typename?: 'DashBoardDataModel';
  /** it is a date */
  createdAt: Scalars['Date']['output'];
  /** it is a value */
  value: Scalars['Int']['output'];
};

export type DeclineRequestShiftSuggestionsInput = {
  /** Массив идентификаторов строк откликнувшихся исполнителей в таблице request_shift_suggestions */
  declinedSuggestionIds: Array<Scalars['String']['input']>;
  /** Идентификатор графика заявки */
  requestScheduleId: Scalars['String']['input'];
};

export type DeleteMeInput = {
  phone: Scalars['String']['input'];
};

export type DeletePromotionInFacilityGroupInput = {
  /** Идентификатор бонусной программы */
  promotionId: Scalars['ID']['input'];
};

export type DeleteReferralProgramInput = {
  /** Идентификатор реферальной программы */
  id: Scalars['String']['input'];
};

export type DeleteUserInput = {
  userId: Scalars['ID']['input'];
};

/** Варианты фильтрации смен, которые не нужно отображать в табеле супервайзера */
export enum DoNotShowShiftEnum {
  InFuture = 'IN_FUTURE',
  Refused = 'REFUSED',
  ToPayment = 'TO_PAYMENT',
  UserWithoutShifts = 'USER_WITHOUT_SHIFTS',
  Wasted = 'WASTED',
  WithoutPayment = 'WITHOUT_PAYMENT'
}

export type Document = {
  __typename?: 'Document';
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  file: File;
  fileId: Scalars['String']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  type: DocumentType;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  user: User;
  /** ID владельца документа */
  userId: Scalars['String']['output'];
};

export type DocumentReview = {
  __typename?: 'DocumentReview';
  /** Комментарий при отклонение документов */
  comment?: Maybe<Scalars['String']['output']>;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  rejectReason: RejectReason;
  reviewer: User;
  /** ID владельца документов */
  reviewerId: Scalars['String']['output'];
  /** Статус проверки документа */
  status: DocumentStatus;
  /** Тип документа на проверку */
  type: ReviewType;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  user: User;
  /** ID владельца документов */
  userId: Scalars['String']['output'];
};

export type DocumentReviewInput = {
  /** Комментарий при отклонение документов */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Причина отклонения документов */
  rejectReason?: InputMaybe<RejectReason>;
  /** Статус проверки документа */
  status: DocumentStatus;
  /** Тип документа на проверку */
  type: ReviewType;
  /** ID владельца документов */
  userId: Scalars['String']['input'];
};

/** Статус проверки документа */
export enum DocumentStatus {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED'
}

/** Тип загружаемого документа */
export enum DocumentType {
  ArrivalNotificationFirstSide = 'ARRIVAL_NOTIFICATION_FIRST_SIDE',
  ArrivalNotificationSecondSide = 'ARRIVAL_NOTIFICATION_SECOND_SIDE',
  ForeignPassportBirthPlace = 'FOREIGN_PASSPORT_BIRTH_PLACE',
  ForeignPassportPersonalInformation = 'FOREIGN_PASSPORT_PERSONAL_INFORMATION',
  MainPage = 'MAIN_PAGE',
  MedicalInvoice = 'MEDICAL_INVOICE',
  MigrationCard = 'MIGRATION_CARD',
  MigrationCardBackSide = 'MIGRATION_CARD_BACK_SIDE',
  Page2_3 = 'PAGE2_3',
  Page28_29 = 'PAGE28_29',
  PassportTranslationNotarizationScan = 'PASSPORT_TRANSLATION_NOTARIZATION_SCAN',
  PassportTranslationScan = 'PASSPORT_TRANSLATION_SCAN',
  Registration = 'REGISTRATION',
  Selfie = 'SELFIE'
}

/** Статус проверки документов */
export enum DocumentsStatus {
  Accepted = 'ACCEPTED',
  InProgress = 'IN_PROGRESS',
  NoDocuments = 'NO_DOCUMENTS',
  PartlyAccepted = 'PARTLY_ACCEPTED',
  Rejected = 'REJECTED'
}

export type EditPromotionInFacilityGroupInput = {
  /** Количество плохих смен для аннулирования */
  badShiftCount?: InputMaybe<Scalars['Int']['input']>;
  /** Дата и время окончания бонусной программы */
  dateTimeEnd?: InputMaybe<Scalars['String']['input']>;
  /** Дата и время начала бонусной программы */
  dateTimeStart?: InputMaybe<Scalars['String']['input']>;
  /** Название бонусной программы */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Идентификатор бонусной программы */
  promotionId: Scalars['ID']['input'];
  /** Тип повтора бонусной программы (условия запуска бонусной программы с нуля) */
  repeatCondition?: InputMaybe<PromotionRepeatConditionEnum>;
  /** Количество смен для выполнения условий получения бонуса */
  requirementShiftCount?: InputMaybe<Scalars['Int']['input']>;
  /** Сумма вознаграждения */
  rewardSum?: InputMaybe<Scalars['Int']['input']>;
  /** Тип вознаграждения */
  rewardType?: InputMaybe<PromotionRewardTypeEnum>;
};

export type EditPromotionInFacilityInput = {
  /** Количество плохих смен для аннулирования */
  badShiftCount?: InputMaybe<Scalars['Int']['input']>;
  /** Идентификатор объекта */
  facilityId: Scalars['String']['input'];
  /** Название бонусной программы */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Идентификатор бонусной программы */
  promotionId: Scalars['ID']['input'];
  /** Количество смен для выполнения условий получения бонуса */
  requirementShiftCount?: InputMaybe<Scalars['Int']['input']>;
  /** Сумма вознаграждения */
  rewardSum?: InputMaybe<Scalars['Int']['input']>;
  /** Тип вознаграждения */
  rewardType?: InputMaybe<PromotionRewardTypeEnum>;
};

export type EditReferralProgramInput = {
  /** Описание реферальной программы */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Дата и время окончания реферальной программы */
  endDatetime?: InputMaybe<Scalars['String']['input']>;
  /** Идентификатор реферальной программы */
  id: Scalars['ID']['input'];
  /** Подтверждение изменения в случае пересечения дат с другими реферальными программами */
  isApproved?: InputMaybe<Scalars['Boolean']['input']>;
  /** Подтверждение публикации реферальной программы */
  isPublication?: InputMaybe<Scalars['Boolean']['input']>;
  /** Название реферальной программы */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Количество хороших смен */
  numberOfGoodShifts?: InputMaybe<Scalars['Int']['input']>;
  /** Сумма выплаты пригласившему */
  rewardForTheReferer?: InputMaybe<Scalars['Int']['input']>;
  /** Дата и время старта реферальной программы */
  startDatetime?: InputMaybe<Scalars['String']['input']>;
};

/** DTO для редактирования смены */
export type EditShiftInput = {
  /** Дата и время начала смены в формате timestamp */
  dateFrom: Scalars['String']['input'];
  /** ID смены */
  id: Scalars['ID']['input'];
  /** Длительность обеда в минутах */
  lunchDuration: Scalars['Int']['input'];
  /** Дата и время окончания смены в формате timestamp */
  shiftEndDate: Scalars['String']['input'];
};

export type EmptyQueueInput = {
  /** Repeat interval */
  interval?: InputMaybe<Scalars['Int']['input']>;
  type: JobName;
};

/** DTO с набором данных необходимых при создании/редактировании группы */
export type EverythingIsPossibleBonusInput = {
  /** Включен или выключен "Всё Возможно Бонус" на объекте */
  everythingIsPossibleBonus?: Scalars['Boolean']['input'];
  /** ID группы */
  facilityId: Scalars['String']['input'];
  /** Сумма для выплат с учетом % налога "Всё Возможно Бонус" на объекте */
  possibleBonusPaymentSum?: Scalars['Int']['input'];
  /** Количество смен для достижения бонуса "Всё Возможно Бонус" на объекте */
  possibleBonusShiftCount?: Scalars['Int']['input'];
  /** Сумма которая отображается на баннере "Всё Возможно Бонус" на объекте */
  possibleBonusViewSum?: Scalars['Int']['input'];
};

/** Тип Файла который загружаем для импорта данных в модерацию */
export enum ExcelVariant {
  CourierOzon = 'COURIER_OZON',
  OzonPickerNative = 'OZON_PICKER_NATIVE',
  PickerOzon = 'PICKER_OZON'
}

export type Facility = {
  __typename?: 'Facility';
  /** Адрес объекта */
  address?: Maybe<Address>;
  /** @deprecated Адрес храним в поле addressString */
  addressId?: Maybe<Scalars['String']['output']>;
  /** Адрес объекта */
  addressString?: Maybe<Scalars['String']['output']>;
  /** Массив содержащий список профессий на объекте со ставками по ним */
  casings?: Maybe<Array<Casing>>;
  checkInMeasurement: Scalars['Int']['output'];
  checkOutMeasurement: Scalars['Int']['output'];
  city?: Maybe<City>;
  cityId?: Maybe<Scalars['String']['output']>;
  /** Менеджеры объекта */
  clientManagers?: Maybe<Array<User>>;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Расчетное поле для расстояния от гео точки в километрах */
  distance?: Maybe<Scalars['Float']['output']>;
  /** Включен или выключен "Всё Возможно Бонус" на объекте */
  everythingIsPossibleBonus: Scalars['Boolean']['output'];
  /** Id объекта в ERP */
  externalId?: Maybe<Scalars['String']['output']>;
  /** Галерея фотографий профессии объекта */
  facilityGallery?: Maybe<Array<FacilityGalleryEntity>>;
  facilityGroup?: Maybe<FacilityGroup>;
  facilityGroupId?: Maybe<Scalars['String']['output']>;
  facilitySupervisors: Array<Supervisors>;
  facilityToUsers: Array<FacilityToUser>;
  foremen?: Maybe<Array<User>>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Есть ли на объекте возможность работать по справке без мед.книжки n-дней */
  isMedInvoiceAllowed: Scalars['Boolean']['output'];
  is_active?: Maybe<Scalars['Boolean']['output']>;
  /** Показывать ли пользователям на объекте бонусы */
  is_bonus_active: Scalars['Boolean']['output'];
  /** Показывать ли пользователям на объекте начисления */
  is_finance_active: Scalars['Boolean']['output'];
  latitude: Scalars['String']['output'];
  legalEntity?: Maybe<LegalEntity>;
  /** Идетнтификатор юр. лица */
  legalEntityId?: Maybe<Scalars['String']['output']>;
  longitude: Scalars['String']['output'];
  manualAccruals: Array<ManualAccrual>;
  metro?: Maybe<Scalars['String']['output']>;
  /** Станции метро */
  metroStations?: Maybe<Array<MetroStation>>;
  /** IDs станций метро */
  metroStationsIds?: Maybe<Array<Scalars['String']['output']>>;
  moderationReports: Array<ModerationReport>;
  /** Идентификатор объекта в Наймикс */
  naimixObjectId?: Maybe<Scalars['String']['output']>;
  /** Название объекта в Наймикс */
  naimixObjectName?: Maybe<Scalars['String']['output']>;
  /** Номер сценария Наймикс */
  naimixScenarioNumber?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** количество дней ожидания медицинской книжки (количество дней действия справки о прохождении осмотра перед получением медицинской книжки) */
  numberDaysWaitMedical: Scalars['Int']['output'];
  /** Массив содержащий список штрафов на объекте */
  penalty?: Maybe<Array<Penalty>>;
  /** Сумма для выплат с учетом % налога 'Всё Возможно Бонус' на объекте */
  possibleBonusPaymentSum: Scalars['Float']['output'];
  /** Количество смен для достижения бонуса "Всё Возможно Бонус" на объекте */
  possibleBonusShiftCount: Scalars['Float']['output'];
  /** Сумма для выплат с учетом % налога "Всё Возможно Бонус" на объекте */
  possibleBonusViewSum: Scalars['Float']['output'];
  /** Дочерние бонусные программы */
  promotions?: Maybe<Array<PromotionEntity>>;
  publicConditions?: Maybe<Array<PublicCondition>>;
  radius: Scalars['Int']['output'];
  /** Реферальные программы привязанные к объекту */
  referralPrograms?: Maybe<Array<ReferralProgramEntity>>;
  schedules: Array<Schedule>;
  shifts: Array<Shift>;
  /** Показывать ли пользователям заработок до модерации */
  showSalaryBeforeModeration: Scalars['Boolean']['output'];
  subscribers?: Maybe<Array<User>>;
  supervisors?: Maybe<Array<User>>;
  temporaryShifts: Array<TemporaryShift>;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  userExperiences: Array<UserExperience>;
  userGrades: Array<UserGrade>;
  userReviews: Array<UserReview>;
  vacancies?: Maybe<Array<Vacancy>>;
  workPosts?: Maybe<Array<WorkPost>>;
  zayavkaSchedules: Array<ZayavkaSchedule>;
  zayavkaShifts: Array<ZayavkaShift>;
  zayavkas: Array<Zayavka>;
};

/** Dto с набором данных необходимых при создании/редактировании объекта */
export type FacilityCardInput = {
  address?: InputMaybe<AddressInput>;
  /** Адрес объекта */
  addressString?: InputMaybe<Scalars['String']['input']>;
  /** Разрешённая погрешность при чекине */
  checkInMeasurement?: InputMaybe<Scalars['Int']['input']>;
  /** Разрешённая погрешность при чекауте */
  checkOutMeasurement?: InputMaybe<Scalars['Int']['input']>;
  /** Fias ID города объекта */
  cityFiasId?: InputMaybe<Scalars['String']['input']>;
  /** ID города объекта */
  cityId: Scalars['String']['input'];
  /** Id объекта в ERP */
  externalId?: InputMaybe<Scalars['String']['input']>;
  /** id группы объекта */
  facilityGroupId?: InputMaybe<Scalars['String']['input']>;
  /** Название объекта */
  id?: InputMaybe<Scalars['String']['input']>;
  is_active?: Scalars['Boolean']['input'];
  is_bonus_active?: Scalars['Boolean']['input'];
  is_finance_active?: Scalars['Boolean']['input'];
  /** Широта */
  latitude: Scalars['String']['input'];
  /** ID юр. лица */
  legalEntityId?: InputMaybe<Scalars['String']['input']>;
  /** Долгота */
  longitude: Scalars['String']['input'];
  metro?: InputMaybe<Scalars['String']['input']>;
  /** IDs станций метро */
  metroStationsIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** ID объекта в Наймикс */
  naimixObjectId?: InputMaybe<Scalars['String']['input']>;
  /** Название объекта в Наймикс */
  naimixObjectName?: InputMaybe<Scalars['String']['input']>;
  /** Номер сценария Наймикс */
  naimixScenarioNumber?: InputMaybe<Scalars['String']['input']>;
  /** Название объекта */
  name: Scalars['String']['input'];
  /** Радиус объекта для проверки CheckIn / CheckOut в метрах */
  radius: Scalars['Int']['input'];
  /** Показывать ли пользователям заработок до модерации */
  showSalaryBeforeModeration?: Scalars['Boolean']['input'];
};

/** поле по которому будет работать сортировка */
export enum FacilityFieldsSorting {
  Metro = 'metro',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type FacilityGalleryEntity = {
  __typename?: 'FacilityGalleryEntity';
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  facility: Facility;
  /** Идентификатор объекта */
  facilityId: Scalars['String']['output'];
  file?: Maybe<File>;
  /** Идентификатор файла с фотографией */
  fileId?: Maybe<Scalars['String']['output']>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Дата и время обновления */
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type FacilityGroup = {
  __typename?: 'FacilityGroup';
  /** Баланс клиента по группе объектов */
  balance: Scalars['Float']['output'];
  /** Директора группы объектов */
  clientDirectors?: Maybe<Array<User>>;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  facilities: Array<Facility>;
  facilityGroupBalanceOperations: Array<FacilityGroupBalanceOperation>;
  /** Менеджеры группы объектов */
  groupManagers?: Maybe<Array<User>>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** информация о файле с изображением группы */
  logo?: Maybe<File>;
  /** id файла который указан как логотип */
  logoId?: Maybe<Scalars['String']['output']>;
  /** Наименование группы объектов */
  name: Scalars['String']['output'];
  /** Бонусные программы */
  promotions?: Maybe<Array<PromotionEntity>>;
  publicConditions?: Maybe<Array<PublicCondition>>;
  /** Реферальные программы привязанные к группе объектов */
  referralPrograms?: Maybe<Array<ReferralProgramEntity>>;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
};

export type FacilityGroupBalanceOperation = {
  __typename?: 'FacilityGroupBalanceOperation';
  /** Сумма операции */
  amount: Scalars['Float']['output'];
  /** Сумма баланса после операции */
  balanceAfter: Scalars['Float']['output'];
  /** Сумма баланса перед операцией */
  balanceBefore: Scalars['Float']['output'];
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Дата операции */
  dayAndTime: Scalars['Date']['output'];
  /** Директора группы объектов */
  description: Scalars['String']['output'];
  facilityGroup: FacilityGroup;
  /** Id группы объекта */
  facilityGroupId: Scalars['String']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Группа объекта */
  operation?: Maybe<BalanceOperationType>;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
};

/** DTO с набором данных необходимых при создании/редактировании группы */
export type FacilityGroupCardInput = {
  /** ID группы */
  id?: InputMaybe<Scalars['String']['input']>;
  /** ID лого группы */
  logoId?: InputMaybe<Scalars['String']['input']>;
  /** Название группы */
  name: Scalars['String']['input'];
};

/** Параметры поиска профессий группы объектов */
export type FacilityGroupCasingsInput = {
  cityId?: InputMaybe<Scalars['String']['input']>;
  /** id группы объектов */
  facilityGroupId?: InputMaybe<Scalars['String']['input']>;
  /** id объекта */
  facilityId?: InputMaybe<Scalars['String']['input']>;
  /** Номер страницы */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** id профессии */
  positionId?: InputMaybe<Scalars['String']['input']>;
  /** Строка поиска */
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  /** Количество элементов на странице */
  size?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<FacilityGroupCasingsSorting>;
  sortWay?: InputMaybe<SortingWay>;
};

/** Поля сортировки списка профессий группы объектов */
export enum FacilityGroupCasingsSorting {
  City = 'city',
  Facility = 'facility',
  Position = 'position'
}

export type FacilityListInput = {
  cityId?: InputMaybe<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** ID объекта в Наймикс */
  naimixObjectId?: InputMaybe<Scalars['String']['input']>;
  /** Номер страницы */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Строка поиска */
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  /** Количество элементов на странице */
  size?: InputMaybe<Scalars['Int']['input']>;
  /** поле по которому сортировка, по умолчанию updatedAt */
  sortBy?: InputMaybe<FacilityFieldsSorting>;
  /** Направление сортировки */
  sortWay?: InputMaybe<SortingWay>;
};

export type FacilityToUser = {
  __typename?: 'FacilityToUser';
  facility: Facility;
  facilityId: Scalars['String']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type File = {
  __typename?: 'File';
  casingGallery: CasingGalleryEntity;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  document: Document;
  facilityGroups: Array<FacilityGroup>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  mimetype: Scalars['String']['output'];
  originalName?: Maybe<Scalars['String']['output']>;
  path: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  story: Story;
  storySlide: StorySlide;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
};

export type FindUserShiftsInput = {
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
  facilityId?: InputMaybe<Scalars['String']['input']>;
  /** Скрывать отмененные смены */
  hideRefused?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type ForemanShift = {
  __typename?: 'ForemanShift';
  /** Id автора */
  authorId: Scalars['String']['output'];
  /** комментарий к смене */
  comment?: Maybe<Scalars['String']['output']>;
  /** Комментарии бригадиров к смене */
  comments: Array<CommentEntity>;
  /** Размер оплаты по смене */
  cost: Scalars['Float']['output'];
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Дата и время конца */
  dateEnd: Scalars['Date']['output'];
  /** Дата и время начала */
  dateFrom: Scalars['Date']['output'];
  deletedDate: Scalars['Date']['output'];
  /** Длительность смены в минутах */
  duration: Scalars['String']['output'];
  empty: Scalars['Boolean']['output'];
  /** Объект */
  facility?: Maybe<Facility>;
  facilityId: Scalars['String']['output'];
  /** Id Бригадира, которому по подписке должна придти информация */
  foremanId?: Maybe<Scalars['String']['output']>;
  /** размер оплаты в час */
  hourRate?: Maybe<Scalars['Float']['output']>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  /** Является ли смена стажировочной */
  isProbation: Scalars['Boolean']['output'];
  /** длительность обеда в минутах */
  lunchDuration?: Maybe<Scalars['Int']['output']>;
  /** Чекины/Чекауты */
  marks: Array<Mark>;
  /** Объект модерации */
  moderation?: Maybe<Moderation>;
  /** Тип выплаты за работу */
  payout: PayoutType;
  /** Штраф на смене */
  penalties?: Maybe<Array<ShiftPenalty>>;
  /** Объект должности */
  position?: Maybe<Position>;
  /** Id должности на смене */
  positionId: Scalars['String']['output'];
  /** Ставка за смену на период стажировки */
  probationRate?: Maybe<Scalars['Float']['output']>;
  rejectedById?: Maybe<Scalars['String']['output']>;
  /** График */
  schedule?: Maybe<Schedule>;
  /** Id графика */
  scheduleId?: Maybe<Scalars['String']['output']>;
  selfEmployedRate?: Maybe<Scalars['Int']['output']>;
  shiftForemen: Array<ShiftForeman>;
  stage: ShiftStage;
  status: ShiftStatus;
  /** Единицы измерения для оплаты */
  unit: UnitType;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  /** Пользователь */
  user?: Maybe<User>;
  userId: Scalars['String']['output'];
  /** Значение для смен по сдельной оплате */
  value?: Maybe<Scalars['Float']['output']>;
  /** Заявка, если смена относится к Заявке */
  zayavka?: Maybe<Zayavka>;
  /** Id заявки если смена привязана к заявке */
  zayavkaId?: Maybe<Scalars['String']['output']>;
  zayavkaShifts: Array<ZayavkaShift>;
};

export type GetCitiesInput = {
  /** Поиск по названию города */
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  /** Направление сортировки */
  sortWay?: InputMaybe<SortingWay>;
};

export type GetDemoAgencyContractNaimixModel = {
  __typename?: 'GetDemoAgencyContractNaimixModel';
  /** Файл в base64 */
  fileContent: Scalars['String']['output'];
};

export type GetDemoFrameContractNaimixModel = {
  __typename?: 'GetDemoFrameContractNaimixModel';
  /** Файл в base64 */
  fileContent: Scalars['String']['output'];
};

export type GetDemoPdContractNaimixModel = {
  __typename?: 'GetDemoPdContractNaimixModel';
  /** Файл в base64 */
  fileContent: Scalars['String']['output'];
};

export type GetFacilitiesByFacilityGroupOfFacilityIdInput = {
  facilityId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type GetFreeRequestShiftsForCalendarInput = {
  requestScheduleId: Scalars['ID']['input'];
};

export type GetLeadsV2Model = {
  __typename?: 'GetLeadsV2Model';
  /** Всего элементов' */
  count: Scalars['Int']['output'];
  /** Лиды */
  leads: Array<Lead>;
  /** Количество страниц */
  pages: Scalars['Int']['output'];
};

export type GetListPromotionsForFacilityGroupInput = {
  /** Идентификатор группы объектов */
  facilityGroupId: Scalars['String']['input'];
};

export type GetListPromotionsForFacilityInput = {
  /** Идентификатор объекта */
  facilityId: Scalars['String']['input'];
};

export type GetListVacanciesInput = {
  /** Нижняя граница широты */
  latBottom: Scalars['Float']['input'];
  /** Верхняя граница широты */
  latTop: Scalars['Float']['input'];
  /** Ограничение по количеству выдаваемой информации */
  limit: Scalars['Int']['input'];
  /** Левая граница долготы */
  lonLeft: Scalars['Float']['input'];
  /** Правая граница долготы */
  lonRight: Scalars['Float']['input'];
  /** Отступ выдаваемой информации */
  offset: Scalars['Int']['input'];
  /** Список идентификаторов профессий */
  positionIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GetNaimixContractNaimixModel = {
  __typename?: 'GetNaimixContractNaimixModel';
  /** Файл в base64 */
  fileContent: Scalars['String']['output'];
};

export type GetReferralProgramInput = {
  /** Идентификатор реферальной программы */
  id: Scalars['String']['input'];
};

export type GetReferralProgramsPaginatedInput = {
  /** Идентификатор группы объектов */
  facilityGroupId?: InputMaybe<Scalars['String']['input']>;
  /** Идентификатор объекта */
  facilityId?: InputMaybe<Scalars['String']['input']>;
  /** Номер страницы */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Поиск по тексту */
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  /** Количество элементов на странице */
  size?: InputMaybe<Scalars['Int']['input']>;
  /** Идентификатор объекта */
  status?: InputMaybe<ReferralProgramStatusEnum>;
};

export type GetReferralProgramsPaginatedModel = {
  __typename?: 'GetReferralProgramsPaginatedModel';
  /** Всего элементов' */
  count: Scalars['Int']['output'];
  /** Количество страниц */
  pages: Scalars['Int']['output'];
  /** Реферальные программы */
  referralPrograms: Array<ReferralProgramEntity>;
};

export type GetSchedulerReferralProgramsInput = {
  /** Идентификатор реферальной программы */
  referralProgramId?: InputMaybe<Scalars['String']['input']>;
};

export type GetStatisticInput = {
  /** группа объектов */
  facilityGroupId?: InputMaybe<Scalars['String']['input']>;
  /** объект */
  facilityId?: InputMaybe<Scalars['String']['input']>;
  /** Период фильтрации статистики */
  period: StatisticPeriod;
  /** Тип графика */
  types: Array<ChartType>;
};

export type GetSupervisorWorkSheetDataModel = {
  __typename?: 'GetSupervisorWorkSheetDataModel';
  shifts?: Maybe<Array<Shift>>;
  user: User;
  workPost: WorkPost;
};

export type GetSupervisorWorkSheetFilterDataModel = {
  __typename?: 'GetSupervisorWorkSheetFilterDataModel';
  users: Array<User>;
  workPosts: Array<WorkPost>;
};

export type GetSupervisorWorkSheetInput = {
  doNotShowShifts?: InputMaybe<Array<DoNotShowShiftEnum>>;
  facilityId: Scalars['String']['input'];
  month: Scalars['Int']['input'];
  order?: InputMaybe<OrderDirection>;
  orderBy?: InputMaybe<GetSupervisorWorkSheetOrderByEnum>;
  positionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
  year: Scalars['Int']['input'];
};

export type GetSupervisorWorkSheetModel = {
  __typename?: 'GetSupervisorWorkSheetModel';
  data: Array<GetSupervisorWorkSheetDataModel>;
  filterData: GetSupervisorWorkSheetFilterDataModel;
};

export enum GetSupervisorWorkSheetOrderByEnum {
  Lastname = 'LASTNAME'
}

export type GetUserDemoFrameContractInput = {
  userContractId: Scalars['String']['input'];
};

export type GetUserNaimixContractInput = {
  userContractId: Scalars['String']['input'];
};

export type GetUsersWhoRespondedToTheRequestScheduleInput = {
  requestScheduleId: Scalars['ID']['input'];
};

export type GetUsersWhoRespondedToTheRequestShiftInput = {
  requestShiftId: Scalars['ID']['input'];
};

export type GetVacanciesOnMapInput = {
  /** Нижняя граница широты */
  latBottom: Scalars['Float']['input'];
  /** Верхняя граница широты */
  latTop: Scalars['Float']['input'];
  /** Левая граница долготы */
  lonLeft: Scalars['Float']['input'];
  /** Правая граница долготы */
  lonRight: Scalars['Float']['input'];
  /** Список идентификаторов профессий */
  positionIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GetVacancyGalleryInput = {
  /** Идентификатор вакансии */
  vacancyId: Scalars['String']['input'];
};

export type GroupManager = {
  __typename?: 'GroupManager';
  A: Scalars['String']['output'];
  B: Scalars['String']['output'];
  facilityGroup: FacilityGroup;
  user: User;
};

export type IVacancyStartTime = {
  __typename?: 'IVacancyStartTime';
  timesEnd: Array<Scalars['String']['output']>;
  timesStart: Array<Scalars['String']['output']>;
};

export type InitAgencyContractResponseNaimixModel = {
  __typename?: 'InitAgencyContractResponseNaimixModel';
  errorCode?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type InitUserFrameContractNaimixInput = {
  userContractId: Scalars['String']['input'];
};

export type InviteFriendProgressOutput = {
  __typename?: 'InviteFriendProgressOutput';
  /** Текущее количество хороших смен */
  currentShifts: Scalars['Int']['output'];
  /** Сумма бонуса */
  sum: Scalars['Float']['output'];
  /** Необходимое количество смен для достижения бонуса "Приведи друга" */
  targetShifts: Scalars['Int']['output'];
};

/** Job Name */
export enum JobName {
  NotifyLetter = 'NotifyLetter',
  SendPush = 'SendPush',
  StoryV1 = 'StoryV1',
  UnpublishPush = 'UnpublishPush',
  UnpublishStory = 'UnpublishStory'
}

export type Lead = {
  __typename?: 'Lead';
  /** ID Пользователя, который создал лида */
  authorId?: Maybe<Scalars['String']['output']>;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Дата приёмки/отказа */
  date?: Maybe<Scalars['Date']['output']>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Дата достижения бонуса "Приведи друга" */
  inviteFriendDate?: Maybe<Scalars['Date']['output']>;
  /** Обработан */
  isProcessed: Scalars['Boolean']['output'];
  /** Дата достижения бонуса "Очко" */
  ochkoDate?: Maybe<Scalars['Date']['output']>;
  /** Признак выплаты рефералу */
  referralPaymentDatetime?: Maybe<Scalars['Date']['output']>;
  /** Реферальная программа, к которой привязано пуш уведомление */
  referralProgram?: Maybe<ReferralProgramEntity>;
  /** Идентификатор реферальной программы, во время действия которой был зарегистрирован пользователь */
  referralProgramId?: Maybe<Scalars['String']['output']>;
  /** Признак выплаты реферреру */
  referrerPaymentDatetime?: Maybe<Scalars['Date']['output']>;
  /** Статус */
  status: LeadStatus;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  /** Реферал */
  user?: Maybe<User>;
  /** ID Пользователя, которого пригласили */
  userId: Scalars['String']['output'];
};

/** Поля доступные для сортировки  */
export enum LeadFieldsForSorting {
  CreatedAt = 'createdAt',
  Date = 'date',
  InviteFriendDate = 'inviteFriendDate',
  Phone = 'phone',
  ReferrerPaymentDatetime = 'referrerPaymentDatetime',
  UpdatedAt = 'updatedAt'
}

export type LeadListInput = {
  /** Фильтр по минимальной дате достижения порога смен для выплаты */
  achieveDateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Фильтр по максимальной дате достижения порога смен для выплаты */
  achieveDateTo?: InputMaybe<Scalars['DateTime']['input']>;
  /** ID группы объектов */
  facilityGroupId?: InputMaybe<Scalars['String']['input']>;
  /** ID объекта */
  facilityId?: InputMaybe<Scalars['String']['input']>;
  /** Фильтр по минимальной дате создания лида */
  inviteDateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Фильтр по максимальной дате создания лида */
  inviteDateTo?: InputMaybe<Scalars['DateTime']['input']>;
  /** Номер страницы */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Идентификатор реферальной программы */
  referralProgramId?: InputMaybe<Scalars['String']['input']>;
  /** Роль пользователя */
  role?: InputMaybe<Role>;
  /** Строка поиска */
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  /** Количество элементов на странице */
  size?: InputMaybe<Scalars['Int']['input']>;
  /** поле по которому сортировка, по умолчанию updatedAt */
  sortBy?: InputMaybe<LeadFieldsForSorting>;
  /** Направление сортировки */
  sortWay?: InputMaybe<SortingWay>;
  status?: InputMaybe<LeadStatus>;
};

export type LeadOutput = {
  __typename?: 'LeadOutput';
  /** ID Пользователя, который создал лида */
  authorId?: Maybe<Scalars['String']['output']>;
  /** Количество учтенных смен */
  countShifts?: Maybe<Scalars['Int']['output']>;
  /** Количество смен в статусе BAD */
  countShiftsBad?: Maybe<Scalars['Int']['output']>;
  /** Количество смен в статусе GOOD */
  countShiftsGood?: Maybe<Scalars['Int']['output']>;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Дата приёмки/отказа */
  date?: Maybe<Scalars['Date']['output']>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Дата достижения бонуса "Приведи друга" */
  inviteFriendDate?: Maybe<Scalars['Date']['output']>;
  /** Прогресс бонуса "Приведи друга" */
  inviteFriendProgress: InviteFriendProgressOutput;
  /** Обработан */
  isProcessed: Scalars['Boolean']['output'];
  /** Дата достижения бонуса "Очко" */
  ochkoDate?: Maybe<Scalars['Date']['output']>;
  /** Признак выплаты рефералу */
  referralPaymentDatetime?: Maybe<Scalars['Date']['output']>;
  /** Реферальная программа, к которой привязано пуш уведомление */
  referralProgram?: Maybe<ReferralProgramEntity>;
  /** Идентификатор реферальной программы, во время действия которой был зарегистрирован пользователь */
  referralProgramId?: Maybe<Scalars['String']['output']>;
  /** Признак выплаты реферреру */
  referrerPaymentDatetime?: Maybe<Scalars['Date']['output']>;
  /** Статус */
  status: LeadStatus;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  /** Реферал */
  user?: Maybe<User>;
  /** ID Пользователя, которого пригласили */
  userId: Scalars['String']['output'];
};

export enum LeadStatus {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type LegalEntity = {
  __typename?: 'LegalEntity';
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  facility?: Maybe<Facility>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  /** Список договоров, заключенных с исполнителями на объекте */
  userContracts?: Maybe<Array<UserContract>>;
};

export type LoginInput = {
  /** Токен android устройства пользователя на который отсылаются push уведомления */
  androidToken?: InputMaybe<Scalars['String']['input']>;
  /** Токен ios устройства пользователя на который отсылаются push уведомления */
  iosToken?: InputMaybe<Scalars['String']['input']>;
  /** Пароль */
  password: Scalars['String']['input'];
  /** Номер телефона */
  phone: Scalars['String']['input'];
  /** Запомнить меня */
  rememberMe?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LoginTestInput = {
  /** Токен android устройства пользователя на который отсылаются push уведомления */
  androidToken?: InputMaybe<Scalars['String']['input']>;
  /** СМС код */
  code: Scalars['String']['input'];
  /** Токен Huawei устройства пользователя на который отсылаются push уведомления */
  huaweiToken?: InputMaybe<Scalars['String']['input']>;
  /** Токен ios устройства пользователя на который отсылаются push уведомления */
  iosToken?: InputMaybe<Scalars['String']['input']>;
  /** Номер телефона, с девятки без +7 или 8 */
  phone: Scalars['String']['input'];
  /** Запомнить меня */
  rememberMe?: InputMaybe<Scalars['Boolean']['input']>;
  /** Информация об устройстве пользователя */
  userDevice?: InputMaybe<UserDeviceInput>;
};

export type ManualAccrual = {
  __typename?: 'ManualAccrual';
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Дата и время начала */
  dateFrom: Scalars['String']['output'];
  /** Дата и время конца */
  dateTo: Scalars['String']['output'];
  /** Объект */
  facility?: Maybe<Facility>;
  /** Id объекта */
  facilityId: Scalars['String']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Строки выплат */
  items?: Maybe<Array<ManualAccrualItem>>;
  /** Существующие выплаты пользователей */
  manualUserAccruals?: Maybe<Array<ManualUserAccrual>>;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
};

export type ManualAccrualItem = {
  __typename?: 'ManualAccrualItem';
  accrual: ManualAccrual;
  /** Id выплаты за период */
  accrualId: Scalars['String']['output'];
  /** Сумма надобавки Активный месяца за выполненные смены */
  activeMonth?: Maybe<Scalars['Float']['output']>;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Id выплаты за период */
  customName?: Maybe<Scalars['String']['output']>;
  /** Сумма надбавки Трудяга за выполненные смены */
  hardWorker?: Maybe<Scalars['Float']['output']>;
  hourRate: Scalars['Int']['output'];
  hours: Scalars['Int']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Должность за которую делается начисление */
  position?: Maybe<Position>;
  /** Id должности */
  positionId?: Maybe<Scalars['String']['output']>;
  /** Сумма за выполненные смены по ставке без бонусов */
  rateAmount?: Maybe<Scalars['Float']['output']>;
  /** Сумма надобавки Супер-Трудяга за выполненные смены */
  superWorker?: Maybe<Scalars['Float']['output']>;
  /** Сумма со всеми надбавками */
  totalAmount?: Maybe<Scalars['Float']['output']>;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  /** Пользователь */
  user?: Maybe<User>;
  /** Id пользователя */
  userId: Scalars['String']['output'];
  /** Сумма начислений пользователю */
  userSum?: Maybe<Scalars['Float']['output']>;
};

export type ManualAccrualSenderInput = {
  /** Id выплаты за период */
  accrualId: Scalars['String']['input'];
  /** Пользователи назначенные на объект */
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ManualUserAccrual = {
  __typename?: 'ManualUserAccrual';
  accrual: ManualAccrual;
  /** Id выплаты за период */
  accrualId: Scalars['String']['output'];
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Показывать в мобильном приложении */
  enabled: Scalars['Boolean']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Сумма */
  summ?: Maybe<Scalars['Float']['output']>;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  /** Модель выплаты к которой относится этот элемент */
  user?: Maybe<ManualAccrual>;
  /** Id пользователя */
  userId: Scalars['String']['output'];
};

export type MapMetrikaModel = {
  __typename?: 'MapMetrikaModel';
  in4km: Scalars['Int']['output'];
  in10km: Scalars['Int']['output'];
  inCity: Scalars['Int']['output'];
};

export type MapPlaceModel = {
  __typename?: 'MapPlaceModel';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  point?: Maybe<Scalars['String']['output']>;
  positions?: Maybe<Array<MapProfessionModel>>;
  shopName: Scalars['String']['output'];
};

export type MapProfessionModel = {
  __typename?: 'MapProfessionModel';
  erp: MapMetrikaModel;
  name: Scalars['String']['output'];
  smena: MapMetrikaModel;
};

export type Mark = {
  __typename?: 'Mark';
  /** Точность координат, в метрах */
  accuracy?: Maybe<Scalars['Int']['output']>;
  /** id пользователя который сделал отметку на смене - работник или бригадир если сделал отметку за работника */
  authorId?: Maybe<Scalars['String']['output']>;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** расстояние от отметки до точки объекта (по умолчанию null а не 0!) */
  distance?: Maybe<Scalars['Int']['output']>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  latitude?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['String']['output']>;
  shift: Shift;
  shiftId: Scalars['String']['output'];
  status: MarkStatus;
  statusMessage: Scalars['String']['output'];
  time: Scalars['Date']['output'];
  type: MarkType;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

/** Mark status */
export enum MarkStatus {
  Bad = 'BAD',
  Good = 'GOOD'
}

/** Mark type */
export enum MarkType {
  Checkin = 'CHECKIN',
  Checkout = 'CHECKOUT'
}

export type MedicalAttestation = {
  __typename?: 'MedicalAttestation';
  /** Даты конца аттестации строка даты YYYY-MM-DD */
  attestationEnd: Scalars['String']['output'];
  /** Даты начала аттестации строка даты YYYY-MM-DD */
  attestationStart: Scalars['String']['output'];
  bookNumber: Scalars['String']['output'];
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  userId: Scalars['String']['output'];
};

export type MetroLineOutput = {
  __typename?: 'MetroLineOutput';
  color: Scalars['String']['output'];
  firstLastOnAline: Scalars['String']['output'];
  name: Scalars['String']['output'];
  stations: Array<MetroStation>;
};

export type MetroStation = {
  __typename?: 'MetroStation';
  addressId: Scalars['String']['output'];
  city: Scalars['String']['output'];
  cityEntity?: Maybe<City>;
  cityId?: Maybe<Scalars['String']['output']>;
  color: Scalars['String']['output'];
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Объекты привязанные к станции метро */
  facilities?: Maybe<Array<Facility>>;
  firstLastOnALine: Scalars['String']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  latitude: Scalars['Float']['output'];
  lineName: Scalars['String']['output'];
  longitude: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
};

export type MetroStationsByLineOutput = {
  __typename?: 'MetroStationsByLineOutput';
  lines: Array<MetroLineOutput>;
};

export enum MigrationStatusNaimixEnum {
  /** Нет */
  None = 'NONE'
}

export type Moderation = {
  __typename?: 'Moderation';
  /** Причина комментария */
  cause: Scalars['String']['output'];
  /** Комментарий модерации */
  comment: Scalars['String']['output'];
  /** Размер оплаты по смене */
  cost: Scalars['Float']['output'];
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Часы к оплате */
  duration: Scalars['Int']['output'];
  /** Ставка отредактированная */
  hourRate: Scalars['Float']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Ставка за смену на период стажировки */
  probationRate?: Maybe<Scalars['Float']['output']>;
  /** Сущность смены */
  shift: Shift;
  /** Id смены для которой создана модерация */
  shiftId: Scalars['String']['output'];
  /** Оплачивается смена (да/нет) */
  toPayment: Scalars['Boolean']['output'];
  /** Учитывается бонус (да/нет) */
  toPaymentBonus: Scalars['Boolean']['output'];
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  /** Значение для смен по сдельной оплате */
  value?: Maybe<Scalars['Float']['output']>;
};

export type ModerationReport = {
  __typename?: 'ModerationReport';
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Дата конца периода модерации */
  dateEnd: Scalars['Date']['output'];
  /** Дата начала периода модерации */
  dateStart: Scalars['Date']['output'];
  /** объект на который создан период */
  facility?: Maybe<Facility>;
  /** Id объекта на который создан период */
  facilityId: Scalars['String']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Загружен ли файл? */
  isImportFromFile?: Maybe<Scalars['Boolean']['output']>;
  /** Количество сотрудников в отчете */
  noModerated?: Maybe<Scalars['Int']['output']>;
  /** Тип периода (WEEK, Неделя / TWO_WEEK, Две недели / MONTH, Месяц) */
  periodType: PeriodType;
  /** Количество смен в отчете */
  shiftCount?: Maybe<Scalars['Int']['output']>;
  /** Статус модерации */
  status: ModerationStatus;
  /** Дата установки статуса */
  statusDate?: Maybe<Scalars['Date']['output']>;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  /** Количество сотрудников в отчете */
  workersCount?: Maybe<Scalars['Int']['output']>;
};

export type ModerationReportListInput = {
  /** Дата конца периода модерации */
  dateEnd?: InputMaybe<Scalars['DateTime']['input']>;
  /** Дата начала периода модерации */
  dateStart?: InputMaybe<Scalars['DateTime']['input']>;
  facilityId?: InputMaybe<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  /** Номер страницы */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Тип периода (WEEK, Неделя / TWO_WEEK, Две недели / MONTH, Месяц) */
  periodType?: InputMaybe<PeriodType>;
  /** Строка поиска */
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  /** Количество элементов на странице */
  size?: InputMaybe<Scalars['Int']['input']>;
  /** поле по которому сортировка, по умолчанию updatedAt */
  sortBy?: InputMaybe<ModerationReportListSorting>;
  /** Направление сортировки */
  sortWay?: InputMaybe<SortingWay>;
  /** Статус модерации */
  status?: InputMaybe<ModerationStatus>;
};

/** поле по которому будет работать сортировка */
export enum ModerationReportListSorting {
  DateStart = 'dateStart',
  StatusDate = 'statusDate',
  UpdatedAt = 'updatedAt'
}

/** Данные для непосредственно экрана табеля */
export type ModerationReportTable = {
  __typename?: 'ModerationReportTable';
  /** Массив смен в дне */
  report: ModerationReport;
  /** Массив смен в дне */
  rows: Array<ModerationReportTableRow>;
};

/** Строка Табеля */
export type ModerationReportTableRow = {
  __typename?: 'ModerationReportTableRow';
  /** Сумма бонусных выплат */
  bonusPayment: Scalars['Int']['output'];
  /** Бонус Все возможно если есть на объекте и работник достиг его */
  everythingIsPossibleBonus?: Maybe<Scalars['Float']['output']>;
  /** Фактическое количество часов */
  factHours: Scalars['Float']['output'];
  /** Фактическое число отработанных */
  factValue: Scalars['Float']['output'];
  /** Сумма бонусных выплат */
  finalPayment: Scalars['Int']['output'];
  /** количество смен без модерации */
  noModeratedCount?: Maybe<Scalars['Int']['output']>;
  /** Сумма штрафов */
  penaltySum?: Maybe<Scalars['Int']['output']>;
  /** Плановое число часов */
  planHours: Scalars['Float']['output'];
  /** Плановое число отработанных */
  planValue: Scalars['Float']['output'];
  /** Должность */
  position: Position;
  /** Сумма стажировочных смен */
  probationSum?: Maybe<Scalars['Float']['output']>;
  /** Идентификатор отчёта */
  reportId: Scalars['String']['output'];
  /** Смены */
  shifts: Array<ShiftFact>;
  /** Количество смен */
  shiftsCount: Scalars['Int']['output'];
  /** Количество смен к оплате */
  shiftsToPayment: Scalars['Int']['output'];
  /** Сумма */
  sum: Scalars['Float']['output'];
  /** Статус оплаты */
  toPayment: Scalars['Boolean']['output'];
  /** Единицы измерения для оплаты */
  unit: UnitType;
  /** Пользователь */
  user: User;
};

/** Moderation status */
export enum ModerationStatus {
  InWork = 'IN_WORK',
  Sent = 'SENT'
}

/** DTO для ручной установки периода стажировки работнику */
export type MoveProbationPeriodInput = {
  /** Новая дата стажировки */
  dateFrom: Scalars['DateTime']['input'];
  /** ID объекта */
  facilityId: Scalars['String']['input'];
  /** ID профессии */
  positionId: Scalars['String']['input'];
  /** ID исполнителя */
  userId: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Принять изменения в заявке (супервайзер) */
  acceptChangesBySupervisor: Scalars['Boolean']['output'];
  /** Одобрить откликнувшихся исполнителей на смену */
  acceptRequestShiftsSuggestions: ZayavkaSchedule;
  /** Принять заявку директором */
  acceptZayavkaByDirector: Scalars['Boolean']['output'];
  /** Принять заявку супервайзером */
  acceptZayavkaBySupervisor: Scalars['Boolean']['output'];
  /** создает запрос на добавление контактного номера телефона (должен быть подтвержден через смс) */
  addContactPhone: Scalars['Boolean']['output'];
  /** Назначает Директора на группу объектов */
  addDirectorToFacilityGroup: User;
  /** Назначает Бригадира на объект */
  addForemanToFacility: User;
  /** Назначает Менеджера на объект */
  addManagerToFacility: User;
  /** Назначает Менеджера на группу объектов */
  addManagerToFacilityGroup: Scalars['Boolean']['output'];
  /** Метод для добавления лида пользователем */
  addReferral: Scalars['Boolean']['output'];
  /** Назначает Супервайзера на объект */
  addSupervisorToFacility: User;
  /** Добавление пустой строки с пользователем в модерацию */
  addUserToModerationReport: ModerationReportTableRow;
  /** Метод для архивирования реферальной программы */
  archiveReferralProgram: Scalars['Boolean']['output'];
  /** Принять сотрудником горячую смену */
  assignTemporaryShiftToUser: Shift;
  /** Создает на "График заявки"(ZayavkaSchedule) новый "График работнику"(Schedule), после этого работник считается назначенным на график заявки */
  assignZayavkaSchedule: ZayavkaSchedule;
  /** Назначение работника на смену заявки */
  assignZayavkaShift: ZayavkaShift;
  /** Забронировать смену в графике заявок */
  bookAShiftInTheSchedule: Array<ZayavkaShift>;
  /** Расчет свежих данных по периодам HOUR, DAY, WEEK, MONTH */
  calculateLatestMetrics: Scalars['Boolean']['output'];
  /** Отменить запланированное изменение ставки профессии */
  cancelPlanCasingRate: Scalars['Boolean']['output'];
  /** Отмена горячей смены (и назначенной смены работнику если она есть) */
  cancelTemporaryShift: Scalars['Boolean']['output'];
  /** Отменить заявку (совсем) */
  cancelZayavka: Scalars['Boolean']['output'];
  /** Предложить отмену заявки менеджером */
  cancelZayavkaByManager: Scalars['Boolean']['output'];
  /** Метод для изменения или создания (если нет) кастомного графика пользователя */
  changeCustomSchedule: Schedule;
  /** Устанавливает информацию о штрафах на объекте */
  changeFacilityPenalty: Penalty;
  /** Изменяет is_active объекта - скрывает активный и наоборот */
  changeFacilityStatus: Facility;
  /** Смена основного номера телефона шаг 4 */
  changeMainPhoneStepFour: ContactPhone;
  /** Смена основного номера телефона шаг 1 */
  changeMainPhoneStepOne: Scalars['Boolean']['output'];
  /** Смена основного номера телефона шаг 3 */
  changeMainPhoneStepThree: Scalars['Boolean']['output'];
  /** Смена основного номера телефона шаг 2 */
  changeMainPhoneStepTwo: Scalars['Boolean']['output'];
  /** Устанавливает статус модерации строке пользователя */
  changeModerationSelectedUsers: Array<ModerationReportTableRow>;
  /** Метод для получения паспортных данных */
  changeMyPersonalInfoPassport: MyPersonalInfoPassportOutput;
  /** Изменить пароль */
  changePassword: User;
  /** Метод для изменения информации по медицинской книжке пользователя */
  changePersonalInfoMedInvoice: PersonalInfoMedInvoiceOutput;
  /** Метод для изменения медицинской книжки пользователя */
  changePersonalInfoMedical: PersonalInfoMedicalOutput;
  /** Метод изменения паспортных данных пользователя */
  changePersonalInfoPassport: PersonalInfoPassportOutput;
  /** Метод изменения платёжных данных пользователя */
  changePersonalInfoPayment: PersonalInfoPaymentOutput;
  /** Метод для изменения пользователю регистрации по паспорту */
  changePersonalInfoRegistration: Requisite;
  /** Метод изменения статуса самозанятого пользователя */
  changePersonalInfoTax: PersonalInfoTaxOutput;
  /** @deprecated Использовать saveUserQuestionnaire */
  changeQuestionnaireExtended: Questionnaire;
  /** Назначение пользователя из импорта к пользователю на объекте */
  changeResolveReportUser: ResolveReportUserModel;
  /** Метод для изменения или создания (если нет) графика пользователя */
  changeSchedule: Schedule;
  /** Редактирование / Модерация смены */
  changeShiftModeration: ShiftFact;
  /** Изменяет статус смены */
  changeShiftStage: Shift;
  /** Смена типа слайда "Истории" */
  changeStorySlideType: StorySlide;
  /** Метод для изменения персональных данных */
  changeUserInfoMain: ChangeUserInfoMainModel;
  /** Поменять пароль пользователя */
  changeUserPassword: User;
  /** Сменить роль пользователя */
  changeUserRole: User;
  /** Изменить статус пользователя */
  changeUserStatus: User;
  /** Изменяет is_active объекта - скрывает активный и наоборот */
  changeVacancyStatus: Vacancy;
  /** Актуальное создание Чекина/Чекаута, возвращает модель Mark */
  createAndGetMark: Mark;
  /** Создание операции с балансом группы объекта */
  createBalanceOperation: FacilityGroupBalanceOperation;
  /** Создать оклад */
  createCasing: Casing;
  /** Создать оклад */
  createCasingsInBatch: Array<Casing>;
  /** Метод для создания города */
  createCity: City;
  /** добавляет контактный номер телефона работнику */
  createContactPhone: ContactPhone;
  createDailyReport: Report;
  /** Метод для создания черновика реферальной программы */
  createDraftReferralProgram: ReferralProgramEntity;
  /** Создание объекта */
  createFacility: Facility;
  /** Создание группы объектов */
  createFacilityGroup: FacilityGroup;
  createLeadsReport: Report;
  /** Создает объект выплаты за период */
  createManualAccuralByPeriod: ManualAccrual;
  /** Метод для создания реферальных программ для групп и объектов. Возвращает количество созданных программ */
  createManyReferralPrograms: Scalars['Int']['output'];
  /** Создание новой модерации */
  createModerationReport: ModerationReport;
  /** Метод для создания новой бонусной программы */
  createNewPromotion: PromotionEntity;
  /** создать горячу смену */
  createNewTemporaryShift: TemporaryShift;
  /** Создать уведомление */
  createNotification: Notification;
  /** Создать рассылку */
  createNotifyLetter: NotifyLetter;
  /** Создать или обновить реквизиты пользователя */
  createOrUpdateUserRequisite: Requisite;
  /** Поставить подработку на пользователя */
  createPart: Shift;
  createPollsReport: Report;
  /** Создать позицию */
  createPosition: Position;
  createReportEverythingIsPossibleBonus: Report;
  /** Создание смены сразу с модерацией для табеля */
  createShiftInModeration: ShiftFact;
  /** Создать штраф на смене */
  createShiftPenalty: ShiftPenalty;
  /** Создание "Истории" с названием по умолчанию */
  createStory: Story;
  /** Создает слайд "Истории" с указанным типом */
  createStorySlideByType: StorySlide;
  createTimesheetByMonthAndFacility: Report;
  createTimesheetByMonthAndFacilityGroup: Report;
  /** Создание пользователя */
  createUserByPersonalInfo: User;
  /** метод для создания пользователя через API ERP */
  createUserErp: User;
  /** Создает оценку пользователя */
  createUserGrade: UserGrade;
  /** Создать пользовательские реквизиты */
  createUserRequisite: Requisite;
  /** Создает отзыв о пользователе */
  createUserReview: UserReview;
  /** Создание вакансии */
  createVacancy: Vacancy;
  /** Создание вакансий списком */
  createVacancyInBatch: Array<Vacancy>;
  /** Создание должности */
  createWorkPost: WorkPost;
  /** Создание заявки */
  createZayavka: ZayavkaProgress;
  /** Создание графика в заявке */
  createZayavkaSchedule: Array<ZayavkaSchedule>;
  /** Создает смену в заявке со стороны клиента */
  createZayavkaShiftByClient: ZayavkaShift;
  /** Производит проверку статуса прохождения регистрации Наймикс, подтягивание документов и обновление персональной информации пользователей */
  cron_checkContractorStatusNaimix: Scalars['String']['output'];
  /** Собирает статистику для дашборда за разные интервалы времени */
  cron_dashboardStatistic: Scalars['String']['output'];
  /** Удаляет реферальные программы со статусом DRAFT, по условию COALESCE(updatedAt, createdAt) <= NOW() - INTERVAL '1 DAY' */
  cron_deleteOldDraftReferralPrograms: Scalars['String']['output'];
  /** Удаление непринятых вовремя графиков */
  cron_deletingSchedulesThatWereNotAcceptedOnTime: Scalars['String']['output'];
  /** Определяет смены, которые заканчиваются и отправляет уведомления об окончании */
  cron_detectingEndingShiftsAndSendingNotifications: Scalars['String']['output'];
  /** Определяет смены, которые начинаются и отправляет уведомления о начале смены */
  cron_detectingStartingShiftsAndSendingNotifications: Scalars['String']['output'];
  /** Удаление записей старше 3 лет от текущего момента из таблицы Marks */
  cron_dropOldMarks: Scalars['String']['output'];
  /** Удаление записей старше 1 месяца от текущего момента из таблицы Notification */
  cron_dropOldNotifications: Scalars['String']['output'];
  /** Удаление записей старше 1 года от текущего момента из таблицы UserActivityLog */
  cron_dropOldUserActivityLog: Scalars['String']['output'];
  /** Закрывает стажировку для профессий, где сутки назад закончился стажировочный период */
  cron_finishProbation: Scalars['String']['output'];
  /** Принудительное закрытие смен спустя 2 часа после завершения */
  cron_forcedClosingOfShifts: Scalars['String']['output'];
  /** Рассылка уведомлений пользователям о незавершённой регистрации в Наймикс */
  cron_notifyingUsersAboutUnfinishedRegistrationInNaimix: Scalars['String']['output'];
  /** Уведомления о сроке действия медицинской книжки */
  cron_processingOfExpiredMedicalBooks: Scalars['String']['output'];
  /** Отправляет лида в телегу и ERP, если пользователь зареган больше 10 минут и лид ещё не был отправлен */
  cron_sendLeadAfterRegistration: Scalars['String']['output'];
  /** Отправляет бригадирам уведомления об опоздавших сотрудниках */
  cron_sendsNotificationsToForemenAboutLateWorkers: Scalars['String']['output'];
  /** Запуск бонусных программ, если наступило время запуска */
  cron_startPromotions: Scalars['String']['output'];
  /** Запускает реферальные программы */
  cron_startReferralPrograms: Scalars['String']['output'];
  /** Остановка бонусных программ, если наступило время остановки */
  cron_stopPromotions: Scalars['String']['output'];
  /** Останавливает реферальные программы */
  cron_stopReferralPrograms: Scalars['String']['output'];
  /** Перевод заявок в статус "выполнено/finished" */
  cron_transferTheRequestToTheStatusCompleted: Scalars['String']['output'];
  /** Отклонить отмену заявки директором */
  declineCancelZayavkaByDirector: Scalars['Boolean']['output'];
  /** Отклонение изменений менеджера (Директором) */
  declineChangesByDirector: Scalars['Boolean']['output'];
  /** Отклонить изменения (супервайзер) */
  declineChangesBySupervisor: Scalars['Boolean']['output'];
  /** Отклонить откликнувшихся исполнителей на смену */
  declineRequestShiftsSuggestions: ZayavkaSchedule;
  /** Отклонить заявку Директором */
  declineZayavkaByDirector: Scalars['Boolean']['output'];
  /** Отклонить заявку супервайзером */
  declineZayavkaBySupervisor: Scalars['Boolean']['output'];
  /** Удалить все смены пользователя */
  deleteAllUserShifts: Scalars['Boolean']['output'];
  /** Удаляет операцию и устанавливает значение баланса до операции */
  deleteBalanceOperation: Scalars['Boolean']['output'];
  /** удаление контактного номера телефона пользователю */
  deleteContactPhone: Scalars['Boolean']['output'];
  /** Удалить запись из выплаты */
  deleteManualAccuralItem: Scalars['Boolean']['output'];
  /** Удаление аккаунта */
  deleteMe: Scalars['Boolean']['output'];
  /** Удаляется "Рассылка Уведомлений" */
  deleteNotifyLetter: Scalars['Boolean']['output'];
  /** Метод для удаления бонусной программы */
  deletePromotionInFacilityGroup: Scalars['Boolean']['output'];
  /** Метод для удаления реферальной программы */
  deleteReferralProgram: Scalars['Boolean']['output'];
  /** Удаляется "истории" */
  deleteStoryAndFiles: Scalars['Boolean']['output'];
  /** Удаляется "миниатюра" для "истории" */
  deleteStoryIcon: Story;
  /** Удаляется Слайд "истории" */
  deleteStorySlide: Scalars['Boolean']['output'];
  /** Удаление аккаунта пользователя */
  deleteUser: Scalars['Boolean']['output'];
  /** Снимает работника из назначения на график заявки */
  deleteWorkerFromZayavkaSchedule: ZayavkaSchedule;
  /** Удаляет работника из смены в заявке */
  deleteWorkerFromZayavkaShift: Scalars['Boolean']['output'];
  /** Удаляет график в заявке */
  deleteZayavkaSchedule: Scalars['Boolean']['output'];
  /** Удаляет смену в заявке со стороны клиента */
  deleteZayavkaShiftByClient: Scalars['Boolean']['output'];
  /** Загрузить отчёт модерации */
  downloadModerationReport: Report;
  /** Метод для редактирования бонусной программы в объекте */
  editPromotionInFacility: PromotionEntity;
  /** Метод для редактирования бонусной программы в группе объектов */
  editPromotionInFacilityGroup: PromotionEntity;
  /** Метод для редактирования реферальной программы */
  editReferralProgram: ReferralProgramEntity;
  /** Метод для изменения графика */
  editSchedule: Schedule;
  /** Редактирование смены. Доступ имеет ADMIN или SUPERVISOR назначенный на соотв. смене объект. */
  editShift: Shift;
  emptyQueueByMessageType: Scalars['Boolean']['output'];
  /** Загрузка и добавление лого в группу объектов */
  facilityGroupUploadImage: FacilityGroup;
  /** Метод создания фейк-кода для авторизации по номеру телефона и смс */
  fakeCode: Scalars['String']['output'];
  /** Метод для добавления смен после импорта файла */
  finishResolveReportUser: Scalars['Boolean']['output'];
  /** возвращает способ авторизации пользователя по номеру телефона, в ответе приходит 'sms-message' или 'password' или ошибка отправки sms-message */
  getAuthOption: AuthOption;
  /** Скрывает график */
  hideSchedule: Scalars['Boolean']['output'];
  /** Скрывает для отображения смену */
  hideShift: Scalars['Boolean']['output'];
  /** Инициализация изменения платёжных данных пользователя в Наймикс */
  initEditUserPaymentInfoInNaimix: Scalars['Boolean']['output'];
  /** Метод авторизации по номеру телефона и паролю */
  login: Auth;
  /** Метод логина, через номер телефона и код из смс */
  loginByCode: Auth;
  /** Обнуляет токены устройств пользователя */
  logout: Scalars['Boolean']['output'];
  /** Пометить сообщение как новое */
  markAsNewNotification: Notification;
  /** Мутация для ручной установки периода стажировки работнику */
  moveProbationPeriod: WorkPost;
  /** меняет местами значение index в двх сущностях StorySlide */
  moveStorySlide: Story;
  /** Отправить заявку директором */
  moveZayavkaToDirector: Scalars['Boolean']['output'];
  parseAndSaveFacilityAddresses: Scalars['Boolean']['output'];
  parseAndSaveFacilityMetroStationsIds: Scalars['Boolean']['output'];
  parseAndSaveMetroStationsAddresses: Scalars['Boolean']['output'];
  /** Запланировать изменение ставки профессии */
  planCasingRate: CasingRateEntity;
  /** Метод для прочтения всех комментариев к смене на модерацию. Возвращает число затронутых комментариев */
  readAllCommentsInShift: Scalars['Int']['output'];
  /** Запрос, сделает все непрочитанные нотификации авторизованного пользователя прочитанными */
  readAllNotification: Scalars['Boolean']['output'];
  /** Прочитать уведомление */
  readNotification: Notification;
  /** Метод обновления связки refresh-access токена по refresh токену */
  refreshToken: Token;
  /** Метод для удаления графика */
  refuseSchedule: Schedule;
  /** Меняет статус смены на REFUSED. Доступ имеет ADMIN или SUPERVISOR назначенный на соотв. смене объект. */
  refuseShift: Shift;
  /** Удалить оклад */
  removeCasing: Scalars['Boolean']['output'];
  /** Метод для удаления города по идентификатору */
  removeCity: Scalars['Boolean']['output'];
  /** Удаляет все отложенные задачи в очереди push-уведомлений */
  removeDelayedJobs: Scalars['Boolean']['output'];
  /** Убирает Директора из Группы объектов */
  removeDirectorFromFacilityGroup: Scalars['Boolean']['output'];
  /** Отключение штрафа на объекте */
  removeFacilityPenalty: Scalars['Boolean']['output'];
  /** Убирает Бригадира с Объекта */
  removeForemanFromFacility: Scalars['Boolean']['output'];
  removeJobsFromPushingQueue: Scalars['Boolean']['output'];
  /** Убирает Менеджера с Объекта */
  removeManagerFromFacility: Scalars['Boolean']['output'];
  /** Убирает Менеджера из Группы объектов */
  removeManagerFromFacilityGroup: Scalars['Boolean']['output'];
  /** Удалить фотографию из альбома профессии объекта */
  removePhotoInCasingGallery: Scalars['Boolean']['output'];
  /** Удалить фотографию из альбома профессии объекта */
  removePhotoInFacilityGallery: Scalars['Boolean']['output'];
  /** Удалить позицию */
  removePosition: Scalars['Boolean']['output'];
  /** Снимает статус "Свободная" со смены в заявке */
  removeStatusFreeRequestShift: Scalars['Boolean']['output'];
  /** Убирает Супервайзера с Объекта */
  removeSupervisorFromFacility: Scalars['Boolean']['output'];
  /** Метод для удаления пользовательского документа */
  removeUserDocument: Scalars['Boolean']['output'];
  /** Удаление должности */
  removeWorkPost: Scalars['Boolean']['output'];
  /** Мутация на пересчет всех данных по статистике */
  resetMetrics: Scalars['Boolean']['output'];
  /** Метод возврата бонусной программы к настройкам по умолчанию */
  resetToDefaultPromotionSettings: PromotionEntity;
  /** Отклик на вакансию */
  respond: User;
  /** Восстановить пользователя на должность */
  restoreWorkerPosition: Array<WorkerFacilityWorkPostsOutput>;
  /** Обновить информацию о пользователе */
  saveUserQuestionnaire: User;
  /** Метод для создания комментария к смене на модерацию */
  sendACommentToTheShift: CommentEntity;
  /** Отправить директору изменения менеджера */
  sendChangesToDirector: Scalars['Boolean']['output'];
  /** Отправить супервайзеру изменения */
  sendChangesToSupervisor: Scalars['Boolean']['output'];
  /** Отправить сообщение с ссылкой на приложение */
  sendDownloadSms: Scalars['String']['output'];
  /** Метод для отправки свободных смен по id графика */
  sendFreeShiftByRequestShiftId: Array<ZayavkaProgress>;
  /** Метод для отправки свободных смен по id заявок */
  sendFreeShiftsByRequestIds: Array<ZayavkaProgress>;
  /** Метод для отправки свободных смен по id графика */
  sendFreeShiftsByRequestScheduleId: Array<ZayavkaProgress>;
  /** Фиксирует выплаты для отображения у пользователей в мобильном приложении */
  sendManualAccurals: Scalars['Boolean']['output'];
  /** Отправить пользовательские данные в Наймикс */
  sendUserDataToNaimix: Scalars['Boolean']['output'];
  /** устанавливает контактный номер телефона как основной */
  setContactPhoneToMain: User;
  /** Устанавливает параметры "Все Возможно Бонус" на объекте */
  setEverythingIsPossibleBonus: Facility;
  /** Метод для изменения статуса модерации */
  setModerationReportStatus: Scalars['Boolean']['output'];
  /** Метод проставляет дату отметки об оплате по реферальной программе */
  setReferralProgramPaymentDatetime: Lead;
  /** Метод для установки статуса проверки пользовательского документа */
  setReviewStatus: DocumentReview;
  /** Метод для изменения статуса графика */
  setScheduleStatus: SetScheduleOutput;
  /** Изменить/создать переменные для фронта */
  setSetting: Setting;
  /** Записывает пользователю по токену город */
  setSettlementForUser: Scalars['Boolean']['output'];
  /** Обновление информации о параметрах публикации "Истории" */
  setStoryPublicCondition: Story;
  /** Записывает работнику город */
  setToUserWorkCity: Scalars['Boolean']['output'];
  /** Записывает токен пользователя в браузере для получения браузерных уведомлений */
  setUserBrowserToken: User;
  /** Метод для изменения токена пользователя */
  setUserToken: Scalars['Boolean']['output'];
  /** Устанавливает параметры срока ожидания мед.книжки у работника после осмотра для работы на объекте */
  setWaitingMedicalOnFacility: Facility;
  /** Отклик на Акцию и отправка в CRM данных по отклику */
  shareResponse: Scalars['Boolean']['output'];
  /** Изменение пользовательских данных в Наймикс */
  signEditUserPaymentInfoInNaimix: Scalars['Boolean']['output'];
  smsReport: Report;
  /** Загрузка и добавление изображения в "историю", создает сущность слайд */
  storySlideReplaceImage: StorySlide;
  /** Загрузка и добавление изображения в "историю", создает сущность слайд */
  storySlideUploadImage: StorySlide;
  /** Загрузка и добавление "миниатюры" в "историю" */
  storyUploadIcon: Story;
  /** Подписка на вакансию */
  subscribe: User;
  /** Отправить рассылку на публикацию */
  toPublishNotifyLetter: NotifyLetter;
  /**
   * Устанавливает статус "ожидает публикации" если условия публикации ожидают наступления
   * либо статус "опубликовано" если условия публикации выполнены
   * возвращает сущность "сторис" с установленным статусом
   */
  toPublishStory: Story;
  /** переводит рассылку уведомлений в статус "REMOVED" (Снято с публикации) */
  toRemoveNotifyLetter: NotifyLetter;
  /** Устанавливает статус "Снято с публикации" */
  toRemovePublishStory: Story;
  /** Снять пользователя с должности в объекте */
  unassignWorkerFromFacility: Array<WorkerFacilityWorkPostsOutput>;
  /** Снять пользователя с должности на группе объектов */
  unassignWorkerFromFacilityGroup: Array<WorkerFacilityWorkPostsOutput>;
  /** Снять пользователя с должности */
  unassignWorkerFromPosition: Array<WorkerFacilityWorkPostsOutput>;
  /** Редактирование операции с балансом группы объекта */
  updateBalanceOperation: FacilityGroupBalanceOperation;
  /** Обновить оклад */
  updateCasing: Casing;
  /** Метод для изменения города */
  updateCity: City;
  /** Пакетное обновление статуса активности у объектов */
  updateFacilitiesActivity: Scalars['Boolean']['output'];
  /** Пакетное обновление юр. лиц у объектов */
  updateFacilitiesLegalEntities: Scalars['Boolean']['output'];
  /** Метод для обновления объекта */
  updateFacility: Facility;
  /** Обновление информации о группе объектов */
  updateFacilityGroup: FacilityGroup;
  /** Метод для обновления лида */
  updateLead: Lead;
  /** Обновить рассылку */
  updateNotifyLetter: NotifyLetter;
  /** Обновить позицию */
  updatePosition: Position;
  /** Модерация оплаты бонуса */
  updateShiftModeration: Scalars['Boolean']['output'];
  /** Обновить статус штрафа */
  updateShiftPenaltyStatus: ShiftPenalty;
  /** Обновление информации о "Истории" */
  updateStory: Story;
  /** Обновление информации о слайде "Истории" */
  updateStorySlide: StorySlide;
  /** @deprecated Неудобный метод. Распилили */
  updateUser: User;
  /** Обновление email пользователя */
  updateUserEmail: User;
  /** Обновление статуса работы с Наймикс пользователя */
  updateUserNaimixWorkStatus: User;
  /** Обновление имени, фамилии, отчества и гражданства пользователя */
  updateUserNameAndCitizenship: User;
  /** Обновить пользовательские реквизиты */
  updateUserRequisite: Requisite;
  /** Обновить текущую версию приложения пользователя */
  updateUserVersion: User;
  /** Обновление вакансии */
  updateVacancy: Vacancy;
  /** Обновление заявки */
  updateZayavka: ZayavkaProgress;
  /** Создание в заявке по должности */
  updateZayavkaSchedule: ZayavkaSchedule;
  /** Обновляет смену в заявке со стороны клиента */
  updateZayavkaShiftByClient: ZayavkaShift;
  /** Загрузка кропнутого изображения */
  uploadCropImage: File;
  /** Загрузить фотографии в альбом профессии объекта */
  uploadPhotoInCasingGallery: CasingGalleryEntity;
  /** Загрузить фотографии в альбом объекта */
  uploadPhotoInFacilityGallery: FacilityGalleryEntity;
  /** Загрузка Excel файлов со сменами */
  uploadShiftsExcel: Scalars['Boolean']['output'];
  /** Загрузка и добавление пользовательских документов */
  uploadUserDocument: Document;
  /** Загрузить сканы документов пользователя для отправки в Наймикс */
  uploadUserFileScanForNaimix: File;
  /** проверяет код отравленный для добавления номера телефона */
  verifyContactPhone: ContactPhone;
  /** Пометить историю как просмотренную */
  viewStory: Scalars['Boolean']['output'];
  /** Создает запись в БД о действии пользователя */
  writeMobileLog: Scalars['Boolean']['output'];
};


export type MutationAcceptChangesBySupervisorArgs = {
  id: Scalars['String']['input'];
};


export type MutationAcceptRequestShiftsSuggestionsArgs = {
  input: AcceptRequestShiftsSuggestionsInput;
};


export type MutationAcceptZayavkaByDirectorArgs = {
  id: Scalars['String']['input'];
};


export type MutationAcceptZayavkaBySupervisorArgs = {
  id: Scalars['String']['input'];
};


export type MutationAddContactPhoneArgs = {
  phone: Scalars['String']['input'];
};


export type MutationAddDirectorToFacilityGroupArgs = {
  groupId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationAddForemanToFacilityArgs = {
  facilityId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationAddManagerToFacilityArgs = {
  facilityId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationAddManagerToFacilityGroupArgs = {
  groupId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationAddReferralArgs = {
  input: AddReferralInput;
};


export type MutationAddSupervisorToFacilityArgs = {
  facilityId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationAddUserToModerationReportArgs = {
  input: AddUserModerationReportInput;
};


export type MutationArchiveReferralProgramArgs = {
  input: ArchiveReferralProgramInput;
};


export type MutationAssignTemporaryShiftToUserArgs = {
  input: AssignTemporaryShiftInput;
};


export type MutationAssignZayavkaScheduleArgs = {
  workerId: Scalars['String']['input'];
  zayavkaScheduleId: Scalars['String']['input'];
};


export type MutationAssignZayavkaShiftArgs = {
  workerId: Scalars['String']['input'];
  zayavkaShiftId: Scalars['String']['input'];
};


export type MutationBookAShiftInTheScheduleArgs = {
  input: BookAShiftInTheScheduleInput;
};


export type MutationCancelPlanCasingRateArgs = {
  input: CancelPlanCasingRateInput;
};


export type MutationCancelTemporaryShiftArgs = {
  id: Scalars['String']['input'];
};


export type MutationCancelZayavkaArgs = {
  id: Scalars['String']['input'];
};


export type MutationCancelZayavkaByManagerArgs = {
  id: Scalars['String']['input'];
};


export type MutationChangeCustomScheduleArgs = {
  inputs: Array<SingleShiftInput>;
};


export type MutationChangeFacilityPenaltyArgs = {
  applyTimeInMinutes: Scalars['Int']['input'];
  cost: Scalars['Int']['input'];
  facilityId: Scalars['String']['input'];
};


export type MutationChangeFacilityStatusArgs = {
  id: Scalars['String']['input'];
};


export type MutationChangeMainPhoneStepFourArgs = {
  code: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};


export type MutationChangeMainPhoneStepThreeArgs = {
  phone: Scalars['String']['input'];
};


export type MutationChangeMainPhoneStepTwoArgs = {
  code: Scalars['String']['input'];
};


export type MutationChangeModerationSelectedUsersArgs = {
  input: ChangeModerationUserRowInput;
};


export type MutationChangeMyPersonalInfoPassportArgs = {
  input: MyPersonalInfoPassportInput;
};


export type MutationChangePasswordArgs = {
  data: ChangePasswordInput;
};


export type MutationChangePersonalInfoMedInvoiceArgs = {
  input: PersonalInfoMedInvoiceInput;
};


export type MutationChangePersonalInfoMedicalArgs = {
  input: PersonalInfoMedicalInput;
};


export type MutationChangePersonalInfoPassportArgs = {
  input: PersonalInfoPassportInput;
};


export type MutationChangePersonalInfoPaymentArgs = {
  input: PersonalInfoPaymentInput;
};


export type MutationChangePersonalInfoRegistrationArgs = {
  registration: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationChangePersonalInfoTaxArgs = {
  input: PersonalInfoTaxInput;
};


export type MutationChangeQuestionnaireExtendedArgs = {
  questionnaire: QuestionnaireInput;
  workerId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationChangeResolveReportUserArgs = {
  input: ChangeResolveReportUserInput;
};


export type MutationChangeScheduleArgs = {
  input: ChangeScheduleInput;
};


export type MutationChangeShiftModerationArgs = {
  moderation: ChangeModerationInput;
};


export type MutationChangeShiftStageArgs = {
  input: ChangeShiftStageInput;
};


export type MutationChangeStorySlideTypeArgs = {
  id: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type MutationChangeUserInfoMainArgs = {
  input: ChangeUserInfoMainInput;
};


export type MutationChangeUserPasswordArgs = {
  password: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationChangeUserRoleArgs = {
  role: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationChangeUserStatusArgs = {
  input: UserStatusInput;
};


export type MutationChangeVacancyStatusArgs = {
  id: Scalars['String']['input'];
};


export type MutationCreateAndGetMarkArgs = {
  input: CreateMarkInput;
};


export type MutationCreateBalanceOperationArgs = {
  input: BalanceOperationCardInput;
};


export type MutationCreateCasingArgs = {
  input: CasingCardInput;
};


export type MutationCreateCasingsInBatchArgs = {
  input: BatchCasingCardInput;
};


export type MutationCreateCityArgs = {
  input: CityInput;
};


export type MutationCreateContactPhoneArgs = {
  phone: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationCreateDailyReportArgs = {
  input: CreateDailyReportInput;
};


export type MutationCreateDraftReferralProgramArgs = {
  input: CreateDraftReferralProgramInput;
};


export type MutationCreateFacilityArgs = {
  input: FacilityCardInput;
};


export type MutationCreateFacilityGroupArgs = {
  name: Scalars['String']['input'];
};


export type MutationCreateLeadsReportArgs = {
  input: LeadListInput;
};


export type MutationCreateManualAccuralByPeriodArgs = {
  dateFrom: Scalars['String']['input'];
  dateTo: Scalars['String']['input'];
  facilityId: Scalars['String']['input'];
};


export type MutationCreateManyReferralProgramsArgs = {
  input: CreateManyReferralProgramsInput;
};


export type MutationCreateModerationReportArgs = {
  input: CreateModerationReportInput;
};


export type MutationCreateNewPromotionArgs = {
  input: CreateNewPromotionInput;
};


export type MutationCreateNewTemporaryShiftArgs = {
  input: CreateTemporaryShiftInput;
};


export type MutationCreateNotificationArgs = {
  input: CreateNotificationInput;
};


export type MutationCreateNotifyLetterArgs = {
  input?: InputMaybe<CreateNotifyLetterInput>;
};


export type MutationCreateOrUpdateUserRequisiteArgs = {
  input: RequisiteInput;
};


export type MutationCreatePartArgs = {
  input: CreatePartInput;
};


export type MutationCreatePollsReportArgs = {
  input: CreatePollsReportInput;
};


export type MutationCreatePositionArgs = {
  input: PositionCardInput;
};


export type MutationCreateReportEverythingIsPossibleBonusArgs = {
  startOfMonth: Scalars['String']['input'];
};


export type MutationCreateShiftInModerationArgs = {
  moderation: CreateModerationInput;
  shift: SingleShiftInput;
};


export type MutationCreateShiftPenaltyArgs = {
  input: CreateShiftPenaltyInput;
};


export type MutationCreateStoryArgs = {
  input?: InputMaybe<CreateDraftStoryInput>;
};


export type MutationCreateStorySlideByTypeArgs = {
  id: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type MutationCreateTimesheetByMonthAndFacilityArgs = {
  input: CreateTimesheetByMonthAndFacilityInput;
};


export type MutationCreateTimesheetByMonthAndFacilityGroupArgs = {
  input: CreateTimesheetByMonthAndFacilityGroupInput;
};


export type MutationCreateUserByPersonalInfoArgs = {
  input: CreateUserByPersonalInfoMainInput;
};


export type MutationCreateUserErpArgs = {
  input: CreateUserErpInput;
};


export type MutationCreateUserGradeArgs = {
  input: CreateUserGradeInput;
};


export type MutationCreateUserRequisiteArgs = {
  input: RequisiteInput;
};


export type MutationCreateUserReviewArgs = {
  input: CreateUserReviewInput;
};


export type MutationCreateVacancyArgs = {
  input: VacancyInput;
};


export type MutationCreateVacancyInBatchArgs = {
  input: BatchVacancyInput;
};


export type MutationCreateWorkPostArgs = {
  facilityId: Scalars['String']['input'];
  positionId: Scalars['String']['input'];
  recountProbation: Scalars['Boolean']['input'];
  userId: Scalars['String']['input'];
};


export type MutationCreateZayavkaArgs = {
  input: ZayavkaCardInput;
};


export type MutationCreateZayavkaScheduleArgs = {
  input: ZayavkaScheduleInput;
};


export type MutationCreateZayavkaShiftByClientArgs = {
  input: ZayavkaShiftByClientInput;
  zayavkaScheduleId: Scalars['String']['input'];
};


export type MutationCron_DashboardStatisticArgs = {
  input: CronDashboardStatisticInput;
};


export type MutationDeclineCancelZayavkaByDirectorArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeclineChangesByDirectorArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeclineChangesBySupervisorArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeclineRequestShiftsSuggestionsArgs = {
  input: DeclineRequestShiftSuggestionsInput;
};


export type MutationDeclineZayavkaByDirectorArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeclineZayavkaBySupervisorArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteAllUserShiftsArgs = {
  userId: Scalars['String']['input'];
};


export type MutationDeleteBalanceOperationArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteContactPhoneArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteManualAccuralItemArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteMeArgs = {
  input: DeleteMeInput;
};


export type MutationDeleteNotifyLetterArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeletePromotionInFacilityGroupArgs = {
  input: DeletePromotionInFacilityGroupInput;
};


export type MutationDeleteReferralProgramArgs = {
  input: DeleteReferralProgramInput;
};


export type MutationDeleteStoryAndFilesArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteStoryIconArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteStorySlideArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationDeleteWorkerFromZayavkaScheduleArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteWorkerFromZayavkaShiftArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteZayavkaScheduleArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteZayavkaShiftByClientArgs = {
  id: Scalars['String']['input'];
};


export type MutationDownloadModerationReportArgs = {
  id: Scalars['String']['input'];
};


export type MutationEditPromotionInFacilityArgs = {
  input: EditPromotionInFacilityInput;
};


export type MutationEditPromotionInFacilityGroupArgs = {
  input: EditPromotionInFacilityGroupInput;
};


export type MutationEditReferralProgramArgs = {
  input: EditReferralProgramInput;
};


export type MutationEditScheduleArgs = {
  input: ChangeScheduleInput;
};


export type MutationEditShiftArgs = {
  input: EditShiftInput;
};


export type MutationEmptyQueueByMessageTypeArgs = {
  input: EmptyQueueInput;
};


export type MutationFacilityGroupUploadImageArgs = {
  id: Scalars['String']['input'];
  image: Scalars['Upload']['input'];
};


export type MutationFakeCodeArgs = {
  phone: Scalars['String']['input'];
};


export type MutationFinishResolveReportUserArgs = {
  reportId: Scalars['String']['input'];
};


export type MutationGetAuthOptionArgs = {
  device?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
};


export type MutationHideScheduleArgs = {
  id: Scalars['String']['input'];
};


export type MutationHideShiftArgs = {
  id: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  data: LoginInput;
};


export type MutationLoginByCodeArgs = {
  input: LoginTestInput;
};


export type MutationMarkAsNewNotificationArgs = {
  id: Scalars['String']['input'];
};


export type MutationMoveProbationPeriodArgs = {
  input: MoveProbationPeriodInput;
};


export type MutationMoveStorySlideArgs = {
  slideIdOne: Scalars['String']['input'];
  slideIdTwo: Scalars['String']['input'];
};


export type MutationMoveZayavkaToDirectorArgs = {
  id: Scalars['String']['input'];
};


export type MutationPlanCasingRateArgs = {
  input: PlanCasingRateInput;
};


export type MutationReadAllCommentsInShiftArgs = {
  input: ReadAllCommentsInShiftInput;
};


export type MutationReadNotificationArgs = {
  id: Scalars['String']['input'];
};


export type MutationRefreshTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationRefuseScheduleArgs = {
  id: Scalars['String']['input'];
};


export type MutationRefuseShiftArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveCasingArgs = {
  facilityId: Scalars['String']['input'];
  positionId: Scalars['String']['input'];
};


export type MutationRemoveCityArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveDirectorFromFacilityGroupArgs = {
  groupId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationRemoveFacilityPenaltyArgs = {
  facilityId: Scalars['String']['input'];
};


export type MutationRemoveForemanFromFacilityArgs = {
  facilityId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationRemoveJobsFromPushingQueueArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveManagerFromFacilityArgs = {
  facilityId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationRemoveManagerFromFacilityGroupArgs = {
  groupId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationRemovePhotoInCasingGalleryArgs = {
  photoIds: Array<Scalars['String']['input']>;
};


export type MutationRemovePhotoInFacilityGalleryArgs = {
  photoIds: Array<Scalars['String']['input']>;
};


export type MutationRemovePositionArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveStatusFreeRequestShiftArgs = {
  input: RemoveStatusFreeRequestShiftInput;
};


export type MutationRemoveSupervisorFromFacilityArgs = {
  facilityId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationRemoveUserDocumentArgs = {
  type: Scalars['String']['input'];
  workerId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRemoveWorkPostArgs = {
  facilityId: Scalars['String']['input'];
  positionId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationResetToDefaultPromotionSettingsArgs = {
  input: ResetToDefaultPromotionSettingsInput;
};


export type MutationRespondArgs = {
  data: Scalars['String']['input'];
};


export type MutationRestoreWorkerPositionArgs = {
  input: RestoreWorkerPositionInput;
};


export type MutationSaveUserQuestionnaireArgs = {
  input: QuestionnaireInput;
};


export type MutationSendACommentToTheShiftArgs = {
  input: SendACommentToTheShiftInput;
};


export type MutationSendChangesToDirectorArgs = {
  id: Scalars['String']['input'];
};


export type MutationSendChangesToSupervisorArgs = {
  id: Scalars['String']['input'];
};


export type MutationSendDownloadSmsArgs = {
  phone: Scalars['String']['input'];
};


export type MutationSendFreeShiftByRequestShiftIdArgs = {
  input: SendFreeShiftByRequestShiftIdInput;
};


export type MutationSendFreeShiftsByRequestIdsArgs = {
  input: SendFreeShiftsByRequestIdsInput;
};


export type MutationSendFreeShiftsByRequestScheduleIdArgs = {
  input: SendFreeShiftsByRequestScheduleIdInput;
};


export type MutationSendManualAccuralsArgs = {
  input: ManualAccrualSenderInput;
};


export type MutationSendUserDataToNaimixArgs = {
  input: SendUserDataToNaimixInput;
};


export type MutationSetContactPhoneToMainArgs = {
  phoneId: Scalars['String']['input'];
};


export type MutationSetEverythingIsPossibleBonusArgs = {
  input: EverythingIsPossibleBonusInput;
};


export type MutationSetModerationReportStatusArgs = {
  input: SetStatusModerationInput;
};


export type MutationSetReferralProgramPaymentDatetimeArgs = {
  input: SetReferralProgramPaymentDatetimeInput;
};


export type MutationSetReviewStatusArgs = {
  input: DocumentReviewInput;
};


export type MutationSetScheduleStatusArgs = {
  scheduleInput: UserScheduleStatusInput;
};


export type MutationSetSettingArgs = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};


export type MutationSetSettlementForUserArgs = {
  input: SetCityForUserInput;
};


export type MutationSetStoryPublicConditionArgs = {
  input: StoryPublicConditionInput;
};


export type MutationSetToUserWorkCityArgs = {
  cityId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationSetUserBrowserTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationSetUserTokenArgs = {
  input: SetUserTokenInput;
};


export type MutationSetWaitingMedicalOnFacilityArgs = {
  facilityId: Scalars['String']['input'];
  isMedInvoiceAllowed: Scalars['Boolean']['input'];
  numberDaysWaitMedical: Scalars['Int']['input'];
};


export type MutationShareResponseArgs = {
  bannerId?: InputMaybe<Scalars['String']['input']>;
  shareData: Scalars['String']['input'];
};


export type MutationSignEditUserPaymentInfoInNaimixArgs = {
  input: SignEditUserPaymentInfoInNaimixInput;
};


export type MutationStorySlideReplaceImageArgs = {
  image: Scalars['Upload']['input'];
  slideId: Scalars['String']['input'];
};


export type MutationStorySlideUploadImageArgs = {
  id: Scalars['String']['input'];
  image: Scalars['Upload']['input'];
};


export type MutationStoryUploadIconArgs = {
  id: Scalars['String']['input'];
  image: Scalars['Upload']['input'];
};


export type MutationSubscribeArgs = {
  data: Scalars['String']['input'];
};


export type MutationToPublishNotifyLetterArgs = {
  id: Scalars['String']['input'];
};


export type MutationToPublishStoryArgs = {
  id: Scalars['String']['input'];
};


export type MutationToRemoveNotifyLetterArgs = {
  id: Scalars['String']['input'];
};


export type MutationToRemovePublishStoryArgs = {
  id: Scalars['String']['input'];
};


export type MutationUnassignWorkerFromFacilityArgs = {
  input: UnassignWorkerFromFacilityInput;
};


export type MutationUnassignWorkerFromFacilityGroupArgs = {
  input: UnassignWorkerFromFacilityGroupInput;
};


export type MutationUnassignWorkerFromPositionArgs = {
  input: UnassignWorkerFromPositionInput;
};


export type MutationUpdateBalanceOperationArgs = {
  id: Scalars['String']['input'];
  input: BalanceOperationCardInput;
};


export type MutationUpdateCasingArgs = {
  input: CasingCardInput;
};


export type MutationUpdateCityArgs = {
  input: CityInput;
};


export type MutationUpdateFacilitiesActivityArgs = {
  input: UpdateFacilitiesActivityInput;
};


export type MutationUpdateFacilitiesLegalEntitiesArgs = {
  input: UpdateFacilitiesLegalEntitiesInput;
};


export type MutationUpdateFacilityArgs = {
  input: FacilityCardInput;
};


export type MutationUpdateFacilityGroupArgs = {
  input: FacilityGroupCardInput;
};


export type MutationUpdateLeadArgs = {
  input: UpdateLeadInput;
};


export type MutationUpdateNotifyLetterArgs = {
  input: UpdateNotifyLetterInput;
};


export type MutationUpdatePositionArgs = {
  input: PositionCardInput;
};


export type MutationUpdateShiftModerationArgs = {
  input: UpdateShiftModerationInput;
};


export type MutationUpdateShiftPenaltyStatusArgs = {
  input: UpdateShiftPenaltyInfoInput;
};


export type MutationUpdateStoryArgs = {
  input: UpdateStoryInput;
};


export type MutationUpdateStorySlideArgs = {
  input: UpdateStorySlideInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserEmailArgs = {
  input: UpdateUserEmailInput;
};


export type MutationUpdateUserNaimixWorkStatusArgs = {
  input: UpdateUserNaimixWorkStatusInput;
};


export type MutationUpdateUserNameAndCitizenshipArgs = {
  input: UpdateUserNameAndCitizenshipInput;
};


export type MutationUpdateUserRequisiteArgs = {
  input: RequisiteInput;
};


export type MutationUpdateUserVersionArgs = {
  input: UpdateUserVersionInput;
};


export type MutationUpdateVacancyArgs = {
  id: Scalars['String']['input'];
  input: VacancyInput;
};


export type MutationUpdateZayavkaArgs = {
  id: Scalars['String']['input'];
  input: ZayavkaCardInput;
};


export type MutationUpdateZayavkaScheduleArgs = {
  id: Scalars['String']['input'];
  input: ZayavkaScheduleInput;
};


export type MutationUpdateZayavkaShiftByClientArgs = {
  id: Scalars['String']['input'];
  input: ZayavkaShiftByClientInput;
};


export type MutationUploadCropImageArgs = {
  cropData: CropDataInput;
  fileId: Scalars['String']['input'];
};


export type MutationUploadPhotoInCasingGalleryArgs = {
  casingId: Scalars['String']['input'];
  image: Scalars['Upload']['input'];
};


export type MutationUploadPhotoInFacilityGalleryArgs = {
  facilityId: Scalars['String']['input'];
  image: Scalars['Upload']['input'];
};


export type MutationUploadShiftsExcelArgs = {
  file: Scalars['Upload']['input'];
  reportId: Scalars['String']['input'];
  sourceType: ExcelVariant;
};


export type MutationUploadUserDocumentArgs = {
  document: Scalars['Upload']['input'];
  type: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationUploadUserFileScanForNaimixArgs = {
  file: Scalars['Upload']['input'];
  scanType: ScanTypeNaimixEnum;
};


export type MutationVerifyContactPhoneArgs = {
  code: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};


export type MutationViewStoryArgs = {
  id: Scalars['String']['input'];
};


export type MutationWriteMobileLogArgs = {
  input: UserActivityLogInput;
};

export type MyFriendsOutput = {
  __typename?: 'MyFriendsOutput';
  /** Список приглашенных рефералов */
  leads: Array<LeadOutput>;
  /** Прогресс бонуса "Очко" */
  ochkoProgress: OchkoProgressOutput;
};

export type MyPersonalInfoPassportInput = {
  /** Дата рождения */
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Место рождения */
  birthPlace?: InputMaybe<Scalars['String']['input']>;
  /** Кем выдан паспорт */
  passportIssuedBy?: InputMaybe<Scalars['String']['input']>;
  /** Дата выдачи паспорта */
  passportIssuedDate?: InputMaybe<Scalars['String']['input']>;
  /** Код подразделения, выдавшего паспорт */
  passportIssuerCode?: InputMaybe<Scalars['String']['input']>;
  /** Номер паспорта */
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  /** Адрес регистрации */
  passportRegistrationAddress?: InputMaybe<Scalars['String']['input']>;
  /** Серия паспорта */
  passportSeries?: InputMaybe<Scalars['String']['input']>;
  /** Пол */
  sex?: InputMaybe<Sex>;
};

export type MyPersonalInfoPassportOutput = {
  __typename?: 'MyPersonalInfoPassportOutput';
  /** Дата рождения */
  birthDate?: Maybe<Scalars['DateTime']['output']>;
  /** Место рождения */
  birthPlace?: Maybe<Scalars['String']['output']>;
  /** Кем выдан паспорт */
  passportIssuedBy: Scalars['String']['output'];
  /** Дата выдачи паспорта */
  passportIssuedDate?: Maybe<Scalars['String']['output']>;
  /** Код подразделения, выдавшего паспорт */
  passportIssuerCode?: Maybe<Scalars['String']['output']>;
  /** Номер паспорта */
  passportNumber: Scalars['String']['output'];
  /** Адрес регистрации */
  passportRegistrationAddress?: Maybe<Scalars['String']['output']>;
  /** Серия паспорта */
  passportSeries: Scalars['String']['output'];
  /** Пол */
  sex?: Maybe<Sex>;
  /** Дата обновления блока паспортные данные */
  updatedAtPassport?: Maybe<Scalars['Date']['output']>;
};

export type NaimixObjectOutput = {
  __typename?: 'NaimixObjectOutput';
  /** Идентификатор объекта в Наймикс */
  naimixObjectId?: Maybe<Scalars['String']['output']>;
  /** Название объекта в Наймикс */
  naimixObjectName?: Maybe<Scalars['String']['output']>;
};

export type Notification = {
  __typename?: 'Notification';
  appType: AppType;
  body: Scalars['String']['output'];
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  isRead: Scalars['Boolean']['output'];
  route: Scalars['String']['output'];
  routeId: Scalars['String']['output'];
  success: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type NotificationsCountersOutput = {
  __typename?: 'NotificationsCountersOutput';
  /** Количество прочитанных сообщений */
  isReadCount: Scalars['Int']['output'];
  /** Количество новых сообщений */
  newCount: Scalars['Int']['output'];
};

export type NotifyLetter = {
  __typename?: 'NotifyLetter';
  /** Текст уведомления */
  body?: Maybe<Scalars['String']['output']>;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Название уведомления */
  name: Scalars['String']['output'];
  /** Условия публикации */
  publicCondition?: Maybe<PublicCondition>;
  /** Id условий публикации */
  publicConditionId: Scalars['String']['output'];
  /** Реферальная программа, к которой привязано пуш уведомление */
  referralProgram?: Maybe<ReferralProgramEntity>;
  /** Идентификатор реферальной программы, к которой привязано пуш уведомление */
  referralProgramId?: Maybe<Scalars['String']['output']>;
  /** Ссылка для перенаправления */
  route?: Maybe<PushRoute>;
  /** Статус пуш уведомления */
  status: NotifyLetterStatus;
  /** Заголовок уведомления */
  title: Scalars['String']['output'];
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
};

/** Notify Letter Status */
export enum NotifyLetterStatus {
  New = 'NEW',
  Pending = 'PENDING',
  Published = 'PUBLISHED',
  Removed = 'REMOVED'
}

/** Notify Letter List Sortiing */
export enum NotifyLettersListSorting {
  PublishFrom = 'publishFrom',
  UpdatedAt = 'updatedAt'
}

/** Параметры запроса постраничного списка push-уведомлений. */
export type NotifyLettersPaginatedInput = {
  /** Номер страницы */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Строка поиска */
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  /** Количество элементов на странице */
  size?: InputMaybe<Scalars['Int']['input']>;
  /** Сортировка (по умолчанию "updatedAt") */
  sortBy?: InputMaybe<NotifyLettersListSorting>;
  /** Направление сортировки */
  sortWay?: InputMaybe<SortingWay>;
  /** Фильтрация по статусу push-уведомления */
  status?: InputMaybe<NotifyLetterStatus>;
};

/** Постраничный вывод push-уведомлений */
export type NotifyLettersPaginatedList = {
  __typename?: 'NotifyLettersPaginatedList';
  /** Всего элементов' */
  count: Scalars['Int']['output'];
  /** Список push-уведомлений */
  notifyLetters: Array<NotifyLetter>;
  /** Количество страниц */
  pages: Scalars['Int']['output'];
};

export type OchkoProgressOutput = {
  __typename?: 'OchkoProgressOutput';
  /** Текущее количество рефералов, достигших условий бонуса "Приведи друга" */
  currentReferrals: Scalars['Int']['output'];
  /** Текущее количество смен рефералов достигших условий бонуса "Приведи друга" */
  currentShifts?: Maybe<Scalars['Int']['output']>;
  /** Сумма бонуса */
  sum: Scalars['Float']['output'];
  /** Необходимое количество рефералов, достигших условий бонуса "Приведи друга", для достижения условий бонуса "Очко" */
  targetReferrals: Scalars['Int']['output'];
  /** Необходимое количество смен рефералов для достижения бонуса "Приведи друга" */
  targetShifts: Scalars['Int']['output'];
};

export type OchkoReferrerOutput = {
  __typename?: 'OchkoReferrerOutput';
  /** Объекты, на которых пользователь является Бригадиром */
  Facility_FOREMAN?: Maybe<Array<Facility>>;
  /** Объекты в которых пользователь Супервайзер */
  Facility_SUPERVISOR?: Maybe<Array<Facility>>;
  /** Документы, которые пользователь рассматривает */
  allDocumentsReviews: Array<DocumentReview>;
  /** Разрешить работать без Наймикс */
  allowWorkWithoutNaimix: Scalars['Boolean']['output'];
  /** Гражданство */
  citizenship?: Maybe<Country>;
  citizenshipId?: Maybe<Scalars['String']['output']>;
  /** Город */
  city?: Maybe<City>;
  /** Id города */
  cityId?: Maybe<Scalars['String']['output']>;
  /** Комментарии к сменам */
  comments: Array<CommentEntity>;
  contactPhones?: Maybe<Array<ContactPhone>>;
  /** Контракты пользователя */
  contracts?: Maybe<Array<UserContract>>;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  currentVersion?: Maybe<Scalars['String']['output']>;
  deletedAt: Scalars['Date']['output'];
  /** Файлы документов загруженные пользователю */
  documents: Array<Document>;
  /** Статус проверки документов */
  documentsStatus: DocumentsStatus;
  /** Email address */
  email?: Maybe<Scalars['String']['output']>;
  /** Id пользователя в ERP */
  externalId?: Maybe<Scalars['String']['output']>;
  /** Объекты в которых пользователь Менеджер */
  facilitiesManager?: Maybe<Array<Facility>>;
  facilityToUsers: Array<FacilityToUser>;
  /** Имя */
  firstname: Scalars['String']['output'];
  /** Количество хороших смен пользователя */
  goodShiftCount?: Maybe<Scalars['Int']['output']>;
  groupManagers: Array<GroupManager>;
  /** Группы в которых пользователь Директор */
  groupsDirector?: Maybe<Array<FacilityGroup>>;
  /** Группы в которых пользователь назначен как Менеджер */
  groupsManager?: Maybe<Array<FacilityGroup>>;
  /** ID реферерра */
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  lastLoginAt?: Maybe<Scalars['Date']['output']>;
  /** Фамилия */
  lastname: Scalars['String']['output'];
  /** Лид */
  lead?: Maybe<Lead>;
  leads: Array<LeadOutput>;
  legalEntities: Array<LegalEntity>;
  manualAccrualItems: Array<ManualAccrualItem>;
  manualUserAccruals: Array<ManualUserAccrual>;
  marks?: Maybe<Array<Mark>>;
  /** Документы пользователя на рассмотрении */
  myDocumentsReviews: Array<DocumentReview>;
  notifications: Array<Notification>;
  /** Отчество */
  patronymic?: Maybe<Scalars['String']['output']>;
  /** Телефон */
  phone: Scalars['String']['output'];
  /** Id должности */
  positionId?: Maybe<Scalars['String']['output']>;
  professions: Array<Profession>;
  progress: OchkoProgressOutput;
  /** Анкета */
  questionnaire?: Maybe<Questionnaire>;
  referrals?: Maybe<Array<User>>;
  referrer?: Maybe<User>;
  referrerId?: Maybe<Scalars['String']['output']>;
  /** Состояние регистрации пользователя в Наймикс */
  registrationState?: Maybe<UserRegistrationState>;
  reports: Array<Report>;
  requestShiftSuggestions: Array<RequestShiftSuggestionEntity>;
  /** Реквизиты пользователя (отельная модель) */
  requisite?: Maybe<Requisite>;
  respondedVacancies?: Maybe<Array<Vacancy>>;
  /** Роль */
  role: Role;
  schedule?: Maybe<Array<Schedule>>;
  shiftForemen: Array<ShiftForeman>;
  shiftPenalties: Array<ShiftPenalty>;
  /** Смены пользователя */
  shifts?: Maybe<Array<Shift>>;
  /** подписка на объекты, о желании на них работать */
  subscribedFacility?: Maybe<Array<Facility>>;
  subscribers: Array<Subscribers>;
  supervisors: Array<Supervisors>;
  temporaryShifts: Array<TemporaryShift>;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  userActivityLogs: Array<UserActivityLog>;
  /** Автоматический рейтинг пользователя */
  userAutoRating?: Maybe<UserAutoRating>;
  userDevice: UserDevice;
  /** История опыта работника */
  userExperience?: Maybe<Array<UserExperience>>;
  /** История опыта работника */
  userGrade?: Maybe<Array<UserGrade>>;
  userGradeAuthor?: Maybe<Array<UserGrade>>;
  /** История опыта работника */
  userReview?: Maybe<Array<UserReview>>;
  userReviewAuthor: Array<UserReview>;
  /** Рабочие места пользователя */
  workposts?: Maybe<Array<WorkPost>>;
};

/** Параметры запроса на постраничный вывод реферреров с прогрессом по бонусу "21 друг" */
export type OchkoReferrersListInput = {
  /** Фильтр по минимальной дате выполнения бонуса */
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Фильтр по максимальной дате выполнения бонуса */
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
  /** ID группы объектов */
  facilityGroupId?: InputMaybe<Scalars['String']['input']>;
  /** ID объекта */
  facilityId?: InputMaybe<Scalars['String']['input']>;
  /** Номер страницы */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Роль реферера */
  role?: InputMaybe<Role>;
  /** Строка поиска */
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  /** Количество элементов на странице */
  size?: InputMaybe<Scalars['Int']['input']>;
  /** Сортировать по полю */
  sortBy?: InputMaybe<OchkoReferrersSorting>;
  /** Направление сортировки */
  sortWay?: InputMaybe<SortingWay>;
};

/** Ochko referrers sorting */
export enum OchkoReferrersSorting {
  Lastname = 'lastname',
  OchkoDate = 'ochkoDate'
}

/** Направление сортировки */
export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type PaginatedCasingsOutput = {
  __typename?: 'PaginatedCasingsOutput';
  /** Профессии на объекте */
  casings: Array<Casing>;
  /** Количество профессий */
  count: Scalars['Int']['output'];
  /** Количество страниц */
  pages: Scalars['Int']['output'];
};

export type PaginatedClientUsers = {
  __typename?: 'PaginatedClientUsers';
  /** Количество страниц */
  count: Scalars['Int']['output'];
  /** Количество страниц */
  pages: Scalars['Int']['output'];
  /** пользователи */
  users: Array<ClientUser>;
};

export type PaginatedFacilities = {
  __typename?: 'PaginatedFacilities';
  /** Количество страниц */
  count: Scalars['Int']['output'];
  /** объекты */
  facilities: Array<Facility>;
  /** Количество страниц */
  pages: Scalars['Int']['output'];
};

export type PaginatedLeadsModel = {
  __typename?: 'PaginatedLeadsModel';
  /** Количество лидов всего */
  count: Scalars['Int']['output'];
  /** Список Лидов */
  leads: Array<LeadOutput>;
  /** Количество страниц с лидами */
  pages: Scalars['Int']['output'];
};

/** Табели модерации смен по периодам с пагинацией */
export type PaginatedModerationReportModel = {
  __typename?: 'PaginatedModerationReportModel';
  /** Количество страниц */
  count: Scalars['Int']['output'];
  /** Количество страниц */
  pages: Scalars['Int']['output'];
  /** Массив Табелей Модерации */
  reports: Array<ModerationReport>;
};

export type PaginatedNotifications = {
  __typename?: 'PaginatedNotifications';
  /** Всего уведомлений */
  count: Scalars['Int']['output'];
  /** Уведомления */
  notifications: Array<Notification>;
  /** Количество страниц */
  pages: Scalars['Int']['output'];
};

export type PaginatedNotificationsInput = {
  /** Фильтр только прочитанные / не прочитанные */
  isRead?: InputMaybe<Scalars['Boolean']['input']>;
  /** Сколько уведомлений пропустить */
  offset: Scalars['Int']['input'];
  /** Кол-во уведомлений на странице */
  size: Scalars['Int']['input'];
  /** Направление сортировки по дате создания */
  sortWay?: InputMaybe<SortingWay>;
};

export type PaginatedOchkoReferrersList = {
  __typename?: 'PaginatedOchkoReferrersList';
  /** Количество реферерров всего */
  count: Scalars['Int']['output'];
  /** Количество страниц с рефераррами */
  pages: Scalars['Int']['output'];
  /** Список реферерров */
  referrers: Array<OchkoReferrerOutput>;
};

export type PaginatedStory = {
  __typename?: 'PaginatedStory';
  /** Всего "Историй" */
  count: Scalars['Int']['output'];
  /** Количество страниц */
  pages: Scalars['Int']['output'];
  /** Массив "Историй" */
  stories: Array<Story>;
};

export type PaginatedUsers = {
  __typename?: 'PaginatedUsers';
  /** Количество страниц */
  count: Scalars['Int']['output'];
  /** Количество страниц */
  pages: Scalars['Int']['output'];
  /** пользователи */
  users: Array<User>;
};

export type PaginatedVacancies = {
  __typename?: 'PaginatedVacancies';
  /** Количество вакансий */
  count: Scalars['Int']['output'];
  /** Количество страниц */
  pages: Scalars['Int']['output'];
  /** Вакансии */
  vacancies: Array<Vacancy>;
};

export type PaginatedZayavky = {
  __typename?: 'PaginatedZayavky';
  /** Количество объектов */
  count: Scalars['Int']['output'];
  /** Количество страниц */
  pages: Scalars['Int']['output'];
  /** объекты */
  zayavky: Array<ZayavkaProgress>;
};

export type Payment = {
  __typename?: 'Payment';
  /** Дата и время создания объекта */
  createdAt: Scalars['Date']['output'];
  date: Scalars['String']['output'];
  /** Уникальный идентификатор объекта */
  id: Scalars['ID']['output'];
  shiftsCount: Scalars['Int']['output'];
  sum: Scalars['Int']['output'];
  /** Дата и время обновления объекта */
  updatedAt: Scalars['Date']['output'];
};

/** Статус проверки платёжных данных в Наймикс */
export enum PaymentInfoEnum {
  Confirmed = 'CONFIRMED',
  NotChecked = 'NOT_CHECKED',
  NotConfirmed = 'NOT_CONFIRMED'
}

/** Частота выплат */
export enum PayoutFrequency {
  Monthly = 'MONTHLY',
  TwiceInMonth = 'TWICE_IN_MONTH',
  Weekly = 'WEEKLY'
}

/** Тип выплаты за работу HOUR Часы, POINT Проценты, PIECE Штуки */
export enum PayoutType {
  Hourly = 'HOURLY',
  Piecework = 'PIECEWORK'
}

export type Penalty = {
  __typename?: 'Penalty';
  /** Интервал до начала смены с которого срабатывает штраф в минутах */
  applyTimeInMinutes: Scalars['Int']['output'];
  /** Комментарий */
  comment?: Maybe<Scalars['String']['output']>;
  /** Стоимость штрафа (в руб.) */
  cost: Scalars['Float']['output'];
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  facility: Facility;
  /** Id объекта */
  facilityId?: Maybe<Scalars['String']['output']>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  shiftPenalties: Array<ShiftPenalty>;
  /** Тип штрафа */
  type: PenaltyType;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
};

/** Тип штрафа */
export enum PenaltyType {
  ShiftRejected = 'SHIFT_REJECTED'
}

/** Period type */
export enum PeriodType {
  Custom = 'CUSTOM',
  Month = 'MONTH',
  TwoWeek = 'TWO_WEEK',
  Week = 'WEEK'
}

export type PersonalInfoMedInvoiceInput = {
  /** Ожидаемая дата выдачи медицинской книжки после получения справки */
  medicalExpectedReceiptDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Дата выдачи медицинской справки (о прохождении осмотра перед получением медицинской книжки) */
  medicalInvoiceIssueDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Id работника */
  userId: Scalars['String']['input'];
};

export type PersonalInfoMedInvoiceOutput = {
  __typename?: 'PersonalInfoMedInvoiceOutput';
  /** Ожидаемая дата выдачи медицинской книжки после получения справки */
  medicalExpectedReceiptDate?: Maybe<Scalars['Date']['output']>;
  /** Дата выдачи медицинской справки (о прохождении осмотра перед получением медицинской книжки) */
  medicalInvoiceIssueDate?: Maybe<Scalars['Date']['output']>;
  /** Дата обновления блока мед.книжка */
  updatedAtMedical?: Maybe<Scalars['Date']['output']>;
};

export type PersonalInfoMedicalInput = {
  /** Даты конца аттестации */
  medicalAttestationEnd?: InputMaybe<Scalars['DateTime']['input']>;
  /** Даты начала аттестации */
  medicalAttestationStart?: InputMaybe<Scalars['DateTime']['input']>;
  /** Ожидаемая дата выдачи медицинской книжки после получения справки */
  medicalExpectedReceiptDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Дата выдачи медицинской справки (о прохождении осмотра перед получением медицинской книжки) */
  medicalInvoiceIssueDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Номер мед.книжки */
  medicalNumber?: InputMaybe<Scalars['String']['input']>;
  /** Id работника */
  userId: Scalars['String']['input'];
};

export type PersonalInfoMedicalOutput = {
  __typename?: 'PersonalInfoMedicalOutput';
  /** Даты конца аттестации */
  medicalAttestationEnd?: Maybe<Scalars['DateTime']['output']>;
  /** Даты начала аттестации в массиве */
  medicalAttestationStart?: Maybe<Scalars['DateTime']['output']>;
  /** Ожидаемая дата выдачи медицинской книжки после получения справки */
  medicalExpectedReceiptDate?: Maybe<Scalars['Date']['output']>;
  /** Дата выдачи медицинской справки (о прохождении осмотра перед получением медицинской книжки) */
  medicalInvoiceIssueDate?: Maybe<Scalars['Date']['output']>;
  /** Номер мед.книжки */
  medicalNumber?: Maybe<Scalars['String']['output']>;
  /** Дата обновления блока мед.книжка */
  updatedAtMedical?: Maybe<Scalars['Date']['output']>;
};

export type PersonalInfoOutput = {
  __typename?: 'PersonalInfoOutput';
  /** Документы пользователя */
  documents: Array<Document>;
  /** Периоды аттестации медкнижки */
  medicalAttestations: Array<MedicalAttestation>;
  /** Все заполняемые поля документов */
  requisite?: Maybe<Requisite>;
  /** Апрувы на документы пользователя */
  reviews: Array<DocumentReview>;
  /** ID пользователя */
  userId: Scalars['String']['output'];
};

export type PersonalInfoPassportInput = {
  /** Дата рождения */
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Место рождения */
  birthPlace?: InputMaybe<Scalars['String']['input']>;
  /** Имя */
  firstname?: InputMaybe<Scalars['String']['input']>;
  /** Фамилия */
  lastname?: InputMaybe<Scalars['String']['input']>;
  /** Кем выдан паспорт */
  passportIssuedBy?: InputMaybe<Scalars['String']['input']>;
  /** Дата выдачи паспорта */
  passportIssuedDate?: InputMaybe<Scalars['String']['input']>;
  /** Код подразделения, выдавшего паспорт */
  passportIssuerCode?: InputMaybe<Scalars['String']['input']>;
  /** Номер паспорта */
  passportNumber?: InputMaybe<Scalars['String']['input']>;
  /** Адрес регистрации */
  passportRegistrationAddress?: InputMaybe<Scalars['String']['input']>;
  /** Серия паспорта */
  passportSeries?: InputMaybe<Scalars['String']['input']>;
  /** Отчество */
  patronymic?: InputMaybe<Scalars['String']['input']>;
  /** Пол */
  sex?: InputMaybe<Sex>;
  /** Id работника */
  userId: Scalars['String']['input'];
};

export type PersonalInfoPassportOutput = {
  __typename?: 'PersonalInfoPassportOutput';
  /** Дата рождения */
  birthDate?: Maybe<Scalars['DateTime']['output']>;
  /** Место рождения */
  birthPlace?: Maybe<Scalars['String']['output']>;
  /** Имя */
  firstname: Scalars['String']['output'];
  /** Фамилия */
  lastname: Scalars['String']['output'];
  /** Кем выдан паспорт */
  passportIssuedBy: Scalars['String']['output'];
  /** Дата выдачи паспорта */
  passportIssuedDate?: Maybe<Scalars['String']['output']>;
  /** Код подразделения, выдавшего паспорт */
  passportIssuerCode?: Maybe<Scalars['String']['output']>;
  /** Номер паспорта */
  passportNumber: Scalars['String']['output'];
  /** Адрес регистрации */
  passportRegistrationAddress?: Maybe<Scalars['String']['output']>;
  /** Серия паспорта */
  passportSeries: Scalars['String']['output'];
  /** Отчество */
  patronymic: Scalars['String']['output'];
  /** Пол */
  sex?: Maybe<Sex>;
  /** Дата обновления блока паспортные данные */
  updatedAtPassport?: Maybe<Scalars['Date']['output']>;
};

export type PersonalInfoPaymentInput = {
  /** Номер счёта */
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  /** Название банка */
  bankName?: InputMaybe<Scalars['String']['input']>;
  /** БИК */
  bik?: InputMaybe<Scalars['String']['input']>;
  /** Срок действия карты */
  cardExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  /** Имя держателя карты */
  cardName?: InputMaybe<Scalars['String']['input']>;
  /** Номер карты */
  cardNumber?: InputMaybe<Scalars['String']['input']>;
  /** Номер корр.счёта */
  correspondentAccount?: InputMaybe<Scalars['String']['input']>;
  /** Email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Id работника */
  userId: Scalars['String']['input'];
};

export type PersonalInfoPaymentOutput = {
  __typename?: 'PersonalInfoPaymentOutput';
  /** Номер счёта */
  accountNumber?: Maybe<Scalars['String']['output']>;
  /** Название банка */
  bankName?: Maybe<Scalars['String']['output']>;
  /** БИК */
  bik?: Maybe<Scalars['String']['output']>;
  /** Срок действия карты */
  cardExpiryDate?: Maybe<Scalars['Date']['output']>;
  /** Имя держателя карты */
  cardName?: Maybe<Scalars['String']['output']>;
  /** Номер карты */
  cardNumber: Scalars['String']['output'];
  /** Номер корр.счёта */
  correspondentAccount?: Maybe<Scalars['String']['output']>;
  /** Email */
  email?: Maybe<Scalars['String']['output']>;
  /** Дата обновления блока платежная информация */
  updatedAtPayment?: Maybe<Scalars['Date']['output']>;
};

export type PersonalInfoTaxInput = {
  /** ИНН */
  inn?: InputMaybe<Scalars['String']['input']>;
  /** Статус самозанятого */
  selfEmployed?: InputMaybe<SelfEmployedStatus>;
  /** Дата самозанятости */
  selfEmployedDate?: InputMaybe<Scalars['Date']['input']>;
  /** Id работника */
  userId: Scalars['String']['input'];
};

export type PersonalInfoTaxOutput = {
  __typename?: 'PersonalInfoTaxOutput';
  /** ИНН */
  inn?: Maybe<Scalars['String']['output']>;
  /** Статус самозанятого */
  selfEmployed?: Maybe<SelfEmployedStatus>;
  /** Дата самозанятости */
  selfEmployedDate?: Maybe<Scalars['Date']['output']>;
  /** Дата обновления блока налоговые реквизиты */
  updatedAtTax?: Maybe<Scalars['Date']['output']>;
};

export type PlanCasingRateInput = {
  casingId: Scalars['String']['input'];
  isProbation: Scalars['Boolean']['input'];
  rate: Scalars['Float']['input'];
  startDatetime: Scalars['String']['input'];
};

export type Position = {
  __typename?: 'Position';
  casings: Array<Casing>;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Описание должности */
  description?: Maybe<Scalars['String']['output']>;
  /** Id позиции в ERP */
  externalId?: Maybe<Scalars['String']['output']>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Возможность самозанятости */
  isSelfEmployed: Scalars['Boolean']['output'];
  manualAccrualItems: Array<ManualAccrualItem>;
  /** Название должности */
  name: Scalars['String']['output'];
  professions: Array<Profession>;
  publicConditions?: Maybe<Array<PublicCondition>>;
  schedules: Array<Schedule>;
  shifts: Array<Shift>;
  temporaryShifts: Array<TemporaryShift>;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  userExperiences: Array<UserExperience>;
  userGrades: Array<UserGrade>;
  userReviews: Array<UserReview>;
  users: Array<User>;
  vacancies: Array<Vacancy>;
  workPosts: Array<WorkPost>;
  zayavkaPlans: Array<ZayavkaPlan>;
  zayavkaSchedules: Array<ZayavkaSchedule>;
  zayavkaShifts: Array<ZayavkaShift>;
};

/** Таблица-справочник должностей на объекте */
export type PositionCardInput = {
  /** Название должности */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Id объекта в ERP */
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  /** Возможность самозанятости */
  isSelfEmployed?: Scalars['Boolean']['input'];
  /** Название должности */
  name: Scalars['String']['input'];
};

/** Связь пользователя и должностей по которым он хочет работать */
export type Profession = {
  __typename?: 'Profession';
  /** Дата и время связи объектов */
  assignedAt: Scalars['Date']['output'];
  /** кем назначен */
  assignedBy?: Maybe<Scalars['String']['output']>;
  isVerified: Scalars['Boolean']['output'];
  /** Профессия которую выбрал пользователь, в качестве желаемой */
  position?: Maybe<Position>;
  /** id должности, по который хочет работать пользователь указанный в userId  */
  positionId: Scalars['String']['output'];
  /** Дата и время создания объекта */
  updatedAt: Scalars['Date']['output'];
  /** Пользователь выбравший желаемую профессию */
  user?: Maybe<User>;
  /** id пользователя, который хочет работать по связанной должности (positionId)  */
  userId: Scalars['String']['output'];
};

/** Бонусная программа */
export type PromotionEntity = {
  __typename?: 'PromotionEntity';
  /** Количество плохих смен для аннулирования */
  badShiftCount: Scalars['Int']['output'];
  /** Дочерние бонусные программы */
  childPromotions?: Maybe<Array<PromotionEntity>>;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Дата и время окончания бонусной программы */
  dateTimeEnd?: Maybe<Scalars['Date']['output']>;
  /** Дата и время начала бонусной программы */
  dateTimeStart: Scalars['Date']['output'];
  /** Объект, к которому принадлежит дочерняя бонусная программа */
  facility?: Maybe<Facility>;
  /** Объект, к которому принадлежит дочерняя бонусная программа */
  facilityGroup?: Maybe<FacilityGroup>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Название бонусной программы */
  name: Scalars['String']['output'];
  /** Родительская бонусная программа */
  parentPromotion?: Maybe<PromotionEntity>;
  /** Тип повтора бонусной программы (условия запуска бонусной программы с нуля) */
  repeatCondition: PromotionRepeatConditionEnum;
  /** Тип требования для получения бонуса */
  requirementBonus: PromotionRequirementBonusEnum;
  /** Количество смен для выполнения условий получения бонуса */
  requirementShiftCount: Scalars['Int']['output'];
  /** Сумма вознаграждения */
  rewardSum: Scalars['Int']['output'];
  /** Тип вознаграждения */
  rewardType: PromotionRewardTypeEnum;
  /** Статус бонусной программы */
  status: PromotionStatusEnum;
  /** Дата и время обновления */
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

/** Повтор (условия запуска бонусной программы с нуля) */
export enum PromotionRepeatConditionEnum {
  /** Раз в месяц */
  OnceAMonth = 'ONCE_A_MONTH',
  /** Раз в 2 месяца */
  OnceEvery_2Month = 'ONCE_EVERY_2_MONTH',
  /** Раз в 3 месяца */
  OnceEvery_3Month = 'ONCE_EVERY_3_MONTH',
  /** Раз в полгода */
  OnceEvery_6Month = 'ONCE_EVERY_6_MONTH',
  /** Раз в год */
  OnceEvery_12Month = 'ONCE_EVERY_12_MONTH',
  /** Однократно */
  OneTime = 'ONE_TIME'
}

/** Тип бонуса в бонусной программе */
export enum PromotionRequirementBonusEnum {
  /** Количество хороших смен */
  GoodShiftCount = 'GOOD_SHIFT_COUNT',
  /** Количество хороших смен + выработка */
  GoodShiftCountPlusProduction = 'GOOD_SHIFT_COUNT_PLUS_PRODUCTION'
}

export type PromotionResult = {
  __typename?: 'PromotionResult';
  /** Прогресс выполнения */
  progress: Scalars['Int']['output'];
  /** Сущность бонусной программы */
  promotion: PromotionEntity;
};

/** Тип вознаграждения в бонусной программе */
export enum PromotionRewardTypeEnum {
  /** Надбавка к хорошим сменам */
  BonusForGoodShifts = 'BONUS_FOR_GOOD_SHIFTS',
  /** Единоразовая выплата к следующей хорошей смене */
  SinglePaymentForTheNextGoodShift = 'SINGLE_PAYMENT_FOR_THE_NEXT_GOOD_SHIFT',
  /** Надбавка к ставке */
  SurchargeToTheRate = 'SURCHARGE_TO_THE_RATE'
}

/** Статус бонусной программы */
export enum PromotionStatusEnum {
  /** Завершено */
  Completed = 'COMPLETED',
  /** Удалено */
  Deleted = 'DELETED',
  /** Опубликовано */
  Published = 'PUBLISHED',
  /** Ожидает отправки */
  WaitingToBeSent = 'WAITING_TO_BE_SENT'
}

/** Условия публикации "Историй"/"рассылок пуш-уведомлений" */
export type PublicCondition = {
  __typename?: 'PublicCondition';
  cities?: Maybe<Array<City>>;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  facilities?: Maybe<Array<Facility>>;
  facilityGroups?: Maybe<Array<FacilityGroup>>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Бессрочная публикация да/нет */
  indefinitely: Scalars['Boolean']['output'];
  /** Интервал для повторения рассылки в минутах */
  interval?: Maybe<Scalars['Int']['output']>;
  /** Пуш уведомление */
  notifyLetter?: Maybe<NotifyLetter>;
  positions?: Maybe<Array<Position>>;
  /** Дата начала публикации */
  publishFrom?: Maybe<Scalars['Date']['output']>;
  /** Дата окончания публикации */
  publishTo?: Maybe<Scalars['Date']['output']>;
  /** Фильтр по открепленным исполнителям */
  pushToUnassigned: PushToUnassignedFilter;
  /** Кол-во получателей истории / пуша по условиям публикации */
  recipientsCount: Scalars['Int']['output'];
  /** Роли для которых публикуется стори */
  roles: Array<Role>;
  /** "Истории" */
  stories?: Maybe<Array<Story>>;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  /** Показать группе пользователей да/нет */
  useCondition: Scalars['Boolean']['output'];
};

/** Параметры группы пользователей публикации */
export type PushRecipientsCountInput = {
  /** Массив ID городов */
  cities?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Массив ID объектов */
  facilities?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Массив ID групп объектов */
  facilityGroups?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Массив ID позиций (профессий) */
  positions?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Фильтр по открепленным исполнителям */
  pushToUnassigned: PushToUnassignedFilter;
  /** Массив Ролей */
  roles?: InputMaybe<Array<Role>>;
  /** Показать группе пользователей да/нет */
  useCondition?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Перенаправления в push-уведомлениях */
export enum PushRoute {
  Home = 'HOME',
  Promo = 'PROMO',
  StoryV1 = 'STORY_V1'
}

export enum PushToUnassignedFilter {
  UnassignedOnly = 'UnassignedOnly',
  WithUnassigned = 'WithUnassigned',
  WithoutUnassigned = 'WithoutUnassigned'
}

export type Query = {
  __typename?: 'Query';
  /** Возвращает объект со свободными, занятыми и принятыми сменами. Либо null, если все значения равны 0 */
  areThereAnyFreeShifts?: Maybe<AreThereAnyFreeShiftsModel>;
  /** @deprecated Метод не включает в себя статусы и возможность объединения аккаунтов */
  checkNaimixContractorStatus: Scalars['Boolean']['output'];
  /** Проверяет ИНН пользователя на возможность регистрации в Наймикс */
  checkNaimixContractorStatusV2: CheckNaimixContractorStatusV2Model;
  /** Метод для получения списка городов */
  cities: Array<City>;
  /** Возвращает список Директоров */
  clientDirectors: Array<User>;
  /** Работники, которые были назначены на смены по заявкам Клиента. в User.Shifts конкретно этого запроса смены только по заявкам клиента */
  clientUsers: PaginatedClientUsers;
  /** Метод для получения списка всех стран */
  countries: Array<Country>;
  /** Метод для получения привязанных объектов */
  facilities: Array<Facility>;
  /** Метод для получения списка объектов постранично */
  facilitiesPaginated: PaginatedFacilities;
  /** Получить карточку одного объекта */
  facility: Facility;
  /** Получить список позиций объекта */
  facilityCasings: Array<Casing>;
  /** Получить список объектов с бригадирами */
  facilityForemen: Array<Facility>;
  /** Возвращает информацию о группе объектов по id */
  facilityGroup: FacilityGroup;
  /** Получить список позиций группы объектов */
  facilityGroupCasings: PaginatedCasingsOutput;
  /** Список групп объектов */
  facilityGroups: Array<FacilityGroup>;
  /** Метод для получения пользователя по номеру телефона */
  findUserByPhone?: Maybe<User>;
  /** Список работников бригадира по всем его объектам с информацией о статусе мед.документов работника */
  foremanWorkersWithMedical: Array<WorkerWithMedical>;
  /** Получить список бригадиров */
  foremans: Array<User>;
  /**
   * Метод для получения активной реферальной программы
   * @deprecated Не нужен этот функционал для мобилки больше. Удалить через пару релизов
   */
  getActiveReferralProgram: ReferralProgramEntity;
  /** Возвращает всех юзеров с ролью менеджер */
  getAllManagers: Array<User>;
  /** Получить список горячих смен доступных работнику */
  getAllTemporaryShifts: Array<TemporaryShift>;
  /** Получить оклад по ID */
  getCasing: Casing;
  /** Получить галерею профессии объекта */
  getCasingGallery: Array<CasingGalleryEntity>;
  /** Получить галерею профессии объекта */
  getCasingGalleryByFacilityAndPosition: Array<CasingGalleryEntity>;
  /** Метод для получения города по идентификатору */
  getCity: City;
  /** Метод для получения текущего налога самозанятого */
  getCurrentSelfEmployedTax: Scalars['Float']['output'];
  /** Метод для получения адреса по fias id */
  getDadataAddressByFiasId: Scalars['String']['output'];
  /** Список групп к которым авторизованный пользователь прикреплен как Супервайзер / Менеджер / Директор */
  getDirectorOrManagerOrSupervisorGroups: Array<FacilityGroup>;
  /** Метод для получения привязанных объектов */
  getFacilitiesByFacilityGroupOfFacilityId: Array<Facility>;
  /** Получить галерею профессии объекта */
  getFacilityGallery: Array<FacilityGalleryEntity>;
  /** Список групп объектов */
  getFacilityGroupOperationList: Array<FacilityGroupBalanceOperation>;
  /** Метод для получения всех групп с объектами */
  getFacilityGroupsWithFacilities: Array<FacilityGroup>;
  /** Получение списка вакансий для карты */
  getFacilityVacanciesOnMap: Array<Facility>;
  /** Получить список свободных смен для календаря */
  getFreeRequestShiftsForCalendar: Array<ZayavkaShift>;
  /** Метод получения списка приглашённых */
  getFriends: MyFriendsOutput;
  /** Выводит постраничный список пользователей для страницы "Реферальная программа" */
  getLeadsV2: GetLeadsV2Model;
  /** Метод для получения списка юридических в Наймикс */
  getLegalEntities: Array<LegalEntity>;
  /** Получение списка связки id и названия объектов Наймикс */
  getLinkedNaimixObjects: Array<NaimixObjectOutput>;
  /** Метод для получения списка бонусных программ для группы объектов */
  getListPromotionsForFacility: Array<PromotionEntity>;
  /** Метод для получения списка бонусных программ для группы объектов */
  getListPromotionsForFacilityGroup: Array<PromotionEntity>;
  /** Получение списка вакансий */
  getListVacancies: Array<Vacancy>;
  getLoggedJobs: Scalars['String']['output'];
  /** Получение статуса может ли пользователь работать сегодня на объекте по мед книжке */
  getMedicalWaitingFacilities: Array<WaitingFacility>;
  /** Метод для получение данных для построения анкеты модерации */
  getModerationReport: ModerationReportTable;
  /** Получить бонусы пользователя */
  getMyBonusAndRates: BonusAndRate;
  getMyIp: Scalars['String']['output'];
  /** Метод для получения списка объектов Наймикс по ID юридического лица */
  getNaimixObjects?: Maybe<Array<NaimixObjectOutput>>;
  /** Получить уведомления */
  getNotifications: PaginatedNotifications;
  /** Получить число новый и прочитанных уведомлений */
  getNotificationsCounters: NotificationsCountersOutput;
  /** Получить рассылку по ID */
  getNotifyLetter: NotifyLetter;
  /** Получить постраничный список рассылок */
  getNotifyLettersPaginated: NotifyLettersPaginatedList;
  /** Получить список реферерров с прогрессом по бонусу "Очко" */
  getOchkoReferrersPaginated: PaginatedOchkoReferrersList;
  /** Получить позицию по ID */
  getPosition: Position;
  /** Получить список позиций объектов, групп объектов, городов */
  getPositionsByFacilitiesAndCities: Array<Position>;
  /** Получить список пользователей для рассылки */
  getPushListRecipients: Array<User>;
  /** Кол-во получателей истории / push-уведомления */
  getPushRecipientsCount: Scalars['Int']['output'];
  getPushingListJobs: Scalars['String']['output'];
  /** Метод для получения реферальной программы по id */
  getReferralProgram: ReferralProgramModel;
  /** Метод для получения списка реферальных программ */
  getReferralProgramsPaginated: GetReferralProgramsPaginatedModel;
  getRepeatableJobs: Scalars['String']['output'];
  /** Получить список графиков заявок с профессиями */
  getRequestScheduleListWithProfessions: Array<ZayavkaSchedule>;
  /** возвращает график и его смены */
  getSchedule: Schedule;
  /** Возвращает типы графиков */
  getScheduleTypes: Array<ScheduleType>;
  /** Метод для получения списка реферальных программ планировщика */
  getSchedulerReferralPrograms: Array<ReferralProgramEntity>;
  /** Данные для графиков на дашборд (v2) */
  getStatistic?: Maybe<Array<ChartOutput>>;
  /** Получение "Истории" по id */
  getStory: Story;
  /** Возвращает доступные Истории для пользователя */
  getStoryByUser: Array<Story>;
  /** Постраничный список "Историй" по 25 записей */
  getStoryPaginationList: PaginatedStory;
  /** Данные для табеля супервайзера */
  getSupervisorWorkSheet: GetSupervisorWorkSheetModel;
  /** Сформировать отчёт для супервайзера */
  getSupervisorWorkSheetReport: Scalars['String']['output'];
  /** Объекты супервайзера */
  getSupervisorsFacilities: Array<Facility>;
  /** Получить список горячих смен доступных бригадиру */
  getTemporaryShiftForForeman: Array<TemporaryShift>;
  /** Метод для получения таймзон, которые есть в enum TimeZones БД */
  getTimeZones: Array<Scalars['String']['output']>;
  /** Метод для получения уникальных названий населённых пунктов */
  getUniqueSettlements: Array<Scalars['String']['output']>;
  /** Получить демо агентского договора Наймикс */
  getUserDemoAgencyContract: GetDemoAgencyContractNaimixModel;
  /** Получение демо рамочного договора Наймикс */
  getUserDemoFrameContract: GetDemoFrameContractNaimixModel;
  /** Получить демо договора Наймикс на обработку ПД */
  getUserDemoPDContract: GetDemoPdContractNaimixModel;
  /** Метод для получения персональной информации */
  getUserDocuments: PersonalInfoOutput;
  /** Получение рамочного договора Наймикс */
  getUserFrameContract: GetDemoFrameContractNaimixModel;
  /** Получение договора из Наймикс */
  getUserNaimixContract: GetNaimixContractNaimixModel;
  /** Возвращает данные о профиле пользователя */
  getUserProfile: User;
  /** Получить реквизиты пользователя */
  getUserRequisite?: Maybe<Requisite>;
  /** Получить список пользователей по объекту и позиции */
  getUsersByFacilityAndPosition: Array<User>;
  /** Получить пользователей, которые откликнулись на график заявок */
  getUsersWhoRespondedToTheRequestSchedule: ZayavkaSchedule;
  /** Получить пользователей, которые откликнулись на заявку */
  getUsersWhoRespondedToTheRequestShift: ZayavkaShift;
  /** Получение галерей вакансии */
  getVacancyGallery: VacancyGallery;
  /** Получение времени работы вакансии */
  getVacancyStartTime: IVacancyStartTime;
  /** Объекты и должности как исполнителя */
  getWorkerFacilitiesWorkPostExperience: Array<WorkerFacilityWorkPostsOutput>;
  /** Возвращает список пользователей с пересечениями для выбранного Графика в заявке */
  getWorkersForZayavkaSchedule: Array<UserIntersect>;
  /** Возвращает список пользователей с пересечениями для выбранной Смены в заявке */
  getWorkersForZayavkaShift: Array<UserIntersect>;
  /** Возвращает заявку с планом заявки и графиками заявки */
  getZayavka: ZayavkaProgress;
  /** Отображает список всех заявок для работы с графиком Супервайзером */
  getZayavkyByFacility: Array<ZayavkaProgress>;
  /** Инициализация подписания агентского договора в Наймикс */
  initUserAgencyContractNaimix: InitAgencyContractResponseNaimixModel;
  /** Инициализация подписания рамочного договора в Наймикс */
  initUserFrameContractNaimix: Scalars['Boolean']['output'];
  /** Проверка номера телефона на регистрацию в Смене */
  isPhoneNumberRegistered: Scalars['Boolean']['output'];
  /** Проверка подтверждения самозанятости пользователя в Наймикс */
  isUserConfirmTaxInNaimix: Scalars['Boolean']['output'];
  /** Метод получения лида по id */
  lead: Lead;
  /**
   * Выводит постраничный список пользователей для страницы "Реферальная программа"
   * @deprecated Неоптимизированный метод
   */
  leads: PaginatedLeadsModel;
  /** Возвращает список менеджеров для конкретной группы объектов */
  managersByGroup: Array<User>;
  /** тяжелый запрос возвращает данные о текущем пользователе с большинством связанных данных */
  me: RichUser;
  /** сравнительно экономичный запрос возвращает данные о текущем пользователе без дополнительных запросов к связанным данным */
  meShortly: RichUser;
  /** Получение списка постраничного модераций */
  moderationReportsPaginated: PaginatedModerationReportModel;
  /** Получить начисления */
  myAccruals: Array<ManualUserAccrual>;
  /** Получить уведомление по ID */
  notification: Notification;
  /** Получить все позиции */
  positions: Array<Position>;
  /** Отображение для сопоставления пользователей из импорта и пользователей на объекте */
  resolveModerationUsers: Array<ResolveReportUserModel>;
  /** Метод для получения городов и объектов, которые есть в этих городах */
  searchCitiesWithFacilities: Array<City>;
  /** Поиск объектов с вакансиями по адресу */
  searchFacilitiesVacancies: Array<Facility>;
  /** Метод для получения адресов */
  searchFacilityAddresses: Array<Address>;
  /** Поиск станций метро по городу и названию */
  searchMetroStations: MetroStationsByLineOutput;
  /** Постраничный вывод списка договоров */
  searchUserContracts: UserContractsPaginatedOutput;
  /** Поиск объектов с вакансиями по адресу */
  searchVacanciesMobile: Array<Vacancy>;
  /** Получить переменные для фронта */
  setting: Setting;
  /** Получить смену по ID */
  shift: Shift;
  /** Получить список смен */
  shifts: Array<Shift>;
  /** Подписание агенского договора в Наймикс */
  signUserAgencyContractNaimix: Scalars['Boolean']['output'];
  /** Подписание рамочного договора в Наймикс */
  signUserFrameContractNaimix: Scalars['Boolean']['output'];
  /** Получить список супервайзеров */
  supervisors: Array<User>;
  /** Получить последние непрочитанные сообщения в числе 100 шт. */
  unreadNotifications: Array<Notification>;
  /** Получение пользователя по ID */
  user: RatingUser;
  /** Получить список смен пользователя */
  userShifts: Array<Shift>;
  /** Люди по вакансиям на карте */
  usersOnMapByFacility: Array<MapPlaceModel>;
  /** Люди по вакансиям на карте */
  usersOnMapByFacility2: Array<MapPlaceModel>;
  /** Получить постраничный список пользователей */
  usersPaginated: PaginatedUsers;
  /** Получение списка вакансий */
  vacancies: Array<Vacancy>;
  /** Список вакансий с пагинацией, фильтрами, поиском и сортировкам */
  vacanciesPaginated: PaginatedVacancies;
  /** Получение вакансии по ID */
  vacancy: Vacancy;
  /** Получение рабочего расписания */
  vacancySchedules: Array<Scalars['String']['output']>;
  /** Список заявок с пагинацией и фильтрацией */
  zayavkiPaginated: PaginatedZayavky;
};


export type QueryCheckNaimixContractorStatusArgs = {
  input: CheckNaimixContractorStatusInput;
};


export type QueryCheckNaimixContractorStatusV2Args = {
  input: CheckNaimixContractorStatusInput;
};


export type QueryCitiesArgs = {
  input?: InputMaybe<GetCitiesInput>;
};


export type QueryClientUsersArgs = {
  input?: InputMaybe<ClientUserListInput>;
};


export type QueryFacilitiesPaginatedArgs = {
  input: FacilityListInput;
};


export type QueryFacilityArgs = {
  id: Scalars['String']['input'];
};


export type QueryFacilityCasingsArgs = {
  facilityId: Scalars['String']['input'];
};


export type QueryFacilityForemenArgs = {
  facilityId: Scalars['String']['input'];
};


export type QueryFacilityGroupArgs = {
  id: Scalars['String']['input'];
};


export type QueryFacilityGroupCasingsArgs = {
  input: FacilityGroupCasingsInput;
};


export type QueryFacilityGroupsArgs = {
  cities?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryFindUserByPhoneArgs = {
  phone: Scalars['String']['input'];
};


export type QueryGetCasingArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetCasingGalleryArgs = {
  casingId: Scalars['String']['input'];
};


export type QueryGetCasingGalleryByFacilityAndPositionArgs = {
  facilityId: Scalars['String']['input'];
  positionId: Scalars['String']['input'];
};


export type QueryGetCityArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetDadataAddressByFiasIdArgs = {
  fiasId: Scalars['String']['input'];
};


export type QueryGetFacilitiesByFacilityGroupOfFacilityIdArgs = {
  input: GetFacilitiesByFacilityGroupOfFacilityIdInput;
};


export type QueryGetFacilityGalleryArgs = {
  facilityId: Scalars['String']['input'];
};


export type QueryGetFacilityGroupOperationListArgs = {
  facilityGroupId: Scalars['String']['input'];
};


export type QueryGetFacilityVacanciesOnMapArgs = {
  input: GetVacanciesOnMapInput;
};


export type QueryGetFreeRequestShiftsForCalendarArgs = {
  input: GetFreeRequestShiftsForCalendarInput;
};


export type QueryGetLeadsV2Args = {
  input: LeadListInput;
};


export type QueryGetListPromotionsForFacilityArgs = {
  input: GetListPromotionsForFacilityInput;
};


export type QueryGetListPromotionsForFacilityGroupArgs = {
  input: GetListPromotionsForFacilityGroupInput;
};


export type QueryGetListVacanciesArgs = {
  input: GetListVacanciesInput;
};


export type QueryGetLoggedJobsArgs = {
  type: Scalars['String']['input'];
};


export type QueryGetMedicalWaitingFacilitiesArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGetModerationReportArgs = {
  id: Scalars['String']['input'];
  showAll?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGetNaimixObjectsArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryGetNotificationsArgs = {
  input: PaginatedNotificationsInput;
};


export type QueryGetNotifyLetterArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetNotifyLettersPaginatedArgs = {
  input?: InputMaybe<NotifyLettersPaginatedInput>;
};


export type QueryGetOchkoReferrersPaginatedArgs = {
  input?: InputMaybe<OchkoReferrersListInput>;
};


export type QueryGetPositionArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetPositionsByFacilitiesAndCitiesArgs = {
  cityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  facilityGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  facilityIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryGetPushListRecipientsArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetPushRecipientsCountArgs = {
  input: PushRecipientsCountInput;
};


export type QueryGetPushingListJobsArgs = {
  type: Scalars['String']['input'];
};


export type QueryGetReferralProgramArgs = {
  input: GetReferralProgramInput;
};


export type QueryGetReferralProgramsPaginatedArgs = {
  input: GetReferralProgramsPaginatedInput;
};


export type QueryGetScheduleArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetSchedulerReferralProgramsArgs = {
  input: GetSchedulerReferralProgramsInput;
};


export type QueryGetStatisticArgs = {
  input: GetStatisticInput;
};


export type QueryGetStoryArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetStoryPaginationListArgs = {
  input: StoryListInput;
};


export type QueryGetSupervisorWorkSheetArgs = {
  input: GetSupervisorWorkSheetInput;
};


export type QueryGetSupervisorWorkSheetReportArgs = {
  input: GetSupervisorWorkSheetInput;
};


export type QueryGetUniqueSettlementsArgs = {
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetUserDemoFrameContractArgs = {
  input: GetUserDemoFrameContractInput;
};


export type QueryGetUserDocumentsArgs = {
  workerId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetUserFrameContractArgs = {
  input: GetUserDemoFrameContractInput;
};


export type QueryGetUserNaimixContractArgs = {
  input: GetUserNaimixContractInput;
};


export type QueryGetUserRequisiteArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGetUsersByFacilityAndPositionArgs = {
  facilityId: Scalars['String']['input'];
  positions: Array<Scalars['String']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetUsersWhoRespondedToTheRequestScheduleArgs = {
  input: GetUsersWhoRespondedToTheRequestScheduleInput;
};


export type QueryGetUsersWhoRespondedToTheRequestShiftArgs = {
  input: GetUsersWhoRespondedToTheRequestShiftInput;
};


export type QueryGetVacancyGalleryArgs = {
  input: GetVacancyGalleryInput;
};


export type QueryGetVacancyStartTimeArgs = {
  facilityGroupId: Scalars['String']['input'];
};


export type QueryGetWorkerFacilitiesWorkPostExperienceArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGetWorkersForZayavkaScheduleArgs = {
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  zayavkaScheduleId: Scalars['String']['input'];
};


export type QueryGetWorkersForZayavkaShiftArgs = {
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  zayavkaShiftId: Scalars['String']['input'];
};


export type QueryGetZayavkaArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetZayavkyByFacilityArgs = {
  facilityId: Scalars['String']['input'];
  month?: InputMaybe<Scalars['String']['input']>;
  show?: InputMaybe<ZayavkaByFacilityDisplay>;
};


export type QueryInitUserFrameContractNaimixArgs = {
  input: InitUserFrameContractNaimixInput;
};


export type QueryIsPhoneNumberRegisteredArgs = {
  phone: Scalars['String']['input'];
};


export type QueryLeadArgs = {
  id: Scalars['String']['input'];
};


export type QueryLeadsArgs = {
  input?: InputMaybe<LeadListInput>;
};


export type QueryManagersByGroupArgs = {
  groupId: Scalars['String']['input'];
};


export type QueryModerationReportsPaginatedArgs = {
  input: ModerationReportListInput;
};


export type QueryNotificationArgs = {
  id: Scalars['String']['input'];
};


export type QueryResolveModerationUsersArgs = {
  reportId: Scalars['String']['input'];
};


export type QuerySearchCitiesWithFacilitiesArgs = {
  facilityGroupId: Scalars['String']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchFacilitiesVacanciesArgs = {
  input: SearchVacanciesFilterInput;
};


export type QuerySearchFacilityAddressesArgs = {
  query: Scalars['String']['input'];
};


export type QuerySearchMetroStationsArgs = {
  city: Scalars['String']['input'];
  search: Scalars['String']['input'];
};


export type QuerySearchUserContractsArgs = {
  input: SearchContractsInput;
};


export type QuerySearchVacanciesMobileArgs = {
  input: SearchVacanciesMobileFilterInput;
};


export type QuerySettingArgs = {
  name: Scalars['String']['input'];
};


export type QueryShiftArgs = {
  id: Scalars['String']['input'];
};


export type QueryShiftsArgs = {
  input?: InputMaybe<FindUserShiftsInput>;
};


export type QuerySignUserAgencyContractNaimixArgs = {
  input: SignUserAgencyContractNaimixInput;
};


export type QuerySignUserFrameContractNaimixArgs = {
  input: SignUserFrameContractNaimixInput;
};


export type QueryUserArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserShiftsArgs = {
  input: FindUserShiftsInput;
};


export type QueryUsersPaginatedArgs = {
  input?: InputMaybe<UserListInput>;
};


export type QueryVacanciesArgs = {
  facilityId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryVacanciesPaginatedArgs = {
  input: VacancyListInput;
};


export type QueryVacancyArgs = {
  vacancyId: Scalars['String']['input'];
};


export type QueryZayavkiPaginatedArgs = {
  input: ZayavkaListInput;
};

/** Анкета пользователя */
export type Questionnaire = {
  __typename?: 'Questionnaire';
  address: Address;
  /**
   * Id адреса
   * @deprecated Устарело, использовать cityId
   */
  addressId?: Maybe<Scalars['String']['output']>;
  /** Размер одежды */
  clothingSize?: Maybe<Scalars['String']['output']>;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Свободный график */
  customSchedule?: Maybe<Scalars['String']['output']>;
  /** IDs групп объектов */
  facilityGroupIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Размер ноги */
  footSize?: Maybe<Scalars['Int']['output']>;
  /** Рост */
  growth?: Maybe<Scalars['Int']['output']>;
  /** Кол-во часов в день */
  hoursPerDay?: Maybe<Scalars['Int']['output']>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** IDs станций метро */
  metroStationsIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Профессии */
  positionsIds: Array<Scalars['String']['output']>;
  /** Радиус поиска вакансий в метрах */
  radius?: Maybe<Scalars['Int']['output']>;
  /**
   * ID типов графиков
   * @deprecated Отказываемся от них
   */
  scheduleTypesIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Пол */
  sex?: Maybe<Sex>;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  user: User;
  /** Id пользователя */
  userId: Scalars['String']['output'];
  vacancySearchType: VacancySearchType;
};

/** Анкета работника */
export type QuestionnaireInput = {
  /** Данные адреса из Dadata */
  address?: InputMaybe<AddressInput>;
  /** Идентификатор страны */
  citizenshipId?: InputMaybe<Scalars['String']['input']>;
  /** Идентификатор города */
  cityId?: InputMaybe<Scalars['String']['input']>;
  /** Размер одежды */
  clothingSize?: InputMaybe<Scalars['String']['input']>;
  /** Свободный график */
  customSchedule?: InputMaybe<Scalars['String']['input']>;
  /** Массив IDs профессий */
  facilityGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Имя */
  firstname?: InputMaybe<Scalars['String']['input']>;
  /** Размер ноги */
  footSize?: InputMaybe<Scalars['Int']['input']>;
  /** Рост */
  growth?: InputMaybe<Scalars['Int']['input']>;
  /** Кол-во часов в день */
  hoursPerDay?: InputMaybe<Scalars['Int']['input']>;
  /** Фамилия */
  lastname?: InputMaybe<Scalars['String']['input']>;
  /** IDs станций метро */
  metroStationsIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Отчество */
  patronymic?: InputMaybe<Scalars['String']['input']>;
  /** Массив IDs профессий */
  positionsIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Массив IDs типов графиков */
  scheduleTypesIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Пол */
  sex?: InputMaybe<Sex>;
  /** Идентификатор пользователя */
  userId?: InputMaybe<Scalars['String']['input']>;
  vacancySearchType?: InputMaybe<VacancySearchType>;
};

export type RatesOutput = {
  __typename?: 'RatesOutput';
  /** Когда назначен на рабочее место */
  assignedAt: Scalars['Date']['output'];
  assignedBy: Scalars['String']['output'];
  /** Экземпляр модели объекта, на котором пользователь занимает конкретную должность */
  facility?: Maybe<Facility>;
  /** id объекта, на котором пользователь занимает конкретную должность */
  facilityId: Scalars['String']['output'];
  /** Возможность стажировки */
  hasProbation: Scalars['Boolean']['output'];
  /** Признак есть ли хотя бы одна отработанная смена после стажировки */
  hasWorkedShift?: Maybe<Scalars['Boolean']['output']>;
  /** размер оплаты в час */
  hourRate?: Maybe<Scalars['Float']['output']>;
  /** Признак пройдена ли стажировка */
  isProbationFinished?: Maybe<Scalars['Boolean']['output']>;
  /** Возможность самозанятости */
  isSelfEmployed: Scalars['Boolean']['output'];
  /** Тип выплаты за работу */
  payout?: Maybe<PayoutType>;
  /** Объект должности, которую занимает пользователь на объекте (рабочем месте) */
  position?: Maybe<Position>;
  /** id должности, которую занимает пользователь на объекте (рабочем месте) */
  positionId: Scalars['String']['output'];
  /** Дата окончания стажировки в формате YYYY-MM-DD */
  probationEnd?: Maybe<Scalars['String']['output']>;
  /** Период стажировки в календарных днях */
  probationPeriod?: Maybe<Scalars['Int']['output']>;
  /** Ставка за смену на период стажировки */
  probationRate?: Maybe<Scalars['Int']['output']>;
  /** Дата начала стажировки в формате YYYY-MM-DD */
  probationStart?: Maybe<Scalars['String']['output']>;
  /** Надбавка к часовой ставке за самозанятость у работника */
  selfEmployedRate?: Maybe<Scalars['Int']['output']>;
  /** Статус рабочего места */
  status: WorkPostStatus;
  /** Единицы измерения для оплаты */
  unit?: Maybe<UnitType>;
  /** Дата и время создания рабочего места */
  updatedAt: Scalars['Date']['output'];
  /** Объект пользователя, который связан с рабочим местом (должность + объект) */
  user?: Maybe<User>;
  /** Id пользователя, который связан с рабочим местом (должность + объект) */
  userId: Scalars['String']['output'];
};

/** Юзер с полями: скорость, характер, ответственность */
export type RatingUser = {
  __typename?: 'RatingUser';
  /** Объекты, на которых пользователь является Бригадиром */
  Facility_FOREMAN?: Maybe<Array<Facility>>;
  /** Объекты в которых пользователь Супервайзер */
  Facility_SUPERVISOR?: Maybe<Array<Facility>>;
  /** Документы, которые пользователь рассматривает */
  allDocumentsReviews: Array<DocumentReview>;
  /** Разрешить работать без Наймикс */
  allowWorkWithoutNaimix: Scalars['Boolean']['output'];
  character: Scalars['Int']['output'];
  /** Гражданство */
  citizenship?: Maybe<Country>;
  citizenshipId?: Maybe<Scalars['String']['output']>;
  /** Город */
  city?: Maybe<City>;
  /** Id города */
  cityId?: Maybe<Scalars['String']['output']>;
  /** Комментарии к сменам */
  comments: Array<CommentEntity>;
  contactPhones?: Maybe<Array<ContactPhone>>;
  /** Контракты пользователя */
  contracts?: Maybe<Array<UserContract>>;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  currentVersion?: Maybe<Scalars['String']['output']>;
  deletedAt: Scalars['Date']['output'];
  /** Файлы документов загруженные пользователю */
  documents: Array<Document>;
  /** Статус проверки документов */
  documentsStatus: DocumentsStatus;
  /** Email address */
  email?: Maybe<Scalars['String']['output']>;
  /** Id пользователя в ERP */
  externalId?: Maybe<Scalars['String']['output']>;
  /** Объекты в которых пользователь Менеджер */
  facilitiesManager?: Maybe<Array<Facility>>;
  facilityToUsers: Array<FacilityToUser>;
  /** Имя */
  firstname: Scalars['String']['output'];
  /** Количество хороших смен пользователя */
  goodShiftCount?: Maybe<Scalars['Int']['output']>;
  groupManagers: Array<GroupManager>;
  /** Группы в которых пользователь Директор */
  groupsDirector?: Maybe<Array<FacilityGroup>>;
  /** Группы в которых пользователь назначен как Менеджер */
  groupsManager?: Maybe<Array<FacilityGroup>>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  lastLoginAt?: Maybe<Scalars['Date']['output']>;
  /** Фамилия */
  lastname: Scalars['String']['output'];
  /** Лид */
  lead?: Maybe<Lead>;
  legalEntities: Array<LegalEntity>;
  manualAccrualItems: Array<ManualAccrualItem>;
  manualUserAccruals: Array<ManualUserAccrual>;
  marks?: Maybe<Array<Mark>>;
  /** Документы пользователя на рассмотрении */
  myDocumentsReviews: Array<DocumentReview>;
  notifications: Array<Notification>;
  /** Отчество */
  patronymic?: Maybe<Scalars['String']['output']>;
  /** Телефон */
  phone: Scalars['String']['output'];
  /** Id должности */
  positionId?: Maybe<Scalars['String']['output']>;
  professions: Array<Profession>;
  /** Анкета */
  questionnaire?: Maybe<Questionnaire>;
  referrals?: Maybe<Array<User>>;
  referrer?: Maybe<User>;
  referrerId?: Maybe<Scalars['String']['output']>;
  /** Состояние регистрации пользователя в Наймикс */
  registrationState?: Maybe<UserRegistrationState>;
  reports: Array<Report>;
  requestShiftSuggestions: Array<RequestShiftSuggestionEntity>;
  /** Реквизиты пользователя (отельная модель) */
  requisite?: Maybe<Requisite>;
  respondedVacancies?: Maybe<Array<Vacancy>>;
  responsiveness: Scalars['Int']['output'];
  /** Роль */
  role: Role;
  schedule?: Maybe<Array<Schedule>>;
  shiftForemen: Array<ShiftForeman>;
  shiftPenalties: Array<ShiftPenalty>;
  /** Смены пользователя */
  shifts?: Maybe<Array<Shift>>;
  speed: Scalars['Int']['output'];
  /** подписка на объекты, о желании на них работать */
  subscribedFacility?: Maybe<Array<Facility>>;
  subscribers: Array<Subscribers>;
  supervisors: Array<Supervisors>;
  temporaryShifts: Array<TemporaryShift>;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  userActivityLogs: Array<UserActivityLog>;
  /** Автоматический рейтинг пользователя */
  userAutoRating?: Maybe<UserAutoRating>;
  userDevice: UserDevice;
  /** История опыта работника */
  userExperience?: Maybe<Array<UserExperience>>;
  /** История опыта работника */
  userGrade?: Maybe<Array<UserGrade>>;
  userGradeAuthor?: Maybe<Array<UserGrade>>;
  /** История опыта работника */
  userReview?: Maybe<Array<UserReview>>;
  userReviewAuthor: Array<UserReview>;
  /** Рабочие места пользователя */
  workposts?: Maybe<Array<WorkPost>>;
};

export type ReadAllCommentsInShiftInput = {
  /** Идентификатор смены, где необходимо пометить комментарии как прочитанные */
  shiftId: Scalars['String']['input'];
};

export type ReferralProgramEntity = {
  __typename?: 'ReferralProgramEntity';
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Описание реферальной программы */
  description?: Maybe<Scalars['String']['output']>;
  /** Дата и время завершения */
  endDatetime?: Maybe<Scalars['Date']['output']>;
  /** Объект привязанный к реферальной программе */
  facility?: Maybe<Facility>;
  /** Группа объектов привязанная к реферальной программе */
  facilityGroup?: Maybe<FacilityGroup>;
  /** Идентификатор группы объектов */
  facilityGroupId?: Maybe<Scalars['String']['output']>;
  /** Идентификатор объекта */
  facilityId?: Maybe<Scalars['String']['output']>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Название реферальной программы */
  name?: Maybe<Scalars['String']['output']>;
  /** Пуш уведомления привязанные к бонусной программе */
  notifyLetters: Array<NotifyLetter>;
  /** Количество хорошо отработанных смен */
  numberOfGoodShifts?: Maybe<Scalars['Int']['output']>;
  /** Вознаграждение приглашающего */
  rewardForTheReferer?: Maybe<Scalars['Int']['output']>;
  /** Дата и время начала */
  startDatetime?: Maybe<Scalars['Date']['output']>;
  /** Текущее состояние */
  status: ReferralProgramStatusEnum;
  /** Истории привязанные к бонусной программе */
  stories: Array<Story>;
  /** Дата и время обновления */
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ReferralProgramModel = {
  __typename?: 'ReferralProgramModel';
  /** Текущей реферальная программа */
  currentReferralProgram: ReferralProgramEntity;
  /** Следующая реферальная программа */
  nextReferralProgram?: Maybe<ReferralProgramEntity>;
  /** Реферальная программа */
  referralProgram: ReferralProgramEntity;
};

export enum ReferralProgramRoleEnum {
  Referral = 'REFERRAL',
  Referrer = 'REFERRER'
}

/** Статус реферальной программы */
export enum ReferralProgramStatusEnum {
  /** Активно */
  Active = 'ACTIVE',
  /** Архив */
  Archive = 'ARCHIVE',
  /** Черновик */
  Draft = 'DRAFT',
  /** Запланировано */
  Planned = 'PLANNED'
}

/** Статус подписания АД и согласия на обработку ПД в Наймикс */
export enum RegistrationInNaimixStateEnum {
  FullRegistration = 'FULL_REGISTRATION',
  IncompleteRegistration = 'INCOMPLETE_REGISTRATION',
  NotChecked = 'NOT_CHECKED',
  NotRegistered = 'NOT_REGISTERED'
}

/** Причина отклонения документов */
export enum RejectReason {
  DocumentExpired = 'DOCUMENT_EXPIRED',
  IncorrectData = 'INCORRECT_DATA',
  None = 'NONE',
  NoFace = 'NO_FACE',
  PoorQuality = 'POOR_QUALITY',
  SelfieDoesntMatch = 'SELFIE_DOESNT_MATCH'
}

export type RemoveStatusFreeRequestShiftInput = {
  /** Идентификатор смены в заявке */
  requestShiftId: Scalars['String']['input'];
};

export type Report = {
  __typename?: 'Report';
  author: User;
  authorId: Scalars['String']['output'];
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  filePath: Scalars['String']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  type: ReportType;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
};

export enum ReportType {
  Daily = 'DAILY',
  EverythingIsPossibleBonus = 'EVERYTHING_IS_POSSIBLE_BONUS',
  Polls = 'POLLS',
  Sms = 'SMS',
  Timesheet = 'TIMESHEET'
}

export type RequestShiftSuggestionEntity = {
  __typename?: 'RequestShiftSuggestionEntity';
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  requestShift: ZayavkaShift;
  requestShiftId: Scalars['ID']['output'];
  status: ZayavkaShiftSuggestionsStatusEnum;
  /** Дата и время обновления */
  updatedAt?: Maybe<Scalars['Date']['output']>;
  /** Пользователь */
  user: User;
  userId: Scalars['ID']['output'];
};

export type Requisite = {
  __typename?: 'Requisite';
  /** Номер счёта */
  accountNumber?: Maybe<Scalars['String']['output']>;
  arrivalNoticeRegistrationDate?: Maybe<Scalars['String']['output']>;
  arrivalNoticeResidenceAddress?: Maybe<Scalars['String']['output']>;
  arrivalNoticeValidToDate?: Maybe<Scalars['String']['output']>;
  /** Название банка */
  bankName?: Maybe<Scalars['String']['output']>;
  /** БИК */
  bik?: Maybe<Scalars['String']['output']>;
  /** Дата рождения */
  birthDate?: Maybe<Scalars['String']['output']>;
  /** Место рождения */
  birthPlace?: Maybe<Scalars['String']['output']>;
  /** Срок действия карты */
  cardExpiryDate?: Maybe<Scalars['Date']['output']>;
  /** Имя держателя карты */
  cardName?: Maybe<Scalars['String']['output']>;
  /** Номер карты */
  cardNumber?: Maybe<Scalars['String']['output']>;
  /** Номер корр.счёта */
  correspondentAccount?: Maybe<Scalars['String']['output']>;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  identificationNumber?: Maybe<Scalars['String']['output']>;
  /** ИНН */
  inn?: Maybe<Scalars['String']['output']>;
  /** Даты конца аттестации строка даты YYYY-MM-DD */
  medicalAttestationEnd?: Maybe<Scalars['String']['output']>;
  /** Даты начала аттестации строка даты YYYY-MM-DD */
  medicalAttestationStart?: Maybe<Scalars['String']['output']>;
  /** Ожидаемая дата выдачи медицинской книжки после получения справки */
  medicalExpectedReceiptDate?: Maybe<Scalars['Date']['output']>;
  /** Дата выдачи медицинской справки (о прохождении осмотра перед получением медицинской книжки) */
  medicalInvoiceIssueDate?: Maybe<Scalars['Date']['output']>;
  /** Номер мед.книжки */
  medicalNumber?: Maybe<Scalars['String']['output']>;
  migrationCardIssueDate?: Maybe<Scalars['String']['output']>;
  migrationCardNumber?: Maybe<Scalars['String']['output']>;
  migrationCardSeries?: Maybe<Scalars['String']['output']>;
  migrationCardValidToDate?: Maybe<Scalars['String']['output']>;
  /** Миграционный статус */
  migrationStatus?: Maybe<MigrationStatusNaimixEnum>;
  /** Кем выдан паспорт */
  passportIssuedBy?: Maybe<Scalars['String']['output']>;
  /** Дата выдачи паспорта */
  passportIssuedDate?: Maybe<Scalars['String']['output']>;
  /** Код подразделения */
  passportIssuerCode?: Maybe<Scalars['String']['output']>;
  /** Номер паспорта */
  passportNumber?: Maybe<Scalars['String']['output']>;
  /** Адрес регистрации */
  passportRegistrationAddress?: Maybe<Scalars['String']['output']>;
  /** Серия паспорта */
  passportSeries?: Maybe<Scalars['String']['output']>;
  /** Дата окончания срока действия паспорта */
  passportValidToDate?: Maybe<Scalars['String']['output']>;
  /** Статус самозанятого */
  selfEmployed?: Maybe<SelfEmployedStatus>;
  /** Дата самозанятости */
  selfEmployedDate?: Maybe<Scalars['Date']['output']>;
  /** Пол */
  sex?: Maybe<Sex>;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  /** Дата обновления блока мед.книжка */
  updatedAtMedical?: Maybe<Scalars['Date']['output']>;
  /** Дата обновления блока паспортные данные */
  updatedAtPassport?: Maybe<Scalars['Date']['output']>;
  /** Дата обновления блока платежная информация */
  updatedAtPayment?: Maybe<Scalars['Date']['output']>;
  /** Дата обновления блока с персональной информацией */
  updatedAtPersonal?: Maybe<Scalars['Date']['output']>;
  /** Дата обновления блока регистрации */
  updatedAtRegistration?: Maybe<Scalars['Date']['output']>;
  /** Дата обновления блока налоговые реквизиты */
  updatedAtTax?: Maybe<Scalars['Date']['output']>;
  /** работник */
  user?: Maybe<User>;
  /** Id работника */
  userId: Scalars['String']['output'];
};

export type RequisiteInput = {
  inn?: InputMaybe<Scalars['String']['input']>;
  selfEmployed?: InputMaybe<SelfEmployedStatus>;
  selfEmployedDate?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
};

export type ResetToDefaultPromotionSettingsInput = {
  /** Идентификатор бонусной программы */
  promotionId: Scalars['ID']['input'];
};

export type RestoreWorkerPositionInput = {
  /** ID объекта */
  facilityId: Scalars['String']['input'];
  /** ID профессии */
  positionId: Scalars['String']['input'];
  /** ID исполнителя */
  userId: Scalars['String']['input'];
};

/** Тип документа на проверку */
export enum ReviewType {
  Inn = 'INN',
  MainPage = 'MAIN_PAGE',
  MedicalInvoice = 'MEDICAL_INVOICE',
  Page2_3 = 'PAGE2_3',
  Page28_29 = 'PAGE28_29',
  PaymentInfo = 'PAYMENT_INFO',
  Person = 'PERSON',
  Registration = 'REGISTRATION',
  Selfie = 'SELFIE'
}

export type RichUser = {
  __typename?: 'RichUser';
  /** Объекты, на которых пользователь является Бригадиром */
  Facility_FOREMAN?: Maybe<Array<Facility>>;
  /** Объекты в которых пользователь Супервайзер */
  Facility_SUPERVISOR?: Maybe<Array<Facility>>;
  /** Документы, которые пользователь рассматривает */
  allDocumentsReviews: Array<DocumentReview>;
  /** Разрешить работать без Наймикс */
  allowWorkWithoutNaimix: Scalars['Boolean']['output'];
  /** Гражданство */
  citizenship?: Maybe<Country>;
  citizenshipId?: Maybe<Scalars['String']['output']>;
  /** Город */
  city?: Maybe<City>;
  /** Id города */
  cityId?: Maybe<Scalars['String']['output']>;
  /** Комментарии к сменам */
  comments: Array<CommentEntity>;
  contactPhones?: Maybe<Array<ContactPhone>>;
  /** Контракты пользователя */
  contracts?: Maybe<Array<UserContract>>;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  currentVersion?: Maybe<Scalars['String']['output']>;
  deletedAt: Scalars['Date']['output'];
  /** Файлы документов загруженные пользователю */
  documents: Array<Document>;
  /** Статус проверки документов */
  documentsStatus: DocumentsStatus;
  /** Email address */
  email?: Maybe<Scalars['String']['output']>;
  /** Id пользователя в ERP */
  externalId?: Maybe<Scalars['String']['output']>;
  /** Объекты в которых пользователь Менеджер */
  facilitiesManager?: Maybe<Array<Facility>>;
  facilityToUsers: Array<FacilityToUser>;
  /** Имя */
  firstname: Scalars['String']['output'];
  /** Количество хороших смен пользователя */
  goodShiftCount?: Maybe<Scalars['Int']['output']>;
  groupManagers: Array<GroupManager>;
  /** Группы в которых пользователь Директор */
  groupsDirector?: Maybe<Array<FacilityGroup>>;
  /** Группы в которых пользователь назначен как Менеджер */
  groupsManager?: Maybe<Array<FacilityGroup>>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  lastLoginAt?: Maybe<Scalars['Date']['output']>;
  /** Фамилия */
  lastname: Scalars['String']['output'];
  /** Лид */
  lead?: Maybe<Lead>;
  legalEntities: Array<LegalEntity>;
  manualAccrualItems: Array<ManualAccrualItem>;
  manualUserAccruals: Array<ManualUserAccrual>;
  marks?: Maybe<Array<Mark>>;
  /** Максимальная версия */
  maxVersion?: Maybe<Scalars['String']['output']>;
  /** Документы пользователя на рассмотрении */
  myDocumentsReviews: Array<DocumentReview>;
  notifications: Array<Notification>;
  /** Отчество */
  patronymic?: Maybe<Scalars['String']['output']>;
  /** Телефон */
  phone: Scalars['String']['output'];
  /** Id должности */
  positionId?: Maybe<Scalars['String']['output']>;
  professions: Array<Profession>;
  /** Анкета */
  questionnaire?: Maybe<Questionnaire>;
  /** Список ID профессий указанных работником в анкете */
  questionnairePositionIds?: Maybe<Array<Scalars['String']['output']>>;
  referrals?: Maybe<Array<User>>;
  referrer?: Maybe<User>;
  referrerId?: Maybe<Scalars['String']['output']>;
  /** Состояние регистрации пользователя в Наймикс */
  registrationState?: Maybe<UserRegistrationState>;
  reports: Array<Report>;
  requestShiftSuggestions: Array<RequestShiftSuggestionEntity>;
  /** Реквизиты пользователя (отельная модель) */
  requisite?: Maybe<Requisite>;
  respondedVacancies?: Maybe<Array<Vacancy>>;
  /** Роль */
  role: Role;
  schedule?: Maybe<Array<Schedule>>;
  shiftForemen: Array<ShiftForeman>;
  shiftPenalties: Array<ShiftPenalty>;
  /** Смены пользователя */
  shifts?: Maybe<Array<Shift>>;
  /** подписка на объекты, о желании на них работать */
  subscribedFacility?: Maybe<Array<Facility>>;
  subscribers: Array<Subscribers>;
  supervisors: Array<Supervisors>;
  temporaryShifts: Array<TemporaryShift>;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  userActivityLogs: Array<UserActivityLog>;
  /** Автоматический рейтинг пользователя */
  userAutoRating?: Maybe<UserAutoRating>;
  userDevice: UserDevice;
  /** История опыта работника */
  userExperience?: Maybe<Array<UserExperience>>;
  /** История опыта работника */
  userGrade?: Maybe<Array<UserGrade>>;
  userGradeAuthor?: Maybe<Array<UserGrade>>;
  /** История опыта работника */
  userReview?: Maybe<Array<UserReview>>;
  userReviewAuthor: Array<UserReview>;
  /** Рабочие места пользователя */
  workposts?: Maybe<Array<WorkPost>>;
};

/** Роль пользователя */
export enum Role {
  Admin = 'ADMIN',
  ClientDirector = 'CLIENT_DIRECTOR',
  ClientManager = 'CLIENT_MANAGER',
  Foreman = 'FOREMAN',
  Newbie = 'NEWBIE',
  Supervisor = 'SUPERVISOR',
  Worker = 'WORKER'
}

export enum ScanTypeNaimixEnum {
  /** Скан уведомления о прибытии, первая сторона */
  ArrivalNotificationFirstSide = 'ARRIVAL_NOTIFICATION_FIRST_SIDE',
  /** Скан уведомления о прибытии, вторая сторона */
  ArrivalNotificationSecondSide = 'ARRIVAL_NOTIFICATION_SECOND_SIDE',
  /** Скан заграничного паспорта, место рождения */
  ForeignPassportBirthPlace = 'FOREIGN_PASSPORT_BIRTH_PLACE',
  /** Скан заграничного паспорта, персональная информация */
  ForeignPassportPersonalInformation = 'FOREIGN_PASSPORT_PERSONAL_INFORMATION',
  /** Скан миграционной карты, лицевая сторона */
  MigrationCard = 'MIGRATION_CARD',
  /** Скан миграционной карты, обратная сторона */
  MigrationCardBackSide = 'MIGRATION_CARD_BACK_SIDE',
  /** Селфи с паспортом */
  PassportSelfie = 'PASSPORT_SELFIE',
  /** Фотография нотариального заверения перевода паспорта */
  PassportTranslationNotarizationScan = 'PASSPORT_TRANSLATION_NOTARIZATION_SCAN',
  /** Фотография перевода паспорта на русский язык */
  PassportTranslationScan = 'PASSPORT_TRANSLATION_SCAN',
  /** Скан паспорта РФ, персональная информация */
  RfPassportPersonalInformation = 'RF_PASSPORT_PERSONAL_INFORMATION',
  /** Скан паспорта РФ, регистрация */
  RfPassportRegistration = 'RF_PASSPORT_REGISTRATION'
}

export type Schedule = {
  __typename?: 'Schedule';
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Дата и время подтверждения графика */
  dateAccept?: Maybe<Scalars['Date']['output']>;
  /** Дата и время начала графика */
  dateFrom?: Maybe<Scalars['Date']['output']>;
  /** Дата и время окончания графика */
  dateTo?: Maybe<Scalars['Date']['output']>;
  /** Тип графика: 1 - 2/2; 2 - 4/2; 3 - 5/2; 4 - 6/1; 5 - 7/0 (рабочих дней / выходные) */
  dayInterleaving?: Maybe<Scalars['Int']['output']>;
  /** Объект */
  facility?: Maybe<Facility>;
  /** Id объекта */
  facilityId: Scalars['String']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** График принят */
  isAccept: Scalars['Boolean']['output'];
  /** Кастомный график */
  isCustom: Scalars['Boolean']['output'];
  isDeleted: Scalars['Boolean']['output'];
  /** Длительность обеда в графике */
  lunchDuration?: Maybe<Scalars['Int']['output']>;
  /** Должность */
  position?: Maybe<Position>;
  /** Id должности */
  positionId?: Maybe<Scalars['String']['output']>;
  /** Дата окончания стажировки в формате YYYY-MM-DD */
  probationEnd?: Maybe<Scalars['String']['output']>;
  /** Дата начала стажировки в формате YYYY-MM-DD */
  probationStart?: Maybe<Scalars['String']['output']>;
  /** Тип графика */
  scheduleType?: Maybe<ScheduleType>;
  /** Идентификатор типа графика */
  scheduleTypeId?: Maybe<Scalars['String']['output']>;
  /** Длительность смены в графике, необязательное */
  shiftDuration?: Maybe<Scalars['Int']['output']>;
  /** Смены */
  shifts?: Maybe<Array<Shift>>;
  /** Новый статус графика */
  stage: ScheduleStage;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  /** Пользователь */
  user: User;
  /** Id пользователя */
  userId: Scalars['String']['output'];
  /** Связь графика с заявкой */
  zayavkaSchedule?: Maybe<ZayavkaSchedule>;
  /** ID связи с заявкой */
  zayavkaScheduleId?: Maybe<Scalars['String']['output']>;
};

/** Schedule NEW statuses */
export enum ScheduleStage {
  Accepted = 'ACCEPTED',
  Ineligible = 'INELIGIBLE',
  New = 'NEW',
  Refused = 'REFUSED'
}

export type ScheduleSubOutput = {
  __typename?: 'ScheduleSubOutput';
  /** Объект графика */
  schedule: Schedule;
  /** Смены которые были изменены при  */
  shifts?: Maybe<Array<Shift>>;
  /** Id бригадира */
  userId: Scalars['String']['output'];
};

export type ScheduleType = {
  __typename?: 'ScheduleType';
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  description: Scalars['String']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  offDays: Scalars['Int']['output'];
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  workDays: Scalars['Int']['output'];
  zayavkaSchedules: Array<ZayavkaSchedule>;
};

/** Фильтр для поиска договоров */
export type SearchContractsInput = {
  /** Дата создания договора */
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Идентификатор группы объектов */
  facilityGroupId?: InputMaybe<Scalars['String']['input']>;
  /** Идентификатор объекта */
  facilityId?: InputMaybe<Scalars['String']['input']>;
  /** Номер страницы */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Строка поиска */
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  /** Количество элементов на странице */
  size?: InputMaybe<Scalars['Int']['input']>;
  /** Поля сортировки списка договоров */
  sortBy?: InputMaybe<UserContractSorting>;
  /** Направление сортировки */
  sortWay?: InputMaybe<SortingWay>;
  /** Статус договора */
  status?: InputMaybe<UserContractStatus>;
  /** Тип договора */
  type?: InputMaybe<UserContractType>;
};

export type SearchVacanciesFilterInput = {
  /** ID группы объектов */
  facilityGroupId?: InputMaybe<Scalars['String']['input']>;
  /** ID объекта */
  facilityId?: InputMaybe<Scalars['String']['input']>;
  /** Флаг выводить ли объекты без вакансий */
  hasVacancies?: InputMaybe<Scalars['Boolean']['input']>;
  /** Широта */
  latitude?: InputMaybe<Scalars['Float']['input']>;
  /** Долгота */
  longitude?: InputMaybe<Scalars['Float']['input']>;
  /** Список идентификаторов станций метро */
  metroStationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** ID профессии */
  positionId?: InputMaybe<Scalars['String']['input']>;
  /** Массив ID профессий */
  positionsIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Радиус поиска в километрах (20км по умолчанию) */
  radius?: InputMaybe<Scalars['Int']['input']>;
  /** Название населенного пункта */
  settlement?: InputMaybe<Scalars['String']['input']>;
};

export type SearchVacanciesMobileFilterInput = {
  /** Широта */
  latitude?: InputMaybe<Scalars['Float']['input']>;
  /** Долгота */
  longitude?: InputMaybe<Scalars['Float']['input']>;
  /** Список идентификаторов станций метро */
  metroStationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Массив ID профессий */
  positionsIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Радиус поиска в километрах (20км по умолчанию) */
  radius?: InputMaybe<Scalars['Int']['input']>;
  /** Массив ID типов графика */
  scheduleTypeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Населенный пункт */
  settlement?: InputMaybe<Scalars['String']['input']>;
  /** Длительность смены */
  shiftDuration?: InputMaybe<Scalars['Int']['input']>;
};

/** Self-employed status */
export enum SelfEmployedStatus {
  Accepted = 'ACCEPTED',
  None = 'NONE',
  Pending = 'PENDING',
  Wish = 'WISH'
}

export type SendACommentToTheShiftInput = {
  /** Текст сообщения к смене на модерацию */
  message: Scalars['String']['input'];
  /** Идентификатор смены */
  shiftId: Scalars['String']['input'];
};

export type SendFreeShiftByRequestShiftIdInput = {
  /** Дата */
  date?: InputMaybe<Scalars['Date']['input']>;
  /** Идентификатор объекта */
  facilityId: Scalars['ID']['input'];
  /** Идентификатор смены заявки */
  requestShiftId: Scalars['ID']['input'];
  /** Сортировка по статусам заявки */
  show?: InputMaybe<ZayavkaByFacilityDisplay>;
};

export type SendFreeShiftsByRequestIdsInput = {
  /** Дата */
  date?: InputMaybe<Scalars['Date']['input']>;
  /** Идентификатор объекта */
  facilityId: Scalars['ID']['input'];
  requestIds: Array<Scalars['ID']['input']>;
  /** Сортировка по статусам заявки */
  show?: InputMaybe<ZayavkaByFacilityDisplay>;
};

export type SendFreeShiftsByRequestScheduleIdInput = {
  /** Дата */
  date?: InputMaybe<Scalars['Date']['input']>;
  /** Идентификатор объекта */
  facilityId: Scalars['ID']['input'];
  /** Идентификатор графика заявки */
  requestScheduleId: Scalars['ID']['input'];
  /** Сортировка по статусам заявки */
  show?: InputMaybe<ZayavkaByFacilityDisplay>;
};

export type SendUserDataToNaimixArrivalNoticeInput = {
  arrivalNoticeRegistrationDate: Scalars['String']['input'];
  arrivalNoticeResidenceAddress: Scalars['String']['input'];
  arrivalNoticeValidToDate: Scalars['String']['input'];
};

export type SendUserDataToNaimixInput = {
  arrivalNotice?: InputMaybe<SendUserDataToNaimixArrivalNoticeInput>;
  birthDate: Scalars['String']['input'];
  birthPlace: Scalars['String']['input'];
  citizenshipId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gender: Sex;
  inn: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  migrationCardInfo?: InputMaybe<SendUserDataToNaimixMigrationCardInfoInput>;
  migrationStatus: MigrationStatusNaimixEnum;
  passport: SendUserDataToNaimixPassportInput;
  patronymic?: InputMaybe<Scalars['String']['input']>;
  registrationAddress?: InputMaybe<Scalars['String']['input']>;
};

export type SendUserDataToNaimixMigrationCardInfoInput = {
  migrationCardIssueDate: Scalars['String']['input'];
  migrationCardNumber: Scalars['String']['input'];
  migrationCardSeries: Scalars['String']['input'];
  migrationCardValidToDate?: InputMaybe<Scalars['String']['input']>;
};

export type SendUserDataToNaimixPassportInput = {
  identificationNumber?: InputMaybe<Scalars['String']['input']>;
  passportIssueDate: Scalars['String']['input'];
  passportIssuedBy: Scalars['String']['input'];
  passportIssuerCode?: InputMaybe<Scalars['String']['input']>;
  passportNumber: Scalars['String']['input'];
  passportSeries?: InputMaybe<Scalars['String']['input']>;
  passportValidToDate?: InputMaybe<Scalars['String']['input']>;
};

export type SetCityForUserInput = {
  /** dadata fias id */
  fiasId: Scalars['String']['input'];
  /** Широта */
  latitude: Scalars['String']['input'];
  /** Долгота */
  longitude: Scalars['String']['input'];
  /** Населенный пункт */
  settlement: Scalars['String']['input'];
};

export type SetReferralProgramPaymentDatetimeInput = {
  leadId: Scalars['String']['input'];
  setFor: ReferralProgramRoleEnum;
};

export type SetScheduleOutput = {
  __typename?: 'SetScheduleOutput';
  /** Смены которые были изменены при  */
  intersectShifts?: Maybe<Array<Shift>>;
  /** Объект графика */
  schedule: Schedule;
};

export type SetStatusModerationInput = {
  /** Id Табелей модервации */
  reportIds: Array<Scalars['String']['input']>;
  /** Статус модерации */
  status?: ModerationStatus;
};

export type SetUserTokenInput = {
  androidToken?: InputMaybe<Scalars['String']['input']>;
  browserToken?: InputMaybe<Scalars['String']['input']>;
  huaweiToken?: InputMaybe<Scalars['String']['input']>;
  iosToken?: InputMaybe<Scalars['String']['input']>;
};

export type Setting = {
  __typename?: 'Setting';
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Имя */
  name: Scalars['String']['output'];
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  /** Значение */
  value: Scalars['String']['output'];
};

/** Пол */
export enum Sex {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type Shift = {
  __typename?: 'Shift';
  /** Id автора */
  authorId: Scalars['String']['output'];
  /** комментарий к смене */
  comment?: Maybe<Scalars['String']['output']>;
  /** Комментарии бригадиров к смене */
  comments: Array<CommentEntity>;
  /** Размер оплаты по смене */
  cost: Scalars['Float']['output'];
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Дата и время конца */
  dateEnd: Scalars['Date']['output'];
  /** Дата и время начала */
  dateFrom: Scalars['Date']['output'];
  deletedDate: Scalars['Date']['output'];
  /** Длительность смены в минутах */
  duration: Scalars['String']['output'];
  empty: Scalars['Boolean']['output'];
  /** Объект */
  facility?: Maybe<Facility>;
  facilityId: Scalars['String']['output'];
  /** размер оплаты в час */
  hourRate?: Maybe<Scalars['Float']['output']>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  /** Является ли смена стажировочной */
  isProbation: Scalars['Boolean']['output'];
  /** длительность обеда в минутах */
  lunchDuration?: Maybe<Scalars['Int']['output']>;
  /** Чекины/Чекауты */
  marks: Array<Mark>;
  /** Объект модерации */
  moderation?: Maybe<Moderation>;
  /** Тип выплаты за работу */
  payout: PayoutType;
  /** Штраф на смене */
  penalties?: Maybe<Array<ShiftPenalty>>;
  /** Объект должности */
  position?: Maybe<Position>;
  /** Id должности на смене */
  positionId: Scalars['String']['output'];
  /** Ставка за смену на период стажировки */
  probationRate?: Maybe<Scalars['Float']['output']>;
  rejectedById?: Maybe<Scalars['String']['output']>;
  /** График */
  schedule?: Maybe<Schedule>;
  /** Id графика */
  scheduleId?: Maybe<Scalars['String']['output']>;
  selfEmployedRate?: Maybe<Scalars['Int']['output']>;
  shiftForemen: Array<ShiftForeman>;
  stage: ShiftStage;
  status: ShiftStatus;
  /** Единицы измерения для оплаты */
  unit: UnitType;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  /** Пользователь */
  user?: Maybe<User>;
  userId: Scalars['String']['output'];
  /** Значение для смен по сдельной оплате */
  value?: Maybe<Scalars['Float']['output']>;
  /** Заявка, если смена относится к Заявке */
  zayavka?: Maybe<Zayavka>;
  /** Id заявки если смена привязана к заявке */
  zayavkaId?: Maybe<Scalars['String']['output']>;
  zayavkaShifts: Array<ZayavkaShift>;
};

/** модель смены дополненная данными факт. и факт. */
export type ShiftFact = {
  __typename?: 'ShiftFact';
  /** Id автора */
  authorId: Scalars['String']['output'];
  /** комментарий к смене */
  comment?: Maybe<Scalars['String']['output']>;
  /** Комментарии бригадиров к смене */
  comments: Array<CommentEntity>;
  /** Размер оплаты по смене */
  cost: Scalars['Float']['output'];
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Дата и время конца */
  dateEnd: Scalars['Date']['output'];
  /** Дата и время начала */
  dateFrom: Scalars['Date']['output'];
  deletedDate: Scalars['Date']['output'];
  /** Длительность смены в минутах */
  duration: Scalars['String']['output'];
  /** Фактическая! Длительность смены в минутах по отметкам чекина и чекаута */
  durationFact: Scalars['Float']['output'];
  /** Плановая! Длительность смены в минутах с учетом вычета обеда */
  durationPlan: Scalars['Float']['output'];
  empty: Scalars['Boolean']['output'];
  /** Объект */
  facility?: Maybe<Facility>;
  facilityId: Scalars['String']['output'];
  finalBonus: Scalars['Int']['output'];
  /** размер оплаты в час */
  hourRate?: Maybe<Scalars['Float']['output']>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  /** Является ли смена стажировочной */
  isProbation: Scalars['Boolean']['output'];
  /** длительность обеда в минутах */
  lunchDuration?: Maybe<Scalars['Int']['output']>;
  /** Чекины/Чекауты */
  marks: Array<Mark>;
  /** Объект модерации */
  moderation?: Maybe<Moderation>;
  /** Число непрочитанных комментариев */
  newCommentsNum: Scalars['Int']['output'];
  /** Тип выплаты за работу */
  payout: PayoutType;
  /** Штраф на смене */
  penalties?: Maybe<Array<ShiftPenalty>>;
  /** Объект должности */
  position?: Maybe<Position>;
  /** Id должности на смене */
  positionId: Scalars['String']['output'];
  /** Ставка за смену на период стажировки */
  probationRate?: Maybe<Scalars['Float']['output']>;
  promotionResults?: Maybe<Array<PromotionResult>>;
  rejectedById?: Maybe<Scalars['String']['output']>;
  /** График */
  schedule?: Maybe<Schedule>;
  /** Id графика */
  scheduleId?: Maybe<Scalars['String']['output']>;
  selfEmployedRate?: Maybe<Scalars['Int']['output']>;
  shiftForemen: Array<ShiftForeman>;
  stage: ShiftStage;
  status: ShiftStatus;
  /** Единицы измерения для оплаты */
  unit: UnitType;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  /** Пользователь */
  user?: Maybe<User>;
  userId: Scalars['String']['output'];
  /** Значение для смен по сдельной оплате */
  value?: Maybe<Scalars['Float']['output']>;
  /** Плановая! Длительность смены в минутах с учетом вычета обеда */
  valuePlan?: Maybe<Scalars['Float']['output']>;
  /** Заявка, если смена относится к Заявке */
  zayavka?: Maybe<Zayavka>;
  /** Id заявки если смена привязана к заявке */
  zayavkaId?: Maybe<Scalars['String']['output']>;
  zayavkaShifts: Array<ZayavkaShift>;
};

/** Связь пользователя и объекта на котором он может работать */
export type ShiftForeman = {
  __typename?: 'ShiftForeman';
  /** Дата и время назначения пользователя */
  assignedAt: Scalars['Date']['output'];
  /** id, пользователя, кем смена отнесена под этого бригадира */
  assignedBy?: Maybe<Scalars['String']['output']>;
  /** Смена подчиненного бригадира */
  shift?: Maybe<Shift>;
  shiftId: Scalars['String']['output'];
  /** Дата и время изменения назначения пользователя */
  updatedAt: Scalars['Date']['output'];
  /** Бригадир назначенный к смене */
  user?: Maybe<User>;
  userId: Scalars['String']['output'];
};

/** Объект доставки бригадиру изменений смены по подписке */
export type ShiftForemanSubOutput = {
  __typename?: 'ShiftForemanSubOutput';
  /** Дата и время создания объекта */
  createdAt: Scalars['Date']['output'];
  /** Уникальный идентификатор объекта */
  id: Scalars['ID']['output'];
  /** Смена которую надо доставить бригадиру */
  shift: Shift;
  /** Дата и время обновления объекта */
  updatedAt: Scalars['Date']['output'];
  /** Id бригадира */
  userId: Scalars['String']['output'];
};

export type ShiftPenalty = {
  __typename?: 'ShiftPenalty';
  authorId?: Maybe<Scalars['String']['output']>;
  /** Комментарий штрафа */
  cause?: Maybe<Scalars['String']['output']>;
  /** Комментарий штрафа */
  comment?: Maybe<Scalars['String']['output']>;
  /** Стоимость штрафа (в руб.) */
  cost: Scalars['Float']['output'];
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Штраф */
  penalty?: Maybe<Penalty>;
  /** Id штрафа */
  penaltyId: Scalars['String']['output'];
  /** Смена */
  shift: Shift;
  /** Id смены */
  shiftId: Scalars['String']['output'];
  /** Статус штрафа */
  status: ShiftPenaltyStatus;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  /** Пользователь, которого штрафуют */
  user: User;
  /** Id пользователя */
  userId: Scalars['String']['output'];
};

/** Статус штрафа */
export enum ShiftPenaltyStatus {
  Approved = 'APPROVED',
  New = 'NEW',
  Rejected = 'REJECTED'
}

/** Shift NEW statuses */
export enum ShiftStage {
  Accepted = 'ACCEPTED',
  Finished = 'FINISHED',
  Ineligible = 'INELIGIBLE',
  New = 'NEW',
  Refused = 'REFUSED',
  Wasted = 'WASTED',
  Working = 'WORKING'
}

/** Shift OLD status */
export enum ShiftStatus {
  PartAbsenteeism = 'PART_ABSENTEEISM',
  PartAccepted = 'PART_ACCEPTED',
  PartDefault = 'PART_DEFAULT',
  PartRefused = 'PART_REFUSED',
  PartRejected = 'PART_REJECTED',
  ShiftAbsenteeism = 'SHIFT_ABSENTEEISM',
  ShiftDefault = 'SHIFT_DEFAULT',
  ShiftPending = 'SHIFT_PENDING',
  ShiftRefused = 'SHIFT_REFUSED',
  ShiftRejected = 'SHIFT_REJECTED'
}

/** Выбор смены: дневная или Ночная */
export enum ShiftTime {
  Day = 'DAY',
  Night = 'NIGHT',
  Undefined = 'UNDEFINED'
}

export type SignEditUserPaymentInfoInNaimixInput = {
  cardNumber: Scalars['String']['input'];
  smsCode: Scalars['String']['input'];
};

export type SignUserAgencyContractNaimixInput = {
  smsCode: Scalars['String']['input'];
};

export type SignUserFrameContractNaimixInput = {
  smsCode: Scalars['String']['input'];
  userContractId: Scalars['String']['input'];
};

/** DEPRECATED! DTO для создания одного экземпляра смены */
export type SingleShiftInput = {
  /** Дата и время начала смены в формате ISO */
  dateFrom: Scalars['DateTime']['input'];
  duration?: InputMaybe<Scalars['String']['input']>;
  /** id объекта, на который ставится смена */
  facilityId: Scalars['String']['input'];
  /** Длительность обеда в минутах */
  lunchDuration: Scalars['Int']['input'];
  /** id должности на которую ставится смена */
  positionId: Scalars['String']['input'];
  /** Дата и время окончания смены в формате ISO */
  shiftEndDate: Scalars['DateTime']['input'];
  /** id пользователя, на которого создается смена */
  userId: Scalars['String']['input'];
};

/** направление сортировки */
export enum SortingWay {
  Asc = 'asc',
  Desc = 'desc'
}

/** Шаг для фильтрации по периоду */
export enum StatisticPeriod {
  AllTime = 'ALL_TIME',
  Day = 'DAY',
  HalfYear = 'HALF_YEAR',
  Hour = 'HOUR',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

/** Период агрегации метрик статистики */
export enum StatsAggregationPeriod {
  Day = 'DAY',
  Hour = 'HOUR',
  Month = 'MONTH',
  Week = 'WEEK'
}

/** сторис (как в instagram) */
export type Story = {
  __typename?: 'Story';
  /** Id автора  */
  authorId: Scalars['String']['output'];
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Group Id in ERP */
  erpGroupId?: Maybe<Scalars['String']['output']>;
  /** Source Id in ERP */
  erpSourceId?: Maybe<Scalars['String']['output']>;
  /** Информация о миниатюре "истории" */
  icon?: Maybe<File>;
  /** Цвет фона миниатюры */
  iconBackgroundColor: Scalars['String']['output'];
  /** Файл миниатюры */
  iconId?: Maybe<Scalars['String']['output']>;
  /** Заголовок миниатюры */
  iconTitle?: Maybe<Scalars['String']['output']>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Цифровой ID */
  number: Scalars['Int']['output'];
  /** Условия публикации */
  publicCondition?: Maybe<PublicCondition>;
  /** Id условий публикации */
  publicConditionId?: Maybe<Scalars['String']['output']>;
  /** Дата начала публикации */
  publishFrom?: Maybe<Scalars['Date']['output']>;
  /** Текст уведомления */
  pushMessage?: Maybe<Scalars['String']['output']>;
  /** Заголовок уведомления */
  pushTitle?: Maybe<Scalars['String']['output']>;
  /** Реферальная программа, к которой привязана история */
  referralProgram?: Maybe<ReferralProgramEntity>;
  /** Идентификатор реферальной программы, к которой привязана история */
  referralProgramId?: Maybe<Scalars['String']['output']>;
  /** Статус */
  stage?: Maybe<StoryStage>;
  storySlides?: Maybe<Array<StorySlide>>;
  storyViews?: Maybe<Array<StoryView>>;
  /** Название */
  title: Scalars['String']['output'];
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  /** Количество просмотров истории */
  viewsCount: Scalars['Int']['output'];
};

/** поле по которому будет работать сортировка ((publishFrom = Дата начала публикации) */
export enum StoryFieldsSorting {
  PublishFrom = 'publishFrom',
  UpdatedAt = 'updatedAt'
}

export type StoryListInput = {
  /** Номер страницы */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Строка поиска */
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  /** Количество элементов на странице */
  size?: InputMaybe<Scalars['Int']['input']>;
  /** поле по которому сортировка, по умолчанию updatedAt */
  sortBy?: InputMaybe<StoryFieldsSorting>;
  /** Направление сортировки */
  sortWay?: InputMaybe<SortingWay>;
  /** Статус */
  stage?: InputMaybe<StoryStage>;
};

/** DTO для сохранения параметров публикации "Истории" */
export type StoryPublicConditionInput = {
  /** Массив ID городов */
  cities?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Group Id in ERP */
  erpGroupId?: InputMaybe<Scalars['String']['input']>;
  /** Source Id in ERP */
  erpSourceId?: InputMaybe<Scalars['String']['input']>;
  /** Массив ID объектов */
  facilities?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Массив ID групп объектов */
  facilityGroups?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Бессрочность публикации */
  indefinitely?: InputMaybe<Scalars['Boolean']['input']>;
  /** Интервал для повторения рассылки */
  interval?: InputMaybe<Scalars['Int']['input']>;
  /** Массив ID позиций (профессий) */
  positions?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Дата начала публикации */
  publishFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Дата окончания публикации */
  publishTo?: InputMaybe<Scalars['DateTime']['input']>;
  /** Текст уведомления */
  pushMessage?: InputMaybe<Scalars['String']['input']>;
  /** Заголовок уведомления */
  pushTitle?: InputMaybe<Scalars['String']['input']>;
  /** Фильтр по открепленным исполнителям */
  pushToUnassigned: PushToUnassignedFilter;
  /** Массив Ролей */
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
  /** ID "Истории" */
  storyId: Scalars['String']['input'];
  /** Показать группе пользователей да/нет */
  useCondition?: Scalars['Boolean']['input'];
};

/** Слайд "Истории" */
export type StorySlide = {
  __typename?: 'StorySlide';
  /** Id автора  */
  authorId: Scalars['String']['output'];
  /** Цвет фона слайда */
  backgroundColor: Scalars['String']['output'];
  /** Ссылка / Route / Мутация "кнопка 1" */
  buttonOneAction: Scalars['String']['output'];
  /** Текст на "кнопке 1" */
  buttonOneLabel: Scalars['String']['output'];
  /** Тип действия "кнопка 1" */
  buttonOneType?: Maybe<StorySlideButtonType>;
  /** Ссылка / Route / Мутация "кнопка 2" */
  buttonTwoAction: Scalars['String']['output'];
  /** Текст на "кнопке 2" */
  buttonTwoLabel: Scalars['String']['output'];
  /** Тип действия "кнопка 2" */
  buttonTwoType?: Maybe<StorySlideButtonType>;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Информация о миниатюре "Истории" */
  image?: Maybe<File>;
  /** Файл миниатюры */
  imageId?: Maybe<Scalars['String']['output']>;
  /** Порядковый номер в "Истории" */
  index: Scalars['Int']['output'];
  /** Текст слайда */
  message: Scalars['String']['output'];
  /** Лейбл "вопрос 1" */
  questionOne: Scalars['String']['output'];
  /** Лейбл "вопрос 3" */
  questionThree: Scalars['String']['output'];
  /** Лейбл "вопрос 2" */
  questionTwo: Scalars['String']['output'];
  story: Story;
  /** Id "Истории", в которой будет показан слайд */
  storyId: Scalars['String']['output'];
  /** Заголовок слайда */
  title: Scalars['String']['output'];
  /** Статус */
  type?: Maybe<StorySlideType>;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
};

/** Types of Story Slide buttons */
export enum StorySlideButtonType {
  Link = 'LINK',
  Mutation = 'MUTATION',
  Route = 'ROUTE'
}

/** Types of Story Slide */
export enum StorySlideType {
  Button = 'BUTTON',
  Feedback = 'FEEDBACK',
  Image = 'IMAGE',
  TitleText = 'TITLE_TEXT',
  TitleTextButton = 'TITLE_TEXT_BUTTON',
  TitleTextFeedback = 'TITLE_TEXT_FEEDBACK',
  TitleTextTwoButtons = 'TITLE_TEXT_TWO_BUTTONS',
  TwoButtons = 'TWO_BUTTONS'
}

/** Story Life Cycle Statuses */
export enum StoryStage {
  Await = 'AWAIT',
  New = 'NEW',
  Published = 'PUBLISHED',
  Removed = 'REMOVED'
}

/** Таблица просмотра "Истории" */
export type StoryView = {
  __typename?: 'StoryView';
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  story: Story;
  /** Id "Истории" */
  storyId: Scalars['String']['output'];
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  /** Id "Пользователя" */
  userId: Scalars['String']['output'];
};

export type Subscribers = {
  __typename?: 'Subscribers';
  A: Scalars['String']['output'];
  B: Scalars['String']['output'];
  facility: Facility;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  user: User;
};

export type Subscription = {
  __typename?: 'Subscription';
  bonusAndRateSub: BonusAndRate;
  /** Подписка для уведомления бригадиру о том, что работник начал или окончил смену */
  checkMarkForForeman: ForemanShift;
  contactPhoneSub: User;
  /** Подписка на удаление горячей смены */
  deleteTemporaryShift: TemporaryShiftUserId;
  /** Подписка на назначение горячей смены */
  deliveryAssignTemporaryShiftToForeman: TemporaryShiftUserId;
  /** Подписка для обновления лида на экране мои друзья */
  leadOutputSub: LeadOutput;
  /** Подписка для пользователя о том, что у него есть новое уведомление */
  newNotificationSub: Notification;
  newPartSub: Shift;
  /** Подписка на создание горячей смены */
  newTemporaryShift: TemporaryShiftUserId;
  /** "Подписка для обновления прогресса по акции "Двадцать одно" на экране мои друзья" */
  ochkoProgressSub: OchkoProgressOutput;
  /** Подписка о изменениях анкетных данных работника */
  questionnaireSub: Questionnaire;
  /** Подписка для уведомления бригадиру о том, что обновился список профессий */
  refreshForemanProfessions: CasingsForemanSubscriptionOutput;
  refreshRequestsList: ZayavkaProgress;
  refreshVacanciesOnMap: Array<Vacancy>;
  requisiteChangeSub: RatingUser;
  /** Подписка о изменениях графика для доставки бригадиру */
  scheduleForemanSub: ScheduleSubOutput;
  /** Подписка о изменениях графика для доставки работнику */
  scheduleWorkerSub: ScheduleSubOutput;
  /** Подписка о изменениях смены для доставки бригадиру */
  shiftForemanSub: ShiftForemanSubOutput;
  /** Подписка о изменениях смены для доставки работнику */
  shiftPenaltySub: ShiftPenalty;
  /** Подписка о изменениях смены для доставки работнику */
  shiftWorkerSub: Shift;
  /** Подписка об изменении истории */
  storyChangeSub: Story;
  /** Подписка для поднятия истории в списке */
  storyRaiseUpSub: Story;
  updateMobileApp: Scalars['String']['output'];
  /** Подписка о изменениях профессии на объекте для доставки бригадиру */
  workPostForemanSub: WorkPostForemanSubOutput;
  /** Подписка о изменениях графика для доставки работнику */
  workPostWorkerSub: WorkPostWorkerSubOutput;
  zayavkaChangesSub: ZayavkaProgress;
};


export type SubscriptionBonusAndRateSubArgs = {
  userId: Scalars['ID']['input'];
};


export type SubscriptionCheckMarkForForemanArgs = {
  userId: Scalars['ID']['input'];
};


export type SubscriptionContactPhoneSubArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionDeleteTemporaryShiftArgs = {
  userId: Scalars['ID']['input'];
};


export type SubscriptionDeliveryAssignTemporaryShiftToForemanArgs = {
  userId: Scalars['ID']['input'];
};


export type SubscriptionLeadOutputSubArgs = {
  referrerId: Scalars['ID']['input'];
};


export type SubscriptionNewNotificationSubArgs = {
  userId: Scalars['ID']['input'];
};


export type SubscriptionNewPartSubArgs = {
  facilityId: Scalars['ID']['input'];
};


export type SubscriptionNewTemporaryShiftArgs = {
  userId: Scalars['ID']['input'];
};


export type SubscriptionOchkoProgressSubArgs = {
  referrerId: Scalars['ID']['input'];
};


export type SubscriptionQuestionnaireSubArgs = {
  userId: Scalars['ID']['input'];
};


export type SubscriptionRefreshForemanProfessionsArgs = {
  foremanId: Scalars['ID']['input'];
};


export type SubscriptionRequisiteChangeSubArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionScheduleForemanSubArgs = {
  userId: Scalars['ID']['input'];
};


export type SubscriptionScheduleWorkerSubArgs = {
  userId: Scalars['ID']['input'];
};


export type SubscriptionShiftForemanSubArgs = {
  userId: Scalars['ID']['input'];
};


export type SubscriptionShiftPenaltySubArgs = {
  userId: Scalars['ID']['input'];
};


export type SubscriptionShiftWorkerSubArgs = {
  userId: Scalars['ID']['input'];
};


export type SubscriptionStoryChangeSubArgs = {
  userId: Scalars['ID']['input'];
};


export type SubscriptionStoryRaiseUpSubArgs = {
  userId: Scalars['ID']['input'];
};


export type SubscriptionWorkPostForemanSubArgs = {
  userId: Scalars['ID']['input'];
};


export type SubscriptionWorkPostWorkerSubArgs = {
  userId: Scalars['ID']['input'];
};


export type SubscriptionZayavkaChangesSubArgs = {
  id: Scalars['ID']['input'];
};

export type Supervisors = {
  __typename?: 'Supervisors';
  A: Scalars['String']['output'];
  B: Scalars['String']['output'];
  facility: Facility;
  user: User;
};

/** Статус проверки самозанятости в Наймикс */
export enum TaxPartnerInfo {
  Confirmed = 'CONFIRMED',
  NotChecked = 'NOT_CHECKED',
  NotConfirmed = 'NOT_CONFIRMED'
}

export type TemporaryShift = {
  __typename?: 'TemporaryShift';
  /** Id автора смены */
  authorId: Scalars['String']['output'];
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Дата и время конца */
  dateEnd: Scalars['Date']['output'];
  /** Дата начала смены */
  dateFrom: Scalars['Date']['output'];
  /** Объект смены */
  facility: Facility;
  /** Id объекта смены */
  facilityId: Scalars['String']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** длительность обеда в минутах */
  lunchDuration?: Maybe<Scalars['Int']['output']>;
  /** Тип выплаты за работу */
  payout?: Maybe<PayoutType>;
  /** Позиция смены */
  position: Position;
  /** Id позиции смены */
  positionId: Scalars['String']['output'];
  /** Id смены которая назначена работнику */
  shiftId?: Maybe<Scalars['String']['output']>;
  /** Единицы измерения для оплаты */
  unit?: Maybe<UnitType>;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
};

/** Модель для доставки подписок о подработках ("горячих" смен) */
export type TemporaryShiftUserId = {
  __typename?: 'TemporaryShiftUserID';
  /** Смена которую надо доставить бригадиру */
  shift?: Maybe<Shift>;
  /** Тип выплаты за работу */
  temporaryShift?: Maybe<TemporaryShift>;
  /** Id пользователя которому нужно доставить подписку */
  userId: Scalars['String']['output'];
};

export type Token = {
  __typename?: 'Token';
  /** JWT access token */
  accessToken: Scalars['String']['output'];
  /** JWT refresh token */
  refreshToken: Scalars['String']['output'];
};

export type UnassignWorkerFromFacilityGroupInput = {
  /** ID группы объектов */
  facilityGroupId: Scalars['String']['input'];
  /** ID исполнителя */
  unassignAt: Scalars['DateTime']['input'];
  /** Комментарий при увольнении работника */
  unassignComment?: InputMaybe<Scalars['String']['input']>;
  /** ID исполнителя */
  userId: Scalars['String']['input'];
};

export type UnassignWorkerFromFacilityInput = {
  /** ID объекта */
  facilityId: Scalars['String']['input'];
  /** ID исполнителя */
  unassignAt: Scalars['DateTime']['input'];
  /** Комментарий при увольнении работника */
  unassignComment?: InputMaybe<Scalars['String']['input']>;
  /** ID исполнителя */
  userId: Scalars['String']['input'];
};

export type UnassignWorkerFromPositionInput = {
  /** ID объекта */
  facilityId: Scalars['String']['input'];
  /** ID профессии */
  positionId: Scalars['String']['input'];
  /** ID исполнителя */
  unassignAt: Scalars['DateTime']['input'];
  /** Комментарий при увольнении работника */
  unassignComment?: InputMaybe<Scalars['String']['input']>;
  /** ID исполнителя */
  userId: Scalars['String']['input'];
};

/** Единицы измерения для оплаты */
export enum UnitType {
  Hour = 'HOUR',
  Piece = 'PIECE',
  Point = 'POINT'
}

export type UpdateFacilitiesActivityInput = {
  /** Массив идентификаторов объектов */
  facilitiesIds: Array<Scalars['String']['input']>;
  /** Флаг активности */
  is_active: Scalars['Boolean']['input'];
};

export type UpdateFacilitiesLegalEntitiesInput = {
  /** Массив идентификаторов объектов */
  facilitiesIds: Array<Scalars['String']['input']>;
  /** Идентификатор юр. лица */
  legalEntityId: Scalars['String']['input'];
};

export type UpdateLeadInput = {
  date: Scalars['DateTime']['input'];
  id: Scalars['String']['input'];
  isProcessed?: Scalars['Boolean']['input'];
  status?: LeadStatus;
};

/** Параметры для обновления push-уведомления */
export type UpdateNotifyLetterInput = {
  /** Текст уведомления */
  body?: InputMaybe<Scalars['String']['input']>;
  /** Массив ID городов */
  cities?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Массив ID объектов */
  facilities?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Массив ID групп объектов */
  facilityGroups?: InputMaybe<Array<Scalars['String']['input']>>;
  /** ID уведомления */
  id: Scalars['String']['input'];
  /** Бессрочность публикации */
  indefinitely?: InputMaybe<Scalars['Boolean']['input']>;
  /** Интервал для повторения рассылки */
  interval?: InputMaybe<Scalars['Int']['input']>;
  /** Название уведомления */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Массив ID позиций (профессий) */
  positions?: InputMaybe<Array<Scalars['String']['input']>>;
  /** ID параметров публикации */
  publicConditionId: Scalars['String']['input'];
  /** Дата начала публикации */
  publishFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Дата окончания публикации */
  publishTo?: InputMaybe<Scalars['DateTime']['input']>;
  /** Фильтр по открепленным исполнителям */
  pushToUnassigned: PushToUnassignedFilter;
  /** Массив Ролей */
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Ссылка для перенаправления */
  route?: InputMaybe<PushRoute>;
  /** Статус пуш уведомления */
  status?: InputMaybe<NotifyLetterStatus>;
  /** Заголовок уведомления */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Показать группе пользователей да/нет */
  useCondition?: Scalars['Boolean']['input'];
};

export type UpdateShiftModerationInput = {
  /** Идентификатор смены */
  shiftId: Scalars['ID']['input'];
  /** Отправить бонус к оплате? */
  toPaymentBonus?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateShiftPenaltyInfoInput = {
  /** Причина */
  cause?: InputMaybe<Scalars['String']['input']>;
  /** Комментарий */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Стоимость */
  cost?: InputMaybe<Scalars['Int']['input']>;
  /** ID штрафа */
  id: Scalars['String']['input'];
  /** Новый статус */
  shiftPenaltyStatus: ShiftPenaltyStatus;
};

export type UpdateStoryInput = {
  /** Group Id in ERP */
  erpGroupId?: InputMaybe<Scalars['String']['input']>;
  /** Source Id in ERP */
  erpSourceId?: InputMaybe<Scalars['String']['input']>;
  /** Цвет фона миниатюры */
  iconBackgroundColor?: Scalars['String']['input'];
  /** Название "истории" (в мобильном приложении поверх миниатюры */
  iconTitle: Scalars['String']['input'];
  id: Scalars['String']['input'];
  /** Название "истории" (внутри dashboard) */
  title: Scalars['String']['input'];
};

export type UpdateStorySlideInput = {
  /** Цвет фона слайда */
  backgroundColor?: Scalars['String']['input'];
  buttonOneAction?: Scalars['String']['input'];
  buttonOneLabel?: Scalars['String']['input'];
  /** Тип действия "кнопка 1" */
  buttonOneType?: InputMaybe<StorySlideButtonType>;
  buttonTwoAction?: Scalars['String']['input'];
  buttonTwoLabel?: Scalars['String']['input'];
  /** Тип действия "кнопка 2" */
  buttonTwoType?: InputMaybe<StorySlideButtonType>;
  id: Scalars['String']['input'];
  message?: Scalars['String']['input'];
  questionOne?: Scalars['String']['input'];
  questionThree?: Scalars['String']['input'];
  questionTwo?: Scalars['String']['input'];
  title?: Scalars['String']['input'];
};

export type UpdateUserEmailInput = {
  /** Email пользователя */
  email: Scalars['String']['input'];
  /** Идентификатор пользователя */
  userId: Scalars['String']['input'];
};

export type UpdateUserInput = {
  /** Разрешить работать без Наймикс */
  allowWorkWithoutNaimix?: InputMaybe<Scalars['Boolean']['input']>;
  /** Дата рождения */
  birthDate?: InputMaybe<Scalars['String']['input']>;
  /** Идентификатор страны, гражданином которой является пользователь */
  citizenshipId?: InputMaybe<Scalars['String']['input']>;
  /** id города пользователя */
  cityFiasId?: InputMaybe<Scalars['String']['input']>;
  /** id города пользователя */
  cityId?: InputMaybe<Scalars['String']['input']>;
  /** Название города пользователя */
  cityName?: InputMaybe<Scalars['String']['input']>;
  /** Email адрес */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Id Пользователя в ERP */
  externalId?: InputMaybe<Scalars['String']['input']>;
  /** Имя пользователя */
  firstname: Scalars['String']['input'];
  /** Идентификатор пользователя */
  id: Scalars['String']['input'];
  /** Фамилия пользователя */
  lastname: Scalars['String']['input'];
  /** Отчество пользователя */
  patronymic?: InputMaybe<Scalars['String']['input']>;
  /** Номер телефона пользователя */
  phone: Scalars['String']['input'];
  /** Роль пользователя в системе */
  role?: InputMaybe<Role>;
  /** Пол */
  sex?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserNaimixWorkStatusInput = {
  /** Разрешить пользователю работу без Наймикс? */
  allowWorkWithoutNaimix: Scalars['Boolean']['input'];
  /** Идентификатор пользователя */
  userId: Scalars['String']['input'];
};

export type UpdateUserNameAndCitizenshipInput = {
  /** Идентификатор гражданства пользователя */
  citizenshipId: Scalars['String']['input'];
  /** Имя пользователя */
  firstname: Scalars['String']['input'];
  /** Фамилия пользователя */
  lastname: Scalars['String']['input'];
  /** Отчество пользователя */
  patronymic?: InputMaybe<Scalars['String']['input']>;
  /** Идентификатор пользователя */
  userId: Scalars['String']['input'];
};

export type UpdateUserVersionInput = {
  currentVersion: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  /** Объекты, на которых пользователь является Бригадиром */
  Facility_FOREMAN?: Maybe<Array<Facility>>;
  /** Объекты в которых пользователь Супервайзер */
  Facility_SUPERVISOR?: Maybe<Array<Facility>>;
  /** Документы, которые пользователь рассматривает */
  allDocumentsReviews: Array<DocumentReview>;
  /** Разрешить работать без Наймикс */
  allowWorkWithoutNaimix: Scalars['Boolean']['output'];
  /** Гражданство */
  citizenship?: Maybe<Country>;
  citizenshipId?: Maybe<Scalars['String']['output']>;
  /** Город */
  city?: Maybe<City>;
  /** Id города */
  cityId?: Maybe<Scalars['String']['output']>;
  /** Комментарии к сменам */
  comments: Array<CommentEntity>;
  contactPhones?: Maybe<Array<ContactPhone>>;
  /** Контракты пользователя */
  contracts?: Maybe<Array<UserContract>>;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  currentVersion?: Maybe<Scalars['String']['output']>;
  deletedAt: Scalars['Date']['output'];
  /** Файлы документов загруженные пользователю */
  documents: Array<Document>;
  /** Статус проверки документов */
  documentsStatus: DocumentsStatus;
  /** Email address */
  email?: Maybe<Scalars['String']['output']>;
  /** Id пользователя в ERP */
  externalId?: Maybe<Scalars['String']['output']>;
  /** Объекты в которых пользователь Менеджер */
  facilitiesManager?: Maybe<Array<Facility>>;
  facilityToUsers: Array<FacilityToUser>;
  /** Имя */
  firstname: Scalars['String']['output'];
  /** Количество хороших смен пользователя */
  goodShiftCount?: Maybe<Scalars['Int']['output']>;
  groupManagers: Array<GroupManager>;
  /** Группы в которых пользователь Директор */
  groupsDirector?: Maybe<Array<FacilityGroup>>;
  /** Группы в которых пользователь назначен как Менеджер */
  groupsManager?: Maybe<Array<FacilityGroup>>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  lastLoginAt?: Maybe<Scalars['Date']['output']>;
  /** Фамилия */
  lastname: Scalars['String']['output'];
  /** Лид */
  lead?: Maybe<Lead>;
  legalEntities: Array<LegalEntity>;
  manualAccrualItems: Array<ManualAccrualItem>;
  manualUserAccruals: Array<ManualUserAccrual>;
  marks?: Maybe<Array<Mark>>;
  /** Документы пользователя на рассмотрении */
  myDocumentsReviews: Array<DocumentReview>;
  notifications: Array<Notification>;
  /** Отчество */
  patronymic?: Maybe<Scalars['String']['output']>;
  /** Телефон */
  phone: Scalars['String']['output'];
  /** Id должности */
  positionId?: Maybe<Scalars['String']['output']>;
  professions: Array<Profession>;
  /** Анкета */
  questionnaire?: Maybe<Questionnaire>;
  referrals?: Maybe<Array<User>>;
  referrer?: Maybe<User>;
  referrerId?: Maybe<Scalars['String']['output']>;
  /** Состояние регистрации пользователя в Наймикс */
  registrationState?: Maybe<UserRegistrationState>;
  reports: Array<Report>;
  requestShiftSuggestions: Array<RequestShiftSuggestionEntity>;
  /** Реквизиты пользователя (отельная модель) */
  requisite?: Maybe<Requisite>;
  respondedVacancies?: Maybe<Array<Vacancy>>;
  /** Роль */
  role: Role;
  schedule?: Maybe<Array<Schedule>>;
  shiftForemen: Array<ShiftForeman>;
  shiftPenalties: Array<ShiftPenalty>;
  /** Смены пользователя */
  shifts?: Maybe<Array<Shift>>;
  /** подписка на объекты, о желании на них работать */
  subscribedFacility?: Maybe<Array<Facility>>;
  subscribers: Array<Subscribers>;
  supervisors: Array<Supervisors>;
  temporaryShifts: Array<TemporaryShift>;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  userActivityLogs: Array<UserActivityLog>;
  /** Автоматический рейтинг пользователя */
  userAutoRating?: Maybe<UserAutoRating>;
  userDevice: UserDevice;
  /** История опыта работника */
  userExperience?: Maybe<Array<UserExperience>>;
  /** История опыта работника */
  userGrade?: Maybe<Array<UserGrade>>;
  userGradeAuthor?: Maybe<Array<UserGrade>>;
  /** История опыта работника */
  userReview?: Maybe<Array<UserReview>>;
  userReviewAuthor: Array<UserReview>;
  /** Рабочие места пользователя */
  workposts?: Maybe<Array<WorkPost>>;
};

export type UserActivityLog = {
  __typename?: 'UserActivityLog';
  action: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  entity: Scalars['String']['output'];
  entityId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  user: User;
  userId: Scalars['String']['output'];
  values: Scalars['String']['output'];
};

/** DTO логирования действий пользователей */
export type UserActivityLogInput = {
  /** совершенное действие UPPERCASE !!! */
  action: Scalars['String']['input'];
  /** Тип сущности с которой совершено действие UPPERCASE !!! */
  entity: Scalars['String']['input'];
  /** id сущности с которой совершено действие */
  entityId?: InputMaybe<Scalars['String']['input']>;
  /** Необязательное поле */
  userId?: InputMaybe<Scalars['String']['input']>;
  /** JSON с объектом действия пользователя */
  values: Scalars['String']['input'];
};

/** Автоматический рейтинг работника */
export type UserAutoRating = {
  __typename?: 'UserAutoRating';
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Исполнительность */
  diligence: Scalars['Int']['output'];
  /** Дисциплина работника */
  discipline: Scalars['Int']['output'];
  /** Заполненность документов работником */
  documents: Scalars['Int']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Насколько часто работник получает штрафы */
  penalization: Scalars['Int']['output'];
  /** Работоспособность */
  performance: Scalars['Int']['output'];
  /** Рейтинг работника */
  rating: Scalars['Float']['output'];
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  /** Работник к которому относится оценка */
  user?: Maybe<User>;
  userId: Scalars['String']['output'];
};

/** Договора исполнителя */
export type UserContract = {
  __typename?: 'UserContract';
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Объект, к которому относится договор */
  legalEntity?: Maybe<LegalEntity>;
  legalEntityId?: Maybe<Scalars['String']['output']>;
  /** Статус договора */
  status: UserContractStatus;
  /** Тип договора */
  type: UserContractType;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  /** Ссылка на скачивание договора */
  url?: Maybe<Scalars['String']['output']>;
  user: User;
  /** Id пользователя */
  userId: Scalars['String']['output'];
};

/** Поля сортировки списка договоров */
export enum UserContractSorting {
  CreatedAt = 'createdAt',
  Lastname = 'lastname',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

/** Статус договора */
export enum UserContractStatus {
  Archived = 'Archived',
  Signed = 'Signed',
  Unsigned = 'Unsigned'
}

/** Тип договора */
export enum UserContractType {
  /** Агентский договор */
  AgentContract = 'AgentContract',
  /** Рамочный договор НПД */
  FrameContract = 'FrameContract',
  /** Согласие на обработку ПД */
  ProcessPersonalInfoContract = 'ProcessPersonalInfoContract'
}

/** Постраничный вывод списка договоров */
export type UserContractsPaginatedOutput = {
  __typename?: 'UserContractsPaginatedOutput';
  /** Список договоров */
  contracts: Array<UserContract>;
  /** Всего элементов' */
  count: Scalars['Int']['output'];
  /** Количество страниц */
  pages: Scalars['Int']['output'];
};

/** Модель устройства пользователя */
export type UserDevice = {
  __typename?: 'UserDevice';
  /** Поддержка Google сервисов */
  hasGoogleServices: Scalars['Boolean']['output'];
  /** Поддержка Huawei сервисов */
  hasHuaweiServices: Scalars['Boolean']['output'];
  /** Модель устройства */
  model: Scalars['String']['output'];
  user: User;
  /** Id пользователя */
  userId: Scalars['String']['output'];
};

export type UserDeviceInput = {
  /** Поддерживает ли Google сервисы */
  hasGoogleServices?: InputMaybe<Scalars['Boolean']['input']>;
  /** Поддерживает ли Huawei сервисы */
  hasHuaweiServices?: InputMaybe<Scalars['Boolean']['input']>;
  /** Название устройства пользователя */
  model?: InputMaybe<Scalars['String']['input']>;
};

/** Хранение истории опыта работника */
export type UserExperience = {
  __typename?: 'UserExperience';
  /** Дата назначения работника на должность */
  assignedAt?: Maybe<Scalars['Date']['output']>;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  facility?: Maybe<Facility>;
  /** Id объекта */
  facilityId: Scalars['String']['output'];
  /** Дата первой смены на должности */
  firstShiftAt?: Maybe<Scalars['Date']['output']>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  lastShiftAt?: Maybe<Scalars['Date']['output']>;
  position?: Maybe<Position>;
  /** Id должности (из справочника позиций) */
  positionId: Scalars['String']['output'];
  /** Дата снятия работника с должности */
  unassignAt?: Maybe<Scalars['Date']['output']>;
  /** Комментарий при увольнении */
  unassignComment?: Maybe<Scalars['String']['output']>;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  user?: Maybe<User>;
  /** Id пользователя */
  userId: Scalars['String']['output'];
};

/** поле по которому будет работать сортировка */
export enum UserFieldsForSorting {
  ActiveShifts = 'activeShifts',
  CreatedAt = 'createdAt',
  CurrentVersion = 'currentVersion',
  LastLoginAt = 'lastLoginAt',
  Lastname = 'lastname',
  PaymentInfo = 'paymentInfo',
  PersonalInfo = 'personalInfo',
  Phone = 'phone',
  Rating = 'rating',
  RequisiteUpdatedAt = 'requisiteUpdatedAt',
  TaxPartnerInfo = 'taxPartnerInfo',
  UpdatedAt = 'updatedAt'
}

export type UserGrade = {
  __typename?: 'UserGrade';
  /** Автор оценки */
  author: User;
  character: Scalars['Int']['output'];
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  facility?: Maybe<Facility>;
  facilityId: Scalars['String']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  position?: Maybe<Position>;
  positionId: Scalars['String']['output'];
  responsiveness: Scalars['Int']['output'];
  speed: Scalars['Int']['output'];
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

/** отображение пользователя с пересечениями его смен */
export type UserIntersect = {
  __typename?: 'UserIntersect';
  /** Объекты, на которых пользователь является Бригадиром */
  Facility_FOREMAN?: Maybe<Array<Facility>>;
  /** Объекты в которых пользователь Супервайзер */
  Facility_SUPERVISOR?: Maybe<Array<Facility>>;
  /** Документы, которые пользователь рассматривает */
  allDocumentsReviews: Array<DocumentReview>;
  /** Разрешить работать без Наймикс */
  allowWorkWithoutNaimix: Scalars['Boolean']['output'];
  /** Гражданство */
  citizenship?: Maybe<Country>;
  citizenshipId?: Maybe<Scalars['String']['output']>;
  /** Город */
  city?: Maybe<City>;
  /** Id города */
  cityId?: Maybe<Scalars['String']['output']>;
  /** Комментарии к сменам */
  comments: Array<CommentEntity>;
  contactPhones?: Maybe<Array<ContactPhone>>;
  /** Контракты пользователя */
  contracts?: Maybe<Array<UserContract>>;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  currentVersion?: Maybe<Scalars['String']['output']>;
  deletedAt: Scalars['Date']['output'];
  /** Файлы документов загруженные пользователю */
  documents: Array<Document>;
  /** Статус проверки документов */
  documentsStatus: DocumentsStatus;
  /** Email address */
  email?: Maybe<Scalars['String']['output']>;
  /** Id пользователя в ERP */
  externalId?: Maybe<Scalars['String']['output']>;
  /** Объекты в которых пользователь Менеджер */
  facilitiesManager?: Maybe<Array<Facility>>;
  facilityToUsers: Array<FacilityToUser>;
  /** Имя */
  firstname: Scalars['String']['output'];
  /** Количество хороших смен пользователя */
  goodShiftCount?: Maybe<Scalars['Int']['output']>;
  groupManagers: Array<GroupManager>;
  /** Группы в которых пользователь Директор */
  groupsDirector?: Maybe<Array<FacilityGroup>>;
  /** Группы в которых пользователь назначен как Менеджер */
  groupsManager?: Maybe<Array<FacilityGroup>>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Смены пользователя c которыми найдено пересечения */
  intersect?: Maybe<Array<Shift>>;
  isActive: Scalars['Boolean']['output'];
  lastLoginAt?: Maybe<Scalars['Date']['output']>;
  /** Фамилия */
  lastname: Scalars['String']['output'];
  /** Лид */
  lead?: Maybe<Lead>;
  legalEntities: Array<LegalEntity>;
  manualAccrualItems: Array<ManualAccrualItem>;
  manualUserAccruals: Array<ManualUserAccrual>;
  marks?: Maybe<Array<Mark>>;
  /** Документы пользователя на рассмотрении */
  myDocumentsReviews: Array<DocumentReview>;
  notifications: Array<Notification>;
  /** Отчество */
  patronymic?: Maybe<Scalars['String']['output']>;
  /** Телефон */
  phone: Scalars['String']['output'];
  /** Id должности */
  positionId?: Maybe<Scalars['String']['output']>;
  professions: Array<Profession>;
  /** Анкета */
  questionnaire?: Maybe<Questionnaire>;
  referrals?: Maybe<Array<User>>;
  referrer?: Maybe<User>;
  referrerId?: Maybe<Scalars['String']['output']>;
  /** Состояние регистрации пользователя в Наймикс */
  registrationState?: Maybe<UserRegistrationState>;
  reports: Array<Report>;
  requestShiftSuggestions: Array<RequestShiftSuggestionEntity>;
  /** Реквизиты пользователя (отельная модель) */
  requisite?: Maybe<Requisite>;
  respondedVacancies?: Maybe<Array<Vacancy>>;
  /** Роль */
  role: Role;
  schedule?: Maybe<Array<Schedule>>;
  shiftForemen: Array<ShiftForeman>;
  shiftPenalties: Array<ShiftPenalty>;
  /** Смены пользователя */
  shifts?: Maybe<Array<Shift>>;
  /** подписка на объекты, о желании на них работать */
  subscribedFacility?: Maybe<Array<Facility>>;
  subscribers: Array<Subscribers>;
  supervisors: Array<Supervisors>;
  temporaryShifts: Array<TemporaryShift>;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  userActivityLogs: Array<UserActivityLog>;
  /** Автоматический рейтинг пользователя */
  userAutoRating?: Maybe<UserAutoRating>;
  userDevice: UserDevice;
  /** История опыта работника */
  userExperience?: Maybe<Array<UserExperience>>;
  /** История опыта работника */
  userGrade?: Maybe<Array<UserGrade>>;
  userGradeAuthor?: Maybe<Array<UserGrade>>;
  /** История опыта работника */
  userReview?: Maybe<Array<UserReview>>;
  userReviewAuthor: Array<UserReview>;
  /** Рабочие места пользователя */
  workposts?: Maybe<Array<WorkPost>>;
};

export type UserListInput = {
  citizenshipId?: InputMaybe<Scalars['String']['input']>;
  /** Статус подписания договоров */
  contractStatus?: InputMaybe<UserContractStatus>;
  /** Статус проверки документов */
  documentsStatus?: InputMaybe<DocumentsStatus>;
  facilityGroupId?: InputMaybe<Scalars['String']['input']>;
  facilityId?: InputMaybe<Scalars['String']['input']>;
  /** Фильтр по активности пользователя */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Фильтр по сотрудничают/не сотрудничают с объектом */
  isCooperateWithGroup?: InputMaybe<Scalars['Boolean']['input']>;
  /** номер страницы */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Заполненены ли платежные данные */
  paymentInfo?: InputMaybe<PaymentInfoEnum>;
  /** Статус заполнения персональной информации */
  personalInfo?: InputMaybe<UserPersonalInfoStatus>;
  /** Роль пользователя */
  role?: InputMaybe<Role>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  /** Количество элементов на странице */
  size?: InputMaybe<Scalars['Int']['input']>;
  /** поле по которому сортировка, по умолчанию updatedAt */
  sortBy?: InputMaybe<UserFieldsForSorting>;
  /** Направление сортировки */
  sortWay?: InputMaybe<SortingWay>;
  /** Добавлено ли приложение "Мой налог" в партнеры */
  taxPartnerInfo?: InputMaybe<TaxPartnerInfo>;
};

/** Статус проверки персональных данных в Наймикс */
export enum UserPersonalInfoStatus {
  PersonalDataNotSent = 'PERSONAL_DATA_NOT_SENT',
  PersonalDataNotVerified = 'PERSONAL_DATA_NOT_VERIFIED',
  PersonalDataSent = 'PERSONAL_DATA_SENT',
  PersonalDataVerified = 'PERSONAL_DATA_VERIFIED'
}

export type UserRegistrationState = {
  __typename?: 'UserRegistrationState';
  /** Статус подписания АД и согласия на обработку ПД */
  adAndPdState: ApAndPdStateEnum;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  deletedAt: Scalars['Date']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Заполнены ли платежные данные */
  paymentInfo: PaymentInfoEnum;
  /** Статус заполнения персональной информации */
  personalInfo: UserPersonalInfoStatus;
  /** Причина отклонения персональных данных */
  personalInfoDeclineReason?: Maybe<Scalars['String']['output']>;
  /** Статус регистрации в Наймикс */
  registrationInNaimixState: RegistrationInNaimixStateEnum;
  /** Добавлено ли приложение "Мой налог" в партнеры */
  taxPartnerInfo: TaxPartnerInfo;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  /** Пользователь */
  user: User;
  userId: Scalars['String']['output'];
};

export type UserReview = {
  __typename?: 'UserReview';
  /** Автор отзыва */
  author: User;
  authorId: Scalars['String']['output'];
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  facility?: Maybe<Facility>;
  facilityId: Scalars['String']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  isGood?: Maybe<Scalars['Boolean']['output']>;
  position?: Maybe<Position>;
  positionId: Scalars['String']['output'];
  text: Scalars['String']['output'];
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  userId: Scalars['String']['output'];
};

export type UserScheduleStatusInput = {
  /** Статус, который будет установлен для графика */
  confirmedSchedule: ScheduleStage;
  /** id графика, если не передается, то статус изменится у всех графиков */
  scheduleId: Scalars['String']['input'];
};

export type UserStatusInput = {
  isActive: Scalars['Boolean']['input'];
  userId: Scalars['String']['input'];
};

export type Vacancy = {
  __typename?: 'Vacancy';
  /** Средняя стоимость оплаты по смене */
  averageCost: Scalars['Float']['output'];
  /** Массив IDs стран */
  citizenshipsIds?: Maybe<Array<Scalars['String']['output']>>;
  coverPhoto?: Maybe<File>;
  coverPhotoId?: Maybe<Scalars['String']['output']>;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Описание */
  description?: Maybe<Scalars['String']['output']>;
  /** Примечание к документам */
  documentsNotes?: Maybe<Scalars['String']['output']>;
  /** Group Id in ERP */
  erpGroupId?: Maybe<Scalars['String']['output']>;
  /** Source Id in ERP */
  erpSourceId?: Maybe<Scalars['String']['output']>;
  facility: Facility;
  facilityId: Scalars['String']['output'];
  /** Возможность образования */
  hasEducation?: Maybe<Scalars['Boolean']['output']>;
  /** Ставка в час */
  hourRate: Scalars['Float']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Публиковать ли стоимость смены */
  isPublishCostOfShift: Scalars['Boolean']['output'];
  /** Отображать в вакансии диапазон стоимости ОТ-ДО */
  isRangeCost: Scalars['Boolean']['output'];
  /** Вакансия активна/не активна */
  is_active: Scalars['Boolean']['output'];
  /** Длительность обеденного перерыва в минутах */
  lunchDuration?: Maybe<Scalars['Int']['output']>;
  /** Максимальный возраст */
  maxAge?: Maybe<Scalars['Int']['output']>;
  /** Минимальный возраст */
  minAge?: Maybe<Scalars['Int']['output']>;
  /** Примечание */
  notes?: Maybe<Scalars['String']['output']>;
  /** Тип выплаты за работу */
  payout?: Maybe<PayoutType>;
  /** Частота выплат */
  payoutFrequency?: Maybe<PayoutFrequency>;
  /** Ночь или день */
  period: ShiftTime;
  position?: Maybe<Position>;
  positionId: Scalars['String']['output'];
  /** Кол-во дней стажировки */
  probationPeriod?: Maybe<Scalars['Int']['output']>;
  /** Необходимые документы */
  requiredDocuments: Array<CasingDocumentType>;
  /** Требования */
  requirements?: Maybe<Scalars['String']['output']>;
  responders?: Maybe<Array<User>>;
  /** Обязанности */
  responsibilities?: Maybe<Scalars['String']['output']>;
  /**
   * ID типов графиков
   * @deprecated Отказываемся от этого поля
   */
  scheduleTypesIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Отдел или секция */
  section?: Maybe<Scalars['String']['output']>;
  /** Пол */
  sex?: Maybe<Sex>;
  /** Минимальная стоимость оплаты по смене от */
  shiftCostFrom: Scalars['Float']['output'];
  /** Максимальная стоимость оплаты по смене до */
  shiftCostUpTo: Scalars['Float']['output'];
  /** Продолжительность смены в часах */
  shiftDuration?: Maybe<Scalars['Int']['output']>;
  /** Конец смены */
  timeEnd: Scalars['String']['output'];
  /** Начало смены */
  timeStart: Scalars['String']['output'];
  /** Транспорт */
  transport?: Maybe<Scalars['String']['output']>;
  /** Единицы измерения для оплаты */
  unit?: Maybe<UnitType>;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  /** Документы одобренные у пользователя */
  userDocuments?: Maybe<Array<CasingDocumentType>>;
  /** Спец. одежда */
  workwear: Scalars['Boolean']['output'];
};

/** поле по которому будет работать сортировка (publishFrom = Дата начала публикации) */
export enum VacancyFieldsSorting {
  City = 'city',
  Facility = 'facility',
  FacilityGroup = 'facilityGroup',
  Position = 'position',
  Schedule = 'schedule',
  TimeStart = 'timeStart',
  UpdatedAt = 'updatedAt'
}

export type VacancyGallery = {
  __typename?: 'VacancyGallery';
  casingGallery: Array<CasingGalleryEntity>;
  facilityGallery: Array<FacilityGalleryEntity>;
};

export type VacancyInput = {
  /** Средняя стоимость оплаты по смене */
  averageCost?: Scalars['Float']['input'];
  /** IDs стран */
  citizenshipsIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Идентификатор фотографии альбома */
  coverPhotoId?: InputMaybe<Scalars['ID']['input']>;
  /** Описание */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Примечание к документам */
  documentsNotes?: InputMaybe<Scalars['String']['input']>;
  /** Group Id in ERP */
  erpGroupId?: InputMaybe<Scalars['String']['input']>;
  /** Source Id in ERP */
  erpSourceId?: InputMaybe<Scalars['String']['input']>;
  facilityId?: InputMaybe<Scalars['String']['input']>;
  /** Возможность образования */
  hasEducation?: InputMaybe<Scalars['Boolean']['input']>;
  hourRate: Scalars['Float']['input'];
  isActive?: Scalars['Boolean']['input'];
  isDeleted?: Scalars['Boolean']['input'];
  /** публиковать стоимость смены */
  isPublishCostOfShift?: Scalars['Boolean']['input'];
  /** Отображать в вакансии диапазон стоимости ОТ-ДО */
  isRangeCost?: Scalars['Boolean']['input'];
  /** Длительность обеденного перерыва в минутах */
  lunchDuration?: InputMaybe<Scalars['Int']['input']>;
  /** Максимальный возраст */
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  /** Минимальный возраст */
  minAge?: InputMaybe<Scalars['Int']['input']>;
  /** Примечание */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Тип выплаты за работу */
  payout?: InputMaybe<PayoutType>;
  /** Частота выплат */
  payoutFrequency?: InputMaybe<PayoutFrequency>;
  /** День / Ночь */
  period: ShiftTime;
  positionId?: InputMaybe<Scalars['String']['input']>;
  /** Кол-во дней стажировки */
  probationPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** Необходимые документы */
  requiredDocuments?: Array<CasingDocumentType>;
  /** Требования */
  requirements?: InputMaybe<Scalars['String']['input']>;
  /** Обязанности */
  responsibilities?: InputMaybe<Scalars['String']['input']>;
  /** IDs типов графиков */
  scheduleTypesIds?: InputMaybe<Array<Scalars['String']['input']>>;
  section?: InputMaybe<Scalars['String']['input']>;
  /** Пол */
  sex?: InputMaybe<Sex>;
  /** Минимальная стоимость оплаты по смене от */
  shiftCostFrom?: Scalars['Float']['input'];
  /** Максимальная стоимость оплаты по смене до */
  shiftCostUpTo?: Scalars['Float']['input'];
  timeEnd?: InputMaybe<Scalars['String']['input']>;
  timeStart?: InputMaybe<Scalars['String']['input']>;
  /** Транспорт */
  transport?: InputMaybe<Scalars['String']['input']>;
  /** Единицы измерения для оплаты */
  unit?: InputMaybe<UnitType>;
  /** Спец. одежда */
  workwear?: Scalars['Boolean']['input'];
};

export type VacancyListInput = {
  /** Город в котором находится вакансия */
  cityId?: InputMaybe<Scalars['String']['input']>;
  /** Объект на котором находится вакансия */
  facilityId?: InputMaybe<Scalars['String']['input']>;
  /** Группа объектов к которой относится вакансия */
  groupId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Номер страницы */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Ночь или день */
  period?: InputMaybe<ShiftTime>;
  /** Массив строк с значением возможных графиков (прим: [2/2, 5/2]) */
  schedule?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Строка поиска */
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  /** Количество элементов на странице */
  size?: InputMaybe<Scalars['Int']['input']>;
  /** поле по которому сортировка, по умолчанию updatedAt */
  sortBy?: InputMaybe<VacancyFieldsSorting>;
  /** Направление сортировки */
  sortWay?: InputMaybe<SortingWay>;
};

/** Тип адреса */
export enum VacancySearchType {
  Metro = 'Metro',
  Point = 'Point'
}

/** Модель объектов с учетом возможности ожидания получения мед.книжки работником */
export type WaitingFacility = {
  __typename?: 'WaitingFacility';
  /** Адрес объекта */
  address?: Maybe<Address>;
  /** @deprecated Адрес храним в поле addressString */
  addressId?: Maybe<Scalars['String']['output']>;
  /** Адрес объекта */
  addressString?: Maybe<Scalars['String']['output']>;
  /** Массив содержащий список профессий на объекте со ставками по ним */
  casings?: Maybe<Array<Casing>>;
  checkInMeasurement: Scalars['Int']['output'];
  checkOutMeasurement: Scalars['Int']['output'];
  city?: Maybe<City>;
  cityId?: Maybe<Scalars['String']['output']>;
  /** Менеджеры объекта */
  clientManagers?: Maybe<Array<User>>;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Расчетное поле для расстояния от гео точки в километрах */
  distance?: Maybe<Scalars['Float']['output']>;
  /** Включен или выключен "Всё Возможно Бонус" на объекте */
  everythingIsPossibleBonus: Scalars['Boolean']['output'];
  /** Id объекта в ERP */
  externalId?: Maybe<Scalars['String']['output']>;
  /** Галерея фотографий профессии объекта */
  facilityGallery?: Maybe<Array<FacilityGalleryEntity>>;
  facilityGroup?: Maybe<FacilityGroup>;
  facilityGroupId?: Maybe<Scalars['String']['output']>;
  facilitySupervisors: Array<Supervisors>;
  facilityToUsers: Array<FacilityToUser>;
  foremen?: Maybe<Array<User>>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Есть ли на объекте возможность работать по справке без мед.книжки n-дней */
  isMedInvoiceAllowed: Scalars['Boolean']['output'];
  is_active?: Maybe<Scalars['Boolean']['output']>;
  /** Показывать ли пользователям на объекте бонусы */
  is_bonus_active: Scalars['Boolean']['output'];
  /** Показывать ли пользователям на объекте начисления */
  is_finance_active: Scalars['Boolean']['output'];
  latitude: Scalars['String']['output'];
  legalEntity?: Maybe<LegalEntity>;
  /** Идетнтификатор юр. лица */
  legalEntityId?: Maybe<Scalars['String']['output']>;
  longitude: Scalars['String']['output'];
  manualAccruals: Array<ManualAccrual>;
  metro?: Maybe<Scalars['String']['output']>;
  /** Станции метро */
  metroStations?: Maybe<Array<MetroStation>>;
  /** IDs станций метро */
  metroStationsIds?: Maybe<Array<Scalars['String']['output']>>;
  moderationReports: Array<ModerationReport>;
  /** Идентификатор объекта в Наймикс */
  naimixObjectId?: Maybe<Scalars['String']['output']>;
  /** Название объекта в Наймикс */
  naimixObjectName?: Maybe<Scalars['String']['output']>;
  /** Номер сценария Наймикс */
  naimixScenarioNumber?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** количество дней ожидания медицинской книжки (количество дней действия справки о прохождении осмотра перед получением медицинской книжки) */
  numberDaysWaitMedical: Scalars['Int']['output'];
  /** Массив содержащий список штрафов на объекте */
  penalty?: Maybe<Array<Penalty>>;
  /** Сумма для выплат с учетом % налога 'Всё Возможно Бонус' на объекте */
  possibleBonusPaymentSum: Scalars['Float']['output'];
  /** Количество смен для достижения бонуса "Всё Возможно Бонус" на объекте */
  possibleBonusShiftCount: Scalars['Float']['output'];
  /** Сумма для выплат с учетом % налога "Всё Возможно Бонус" на объекте */
  possibleBonusViewSum: Scalars['Float']['output'];
  /** Дочерние бонусные программы */
  promotions?: Maybe<Array<PromotionEntity>>;
  publicConditions?: Maybe<Array<PublicCondition>>;
  radius: Scalars['Int']['output'];
  /** Реферальные программы привязанные к объекту */
  referralPrograms?: Maybe<Array<ReferralProgramEntity>>;
  schedules: Array<Schedule>;
  shifts: Array<Shift>;
  /** Показывать ли пользователям заработок до модерации */
  showSalaryBeforeModeration: Scalars['Boolean']['output'];
  subscribers?: Maybe<Array<User>>;
  supervisors?: Maybe<Array<User>>;
  temporaryShifts: Array<TemporaryShift>;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  userExperiences: Array<UserExperience>;
  userGrades: Array<UserGrade>;
  userReviews: Array<UserReview>;
  vacancies?: Maybe<Array<Vacancy>>;
  /** Статус может ли работник работать сегодня на объекте по справке о мед.книжке */
  waitingMedicalStatus: Scalars['Boolean']['output'];
  workPosts?: Maybe<Array<WorkPost>>;
  zayavkaSchedules: Array<ZayavkaSchedule>;
  zayavkaShifts: Array<ZayavkaShift>;
  zayavkas: Array<Zayavka>;
};

export type WorkPost = {
  __typename?: 'WorkPost';
  /** Когда назначен на рабочее место */
  assignedAt: Scalars['Date']['output'];
  assignedBy: Scalars['String']['output'];
  /** Экземпляр модели объекта, на котором пользователь занимает конкретную должность */
  facility?: Maybe<Facility>;
  /** id объекта, на котором пользователь занимает конкретную должность */
  facilityId: Scalars['String']['output'];
  /** Возможность стажировки */
  hasProbation: Scalars['Boolean']['output'];
  /** Признак есть ли хотя бы одна отработанная смена после стажировки */
  hasWorkedShift?: Maybe<Scalars['Boolean']['output']>;
  /** размер оплаты в час */
  hourRate?: Maybe<Scalars['Float']['output']>;
  /** Признак пройдена ли стажировка */
  isProbationFinished?: Maybe<Scalars['Boolean']['output']>;
  /** Тип выплаты за работу */
  payout?: Maybe<PayoutType>;
  /** Объект должности, которую занимает пользователь на объекте (рабочем месте) */
  position?: Maybe<Position>;
  /** id должности, которую занимает пользователь на объекте (рабочем месте) */
  positionId: Scalars['String']['output'];
  /** Дата окончания стажировки в формате YYYY-MM-DD */
  probationEnd?: Maybe<Scalars['String']['output']>;
  /** Период стажировки в календарных днях */
  probationPeriod?: Maybe<Scalars['Int']['output']>;
  /** Ставка за смену на период стажировки */
  probationRate?: Maybe<Scalars['Int']['output']>;
  /** Дата начала стажировки в формате YYYY-MM-DD */
  probationStart?: Maybe<Scalars['String']['output']>;
  /** Статус рабочего места */
  status: WorkPostStatus;
  /** Единицы измерения для оплаты */
  unit?: Maybe<UnitType>;
  /** Дата и время создания рабочего места */
  updatedAt: Scalars['Date']['output'];
  /** Объект пользователя, который связан с рабочим местом (должность + объект) */
  user?: Maybe<User>;
  /** Id пользователя, который связан с рабочим местом (должность + объект) */
  userId: Scalars['String']['output'];
};

/** Должность исполнителя с историей сотрудничества */
export type WorkPostExperienceOutput = {
  __typename?: 'WorkPostExperienceOutput';
  /** Когда назначен на рабочее место */
  assignedAt: Scalars['Date']['output'];
  assignedBy: Scalars['String']['output'];
  /** Экземпляр модели объекта, на котором пользователь занимает конкретную должность */
  facility?: Maybe<Facility>;
  /** id объекта, на котором пользователь занимает конкретную должность */
  facilityId: Scalars['String']['output'];
  /** Возможность стажировки */
  hasProbation: Scalars['Boolean']['output'];
  /** Признак есть ли хотя бы одна отработанная смена после стажировки */
  hasWorkedShift?: Maybe<Scalars['Boolean']['output']>;
  /** размер оплаты в час */
  hourRate?: Maybe<Scalars['Float']['output']>;
  /** Признак пройдена ли стажировка */
  isProbationFinished?: Maybe<Scalars['Boolean']['output']>;
  /** Тип выплаты за работу */
  payout?: Maybe<PayoutType>;
  /** Объект должности, которую занимает пользователь на объекте (рабочем месте) */
  position?: Maybe<Position>;
  /** id должности, которую занимает пользователь на объекте (рабочем месте) */
  positionId: Scalars['String']['output'];
  /** Дата окончания стажировки в формате YYYY-MM-DD */
  probationEnd?: Maybe<Scalars['String']['output']>;
  /** Период стажировки в календарных днях */
  probationPeriod?: Maybe<Scalars['Int']['output']>;
  /** Ставка за смену на период стажировки */
  probationRate?: Maybe<Scalars['Int']['output']>;
  /** Дата начала стажировки в формате YYYY-MM-DD */
  probationStart?: Maybe<Scalars['String']['output']>;
  /** Статус рабочего места */
  status: WorkPostStatus;
  /** Единицы измерения для оплаты */
  unit?: Maybe<UnitType>;
  /** Дата и время создания рабочего места */
  updatedAt: Scalars['Date']['output'];
  /** Объект пользователя, который связан с рабочим местом (должность + объект) */
  user?: Maybe<User>;
  /** История сотрудничества по профессии на объекте */
  userExperience: Array<UserExperience>;
  /** Id пользователя, который связан с рабочим местом (должность + объект) */
  userId: Scalars['String']['output'];
};

/** Объект доставки бригадиру изменений рабочего места по подписке */
export type WorkPostForemanSubOutput = {
  __typename?: 'WorkPostForemanSubOutput';
  /** Id бригадира */
  userId: Scalars['String']['output'];
  /** Объект рабочего места */
  workPost: WorkPost;
};

/** WorkPost statuses */
export enum WorkPostStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

/** Объект доставки работника изменений рабочего места по подписке */
export type WorkPostWorkerSubOutput = {
  __typename?: 'WorkPostWorkerSubOutput';
  /** Id работника */
  userId: Scalars['String']['output'];
  /** Объект рабочего места */
  workPost: WorkPost;
};

/** Объект со списком должностей исполнителя */
export type WorkerFacilityWorkPostsOutput = {
  __typename?: 'WorkerFacilityWorkPostsOutput';
  /** Адрес объекта */
  address?: Maybe<Address>;
  /** @deprecated Адрес храним в поле addressString */
  addressId?: Maybe<Scalars['String']['output']>;
  /** Адрес объекта */
  addressString?: Maybe<Scalars['String']['output']>;
  /** Массив содержащий список профессий на объекте со ставками по ним */
  casings?: Maybe<Array<Casing>>;
  checkInMeasurement: Scalars['Int']['output'];
  checkOutMeasurement: Scalars['Int']['output'];
  city?: Maybe<City>;
  cityId?: Maybe<Scalars['String']['output']>;
  /** Менеджеры объекта */
  clientManagers?: Maybe<Array<User>>;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Расчетное поле для расстояния от гео точки в километрах */
  distance?: Maybe<Scalars['Float']['output']>;
  /** Включен или выключен "Всё Возможно Бонус" на объекте */
  everythingIsPossibleBonus: Scalars['Boolean']['output'];
  /** Id объекта в ERP */
  externalId?: Maybe<Scalars['String']['output']>;
  /** Галерея фотографий профессии объекта */
  facilityGallery?: Maybe<Array<FacilityGalleryEntity>>;
  facilityGroup?: Maybe<FacilityGroup>;
  facilityGroupId?: Maybe<Scalars['String']['output']>;
  facilitySupervisors: Array<Supervisors>;
  facilityToUsers: Array<FacilityToUser>;
  foremen?: Maybe<Array<User>>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Есть ли на объекте возможность работать по справке без мед.книжки n-дней */
  isMedInvoiceAllowed: Scalars['Boolean']['output'];
  is_active?: Maybe<Scalars['Boolean']['output']>;
  /** Показывать ли пользователям на объекте бонусы */
  is_bonus_active: Scalars['Boolean']['output'];
  /** Показывать ли пользователям на объекте начисления */
  is_finance_active: Scalars['Boolean']['output'];
  latitude: Scalars['String']['output'];
  legalEntity?: Maybe<LegalEntity>;
  /** Идетнтификатор юр. лица */
  legalEntityId?: Maybe<Scalars['String']['output']>;
  longitude: Scalars['String']['output'];
  manualAccruals: Array<ManualAccrual>;
  metro?: Maybe<Scalars['String']['output']>;
  /** Станции метро */
  metroStations?: Maybe<Array<MetroStation>>;
  /** IDs станций метро */
  metroStationsIds?: Maybe<Array<Scalars['String']['output']>>;
  moderationReports: Array<ModerationReport>;
  /** Идентификатор объекта в Наймикс */
  naimixObjectId?: Maybe<Scalars['String']['output']>;
  /** Название объекта в Наймикс */
  naimixObjectName?: Maybe<Scalars['String']['output']>;
  /** Номер сценария Наймикс */
  naimixScenarioNumber?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** количество дней ожидания медицинской книжки (количество дней действия справки о прохождении осмотра перед получением медицинской книжки) */
  numberDaysWaitMedical: Scalars['Int']['output'];
  /** Массив содержащий список штрафов на объекте */
  penalty?: Maybe<Array<Penalty>>;
  /** Сумма для выплат с учетом % налога 'Всё Возможно Бонус' на объекте */
  possibleBonusPaymentSum: Scalars['Float']['output'];
  /** Количество смен для достижения бонуса "Всё Возможно Бонус" на объекте */
  possibleBonusShiftCount: Scalars['Float']['output'];
  /** Сумма для выплат с учетом % налога "Всё Возможно Бонус" на объекте */
  possibleBonusViewSum: Scalars['Float']['output'];
  /** Дочерние бонусные программы */
  promotions?: Maybe<Array<PromotionEntity>>;
  publicConditions?: Maybe<Array<PublicCondition>>;
  radius: Scalars['Int']['output'];
  /** Реферальные программы привязанные к объекту */
  referralPrograms?: Maybe<Array<ReferralProgramEntity>>;
  schedules: Array<Schedule>;
  shifts: Array<Shift>;
  /** Показывать ли пользователям заработок до модерации */
  showSalaryBeforeModeration: Scalars['Boolean']['output'];
  subscribers?: Maybe<Array<User>>;
  supervisors?: Maybe<Array<User>>;
  temporaryShifts: Array<TemporaryShift>;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  userExperiences: Array<UserExperience>;
  userGrades: Array<UserGrade>;
  userReviews: Array<UserReview>;
  vacancies?: Maybe<Array<Vacancy>>;
  workPosts?: Maybe<Array<WorkPost>>;
  /** Должности исполнителя на объекте */
  workposts: Array<WorkPostExperienceOutput>;
  zayavkaSchedules: Array<ZayavkaSchedule>;
  zayavkaShifts: Array<ZayavkaShift>;
  zayavkas: Array<Zayavka>;
};

/** Юзер с полями: даты мед.книжки, корешок, апрув на мед.книжку, апрув на корешок */
export type WorkerWithMedical = {
  __typename?: 'WorkerWithMedical';
  /** Объекты, на которых пользователь является Бригадиром */
  Facility_FOREMAN?: Maybe<Array<Facility>>;
  /** Объекты в которых пользователь Супервайзер */
  Facility_SUPERVISOR?: Maybe<Array<Facility>>;
  /** Документы, которые пользователь рассматривает */
  allDocumentsReviews: Array<DocumentReview>;
  /** Разрешить работать без Наймикс */
  allowWorkWithoutNaimix: Scalars['Boolean']['output'];
  /** Гражданство */
  citizenship?: Maybe<Country>;
  citizenshipId?: Maybe<Scalars['String']['output']>;
  /** Город */
  city?: Maybe<City>;
  /** Id города */
  cityId?: Maybe<Scalars['String']['output']>;
  /** Комментарии к сменам */
  comments: Array<CommentEntity>;
  contactPhones?: Maybe<Array<ContactPhone>>;
  /** Контракты пользователя */
  contracts?: Maybe<Array<UserContract>>;
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  currentVersion?: Maybe<Scalars['String']['output']>;
  deletedAt: Scalars['Date']['output'];
  documentReviewMedicalBook: Scalars['Boolean']['output'];
  documentReviewMedicalInvoice: Scalars['Boolean']['output'];
  /** Файлы документов загруженные пользователю */
  documents: Array<Document>;
  /** Статус проверки документов */
  documentsStatus: DocumentsStatus;
  /** Email address */
  email?: Maybe<Scalars['String']['output']>;
  /** Id пользователя в ERP */
  externalId?: Maybe<Scalars['String']['output']>;
  /** Объекты в которых пользователь Менеджер */
  facilitiesManager?: Maybe<Array<Facility>>;
  facilityToUsers: Array<FacilityToUser>;
  /** Имя */
  firstname: Scalars['String']['output'];
  /** Количество хороших смен пользователя */
  goodShiftCount?: Maybe<Scalars['Int']['output']>;
  groupManagers: Array<GroupManager>;
  /** Группы в которых пользователь Директор */
  groupsDirector?: Maybe<Array<FacilityGroup>>;
  /** Группы в которых пользователь назначен как Менеджер */
  groupsManager?: Maybe<Array<FacilityGroup>>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  lastLoginAt?: Maybe<Scalars['Date']['output']>;
  /** Фамилия */
  lastname: Scalars['String']['output'];
  /** Лид */
  lead?: Maybe<Lead>;
  legalEntities: Array<LegalEntity>;
  manualAccrualItems: Array<ManualAccrualItem>;
  manualUserAccruals: Array<ManualUserAccrual>;
  marks?: Maybe<Array<Mark>>;
  /** Даты конца аттестации строка даты YYYY-MM-DD */
  medicalAttestationEnd?: Maybe<Scalars['String']['output']>;
  /** Даты начала аттестации строка даты YYYY-MM-DD */
  medicalAttestationStart?: Maybe<Scalars['String']['output']>;
  /** Дата выдачи медицинской справки (о прохождении осмотра перед получением медицинской книжки) */
  medicalInvoiceIssueDate?: Maybe<Scalars['Date']['output']>;
  /** Документы пользователя на рассмотрении */
  myDocumentsReviews: Array<DocumentReview>;
  notifications: Array<Notification>;
  /** Отчество */
  patronymic?: Maybe<Scalars['String']['output']>;
  /** Телефон */
  phone: Scalars['String']['output'];
  /** Id должности */
  positionId?: Maybe<Scalars['String']['output']>;
  professions: Array<Profession>;
  /** Анкета */
  questionnaire?: Maybe<Questionnaire>;
  referrals?: Maybe<Array<User>>;
  referrer?: Maybe<User>;
  referrerId?: Maybe<Scalars['String']['output']>;
  /** Состояние регистрации пользователя в Наймикс */
  registrationState?: Maybe<UserRegistrationState>;
  reports: Array<Report>;
  requestShiftSuggestions: Array<RequestShiftSuggestionEntity>;
  /** Реквизиты пользователя (отельная модель) */
  requisite?: Maybe<Requisite>;
  respondedVacancies?: Maybe<Array<Vacancy>>;
  /** Роль */
  role: Role;
  schedule?: Maybe<Array<Schedule>>;
  shiftForemen: Array<ShiftForeman>;
  shiftPenalties: Array<ShiftPenalty>;
  /** Смены пользователя */
  shifts?: Maybe<Array<Shift>>;
  /** подписка на объекты, о желании на них работать */
  subscribedFacility?: Maybe<Array<Facility>>;
  subscribers: Array<Subscribers>;
  supervisors: Array<Supervisors>;
  temporaryShifts: Array<TemporaryShift>;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  userActivityLogs: Array<UserActivityLog>;
  /** Автоматический рейтинг пользователя */
  userAutoRating?: Maybe<UserAutoRating>;
  userDevice: UserDevice;
  /** История опыта работника */
  userExperience?: Maybe<Array<UserExperience>>;
  /** История опыта работника */
  userGrade?: Maybe<Array<UserGrade>>;
  userGradeAuthor?: Maybe<Array<UserGrade>>;
  /** История опыта работника */
  userReview?: Maybe<Array<UserReview>>;
  userReviewAuthor: Array<UserReview>;
  /** Рабочие места пользователя */
  workposts?: Maybe<Array<WorkPost>>;
};

export type Zayavka = {
  __typename?: 'Zayavka';
  authorId: Scalars['String']['output'];
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Даты окончания заявки в формате Date object / ISO Datetime */
  dateFrom: Scalars['Date']['output'];
  /** Дата начала заявки в формате Date object / ISO Datetime */
  dateTimeFrom: Scalars['Date']['output'];
  /** Даты окончания заявки в формате Date object / ISO Datetime */
  dateTimeTo: Scalars['Date']['output'];
  dateTo: Scalars['String']['output'];
  /** Объект на который назначена заявка */
  facility?: Maybe<Facility>;
  facilityId: Scalars['String']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  number: Scalars['Int']['output'];
  plans: Array<ZayavkaPlan>;
  shifts: Array<Shift>;
  /** Статус заявки */
  status: ZayavkaStage;
  /** Статус заявки */
  toCancel: ZayavkaToCancelStatus;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  /** Изменения заявки */
  zayavkaChanges?: Maybe<Array<ZayavkaChanges>>;
  /** Массив с графиками заявки */
  zayavkaSchedule?: Maybe<Array<ZayavkaSchedule>>;
  /** Массив со сменами заявки */
  zayavkaShift?: Maybe<Array<ZayavkaShift>>;
};

/** Sub statuses statuses of Zayavka */
export enum ZayavkaByFacilityDisplay {
  /** Новые и открытые */
  Active = 'ACTIVE',
  /** Все */
  All = 'ALL',
  /** Выполненные и закрытые */
  Finished = 'FINISHED'
}

export type ZayavkaCardInput = {
  /** Дата начала заявки в формате YYYY-MM-DD */
  dateFrom: Scalars['String']['input'];
  /** Даты окончания заявки в формате YYYY-MM-DD */
  dateTo: Scalars['String']['input'];
  /** Идентификатор объекта */
  facilityId: Scalars['String']['input'];
  /** Массив с должностями и планом количества смен/часов в заявке */
  plans: Array<ZayavkaPlanInput>;
};

export type ZayavkaChanges = {
  __typename?: 'ZayavkaChanges';
  authorId: Scalars['String']['output'];
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Прогресс согласования заявки */
  stage: ZayavkaChangesStatus;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  /** Заявка к которой относится этот экземпляр плана в заявке */
  zayavka?: Maybe<Zayavka>;
  zayavkaId: Scalars['String']['output'];
};

/** Sub statuses statuses of Zayavka */
export enum ZayavkaChangesStatus {
  /** Изменения подтверждены, ожидают отправки супервайзеру */
  Accepted = 'ACCEPTED',
  /** Изменения внесены, не отправлены и не подтверждены */
  Draft = 'DRAFT',
  /** Изменения в заявке отклонены директором */
  Ineligible = 'INELIGIBLE',
  /** Заявка одобрена, ждет одобрения супервайзером */
  New = 'NEW',
  /** Супервайзер не одобрил изменения */
  Refused = 'REFUSED',
  /** Изменения ожидают подтверждения директором */
  Waiting = 'WAITING'
}

/** поле по которому произойдет сортировка в заявках */
export enum ZayavkaFieldsSorting {
  DateFrom = 'dateFrom',
  Number = 'number',
  UpdatedAt = 'updatedAt'
}

export type ZayavkaListInput = {
  /** Дата начала заявки в формате YYYY-MM-DD */
  dateFrom?: InputMaybe<Scalars['String']['input']>;
  /** Даты окончания заявки в формате YYYY-MM-DD */
  dateTo?: InputMaybe<Scalars['String']['input']>;
  /** Объект по которому отфильтровать заявки */
  facilityId?: InputMaybe<Scalars['String']['input']>;
  /** Id группы объектов для фильтрации */
  groupId?: InputMaybe<Scalars['String']['input']>;
  /** Номер заявки который требуется найти */
  number?: InputMaybe<Scalars['String']['input']>;
  /** Номер страницы */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Строка поиска */
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  /** Количество элементов на странице */
  size?: InputMaybe<Scalars['Int']['input']>;
  /** поле по которому сортировка, по умолчанию updatedAt */
  sortBy?: InputMaybe<ZayavkaFieldsSorting>;
  /** Направление сортировки */
  sortWay?: InputMaybe<SortingWay>;
  /** Статус заявки */
  status?: InputMaybe<Scalars['String']['input']>;
};

/** Модель для плана в заявке по каждой должности */
export type ZayavkaPlan = {
  __typename?: 'ZayavkaPlan';
  countWorkers: Scalars['Int']['output'];
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  hours: Scalars['Int']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Позиция по которой нужны смены в заявке */
  position: Position;
  positionId: Scalars['String']['output'];
  shiftCount: Scalars['Int']['output'];
  shiftDuration: Scalars['Int']['output'];
  timesOfDay: ShiftTime;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  /** Заявка к которой относится этот экземпляр плана в заявке */
  zayavka?: Maybe<Zayavka>;
  zayavkaId: Scalars['String']['output'];
};

/** Плановые значения по должностям заявки */
export type ZayavkaPlanInput = {
  /** Количество работников */
  countWorkers?: Scalars['Int']['input'];
  /** Продолжительность смены */
  hours?: Scalars['Int']['input'];
  /** Идентификатор профессии */
  positionId: Scalars['String']['input'];
  /** Количество смен */
  shiftCount?: Scalars['Int']['input'];
  /** Продолжительность перерыва */
  shiftDuration?: Scalars['Int']['input'];
  /** Время дня */
  timesOfDay?: ShiftTime;
};

export type ZayavkaProgress = {
  __typename?: 'ZayavkaProgress';
  authorId: Scalars['String']['output'];
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Даты окончания заявки в формате Date object / ISO Datetime */
  dateFrom: Scalars['Date']['output'];
  /** Дата начала заявки в формате Date object / ISO Datetime */
  dateTimeFrom: Scalars['Date']['output'];
  /** Даты окончания заявки в формате Date object / ISO Datetime */
  dateTimeTo: Scalars['Date']['output'];
  dateTo: Scalars['String']['output'];
  /** Объект на который назначена заявка */
  facility?: Maybe<Facility>;
  facilityId: Scalars['String']['output'];
  /** Сколько всего часов запланировано в заявке */
  hoursPlanCount: Scalars['Int']['output'];
  /** Сколько часов в заявке проставлено в графики */
  hoursScheduleCount: Scalars['Int']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  number: Scalars['Int']['output'];
  plans: Array<ZayavkaPlan>;
  shifts: Array<Shift>;
  /** Сколько всего смен запланировано в заявке */
  shiftsPlanCount: Scalars['Int']['output'];
  /** Сколько смен в заявке проставлено в графики */
  shiftsScheduleCount: Scalars['Int']['output'];
  /** Статус заявки */
  status: ZayavkaStage;
  /** Статус заявки */
  toCancel: ZayavkaToCancelStatus;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  /** Изменения заявки */
  zayavkaChanges?: Maybe<Array<ZayavkaChanges>>;
  /** Массив с графиками заявки */
  zayavkaSchedule?: Maybe<Array<ZayavkaSchedule>>;
  /** Массив со сменами заявки */
  zayavkaShift?: Maybe<Array<ZayavkaShift>>;
};

export type ZayavkaSchedule = {
  __typename?: 'ZayavkaSchedule';
  /** Id автора графика в заявке */
  authorId: Scalars['String']['output'];
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Дата и время подтверждения графика Работником которого назначил супервайзер */
  dateAccept?: Maybe<Scalars['Date']['output']>;
  /** Дата и время начала графика */
  dateFrom: Scalars['Date']['output'];
  /** Дата и время окончания графика */
  dateTo: Scalars['Date']['output'];
  /** Тип графика: 1 - 2/2; 2 - 4/2; 3 - 5/2; 4 - 6/1; 5 - 7/0 (рабочих дней / выходные) */
  dayInterleaving?: Maybe<Scalars['Int']['output']>;
  facility: Facility;
  /** Id объекта */
  facilityId: Scalars['String']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isEdited: Scalars['Boolean']['output'];
  isNew: Scalars['Boolean']['output'];
  /** Длительность обедав графике */
  lunchDuration?: Maybe<Scalars['Int']['output']>;
  /** Дата и время начала графика (Новое) */
  newDateFrom?: Maybe<Scalars['Date']['output']>;
  /** Дата и время окончания графика (Новое) */
  newDateTo?: Maybe<Scalars['Date']['output']>;
  /** Тип графика (Новое) */
  newDayInterleaving?: Maybe<Scalars['Int']['output']>;
  /** Длительность обеда в графике (Новое) */
  newLunchDuration?: Maybe<Scalars['Int']['output']>;
  /** Новый тип графика */
  newScheduleType?: Maybe<ScheduleType>;
  /** Идентификатор нового типа графика */
  newScheduleTypeId?: Maybe<Scalars['String']['output']>;
  /** Длительность смены в графике, (Новое) */
  newShiftDuration?: Maybe<Scalars['Int']['output']>;
  /** Должность */
  position?: Maybe<Position>;
  /** Id должности */
  positionId: Scalars['String']['output'];
  /** Id графика назначенного пользователю */
  scheduleId?: Maybe<Scalars['String']['output']>;
  /** Тип графика */
  scheduleType?: Maybe<ScheduleType>;
  /** Идентификатор типа графика */
  scheduleTypeId?: Maybe<Scalars['String']['output']>;
  schedules: Array<Schedule>;
  /** Длительность смены в графике, необязательное */
  shiftDuration?: Maybe<Scalars['Int']['output']>;
  /** Время дня */
  timesOfDay: ShiftTime;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  /** Заявка */
  zayavka?: Maybe<Zayavka>;
  /** Id заявки к которой относится график */
  zayavkaId: Scalars['String']['output'];
  zayavkaShift: Array<ZayavkaShift>;
};

export type ZayavkaScheduleInput = {
  /** Количество создаваемых графиков */
  countSchedules?: InputMaybe<Scalars['Int']['input']>;
  /** Обязательное! время окончания графика и последней смены */
  dateEnd: Scalars['DateTime']['input'];
  /** Обязательное! время начала графика и первой смены */
  dateStart: Scalars['DateTime']['input'];
  /** Тип графика: 1 - 2/2; 2 - 4/2; 3 - 5/2; 4 - 6/1; 5 - 7/0 (рабочих дней / выходные) */
  dayInterleaving?: InputMaybe<Scalars['Int']['input']>;
  /** Тип графика: 1 - 2/2; 2 - 4/2; 3 - 5/2; 4 - 6/1; 5 - 7/0 (рабочих дней / выходные) */
  dayInterleavingId?: InputMaybe<Scalars['String']['input']>;
  /** объект на который устанавливается график */
  facilityId: Scalars['String']['input'];
  /** длительность обеда в минутах */
  lunchDuration?: InputMaybe<Scalars['Int']['input']>;
  /** позиция на которую устанавливается график */
  positionId: Scalars['String']['input'];
  /** Обязательное! Время окочания первой смены в графике для определения длительности смены */
  shiftEndDate: Scalars['DateTime']['input'];
  /** Время дня */
  timesOfDay?: ShiftTime;
  /** Id заявки к которой относится график */
  zayavkaId: Scalars['String']['input'];
};

/** Модель плана смен в заявке (смены графика заявки по шаблону которых будут создавать смены работникам) */
export type ZayavkaShift = {
  __typename?: 'ZayavkaShift';
  /** Id автора смены */
  authorId: Scalars['String']['output'];
  cost: Scalars['Int']['output'];
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Дата и время конца */
  dateEnd: Scalars['Date']['output'];
  /** Дата начала смены */
  dateFrom: Scalars['Date']['output'];
  /** длительность смены в минутах */
  duration: Scalars['Int']['output'];
  /** Объект к которому относится смена в заявке */
  facility?: Maybe<Facility>;
  /** Id объекта смены */
  facilityId: Scalars['String']['output'];
  hourRate: Scalars['Int']['output'];
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Помечено как к удалению? */
  isDeleted: Scalars['Boolean']['output'];
  /** Пометка о том, что смена редактировалась */
  isEdited: Scalars['Boolean']['output'];
  /** Смена в статусе "Свободная"? */
  isFree: Scalars['Boolean']['output'];
  /** Новая смена? */
  isNew: Scalars['Boolean']['output'];
  /** длительность обеда в минутах */
  lunchDuration?: Maybe<Scalars['Int']['output']>;
  /** Дата и время конца (новая) */
  newDateEnd?: Maybe<Scalars['Date']['output']>;
  /** Дата начала смены (новая) */
  newDateFrom?: Maybe<Scalars['Date']['output']>;
  /** длительность обеда в минутах (новая) */
  newLunchDuration?: Maybe<Scalars['Int']['output']>;
  /** Тип выплаты за работу */
  payout?: Maybe<PayoutType>;
  /** Id позиции смены */
  positionId: Scalars['String']['output'];
  /** Массив содержащий список откликнувшихся на свободную смену пользователей */
  requestShiftSuggestions: Array<RequestShiftSuggestionEntity>;
  /** Реальная смена работника, через которую назначен работник на смену заявки */
  shift?: Maybe<Shift>;
  /** Id смены к которой относится смена в заявке */
  shiftId?: Maybe<Scalars['String']['output']>;
  /** Единицы измерения для оплаты */
  unit?: Maybe<UnitType>;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  /** Заявка к которой относится смена */
  zayavka: Zayavka;
  /** Id заявки к которой относится смена */
  zayavkaId: Scalars['String']['output'];
  /** График к которому относится смена в заявке */
  zayavkaSchedule?: Maybe<ZayavkaSchedule>;
  /** Id графика заявки */
  zayavkaScheduleId: Scalars['String']['output'];
};

export type ZayavkaShiftByClientInput = {
  /** Дата и время конца */
  dateEnd: Scalars['DateTime']['input'];
  /** Дата начала смены */
  dateFrom: Scalars['DateTime']['input'];
  /** длительность обеда в минутах */
  lunchDuration?: InputMaybe<Scalars['Int']['input']>;
};

export enum ZayavkaShiftSuggestionsStatusEnum {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  New = 'NEW'
}

/** Статусы жизненного цикла заявки */
export enum ZayavkaStage {
  /** Новая созданная заявка без заполнения графиками */
  Blank = 'BLANK',
  /** заявка отменена клиентом */
  Canceled = 'CANCELED',
  /** В заявке проставлены графики, ожидает отправка супервайзеру */
  Draft = 'DRAFT',
  /** все смены по заявке отработаны */
  Finished = 'FINISHED',
  /** заявка отправлена супервайзеру, ждет одобрения */
  New = 'NEW',
  /** заявка отклонена супервайзером */
  Refused = 'REFUSED',
  /** заявка взята в работу супервайзером - смены назначаются */
  Working = 'WORKING'
}

/** Статусы использующиеся в случае отметки заявки к удалению */
export enum ZayavkaToCancelStatus {
  /** директор подтвердил удаление заявки */
  Accepted = 'ACCEPTED',
  /** директор отклонил удаление заявки */
  Ineligible = 'INELIGIBLE',
  /** заявка не отмечена к удалению */
  None = 'NONE',
  /** заявка помечена к удалению менеджером, ожидание действия директора */
  Waiting = 'WAITING'
}

/** Данные для непосредственно экрана табеля */
export type ResolveReportUserModel = {
  __typename?: 'resolveReportUserModel';
  /** Дата и время создания */
  createdAt: Scalars['Date']['output'];
  /** Исключить из отчета */
  disable: Scalars['Boolean']['output'];
  /** Фамилия и Имя */
  fio?: Maybe<Scalars['String']['output']>;
  /** Уникальный идентификатор */
  id: Scalars['ID']['output'];
  /** Телефон */
  phone?: Maybe<Scalars['String']['output']>;
  /** Дата и время обновления */
  updatedAt: Scalars['Date']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};
