import clsx from 'clsx';
import { useCallback } from 'react';
import { useStore } from 'store';
import MainSidebar from 'ui/sidebar';
import NewCustomSchedule from '../newCustomSchedule';
import NewSchedule from '../newSchedule';

const Shedule = () => {
  const typeSchedule = useStore(state => state.typeSchedule);
  const setTypeSchedule = useStore(state => state.setTypeSchedule);
  const createScheduleMenu = useStore(state => state.createScheduleMenu);
  const setCreateScheduleMenu = useStore(state => state.setCreateScheduleMenu);
  const setLunchDuration = useStore(state => state.setLunchDuration);

  const firstScheduleHandler = useCallback(() => {
    setTypeSchedule(1);
    setLunchDuration(0);
  }, [setTypeSchedule, setLunchDuration]);

  const secondScheduleHandler = useCallback(() => {
    setTypeSchedule(2);
    setLunchDuration(0);
  }, [setTypeSchedule, setLunchDuration]);

  return (
    <MainSidebar title="Добавить график" show={createScheduleMenu} setShow={setCreateScheduleMenu}>
      <div className="flex mb-4 w-full">
        <button
          className={clsx(
            'rounded-l-md border transition-all flex flex-1 justify-center',
            typeSchedule === 1 && 'bg-smena_bb-background',
          )}
          onClick={firstScheduleHandler}
        >
          <span className="Subtitle1 text-smena_text py-1">шаблон графика</span>
        </button>
        <button
          className={clsx(
            'rounded-r-md border transition-all flex flex-1 justify-center',
            typeSchedule === 2 && 'bg-smena_bb-background',
          )}
          onClick={secondScheduleHandler}
        >
          <span className="Subtitle1 text-smena_text py-1">кастомный график</span>
        </button>
      </div>
      {typeSchedule === 1 && <NewSchedule />}
      {typeSchedule === 2 && <NewCustomSchedule />}
    </MainSidebar>
  );
};

export default Shedule;
