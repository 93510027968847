import Days from './components/Days';
import Performers from './components/Performers/Performers';
import Statistics from './components/Statistics';

import { GetSupervisorWorkSheetQuery } from 'pages/facilityReport/api/queries/generated/GetSupervisorWorkSheet';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';

interface Props {
  data?: GetSupervisorWorkSheetQuery['getSupervisorWorkSheet']['data'];
}

const FacilityTable: React.FC<Props> = ({ data }) => {
  const { statisticsVisible } = useFacilityReportStore();

  return (
    <div className="flex overflow-auto" style={{ maxHeight: 'calc(100vh - 358px)' }}>
      <Performers data={data} />
      <Days data={data} />
      {statisticsVisible && <Statistics data={data} />}
    </div>
  );
};

export default FacilityTable;
