import { GetSupervisorWorkSheetDocument } from 'pages/facilityReport/api/queries/generated/GetSupervisorWorkSheet';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';
import { useChangeCustomScheduleMutation } from 'pages/userSchedule/api/mutations/generated/ChangeCustomSchedule';
import { useParams } from 'react-router';
import { useStore } from 'store';
import { errorHandler } from 'utils/helpers/errorHandler';
import { successToast, errorToast } from 'utils/helpers/notify';
import dayjs from 'dayjs';

const CustomShiftsController: React.FC = () => {
  const { facilityId } = useParams();

  const { setIsCalendar, clearCustomShifts, customShifts, user } = useFacilityReportStore();
  const { setCreateScheduleMenu } = useStore();

  const startScheduleTime = useStore(state => state.startScheduleTime);
  const endScheduleTime = useStore(state => state.endScheduleTime);
  const lunchDuration = useStore(state => state.lunchDuration);
  const [changeCustomSchedule] = useChangeCustomScheduleMutation({ refetchQueries: [GetSupervisorWorkSheetDocument] });

  const onSubmit = () => {
    if (user?.id && startScheduleTime && endScheduleTime && customShifts.length) {
      const currentYear = dayjs().year();
      const currentMonth = dayjs().month();

      const inputs = customShifts.map(shift => {
        const { dayIndex } = shift;

        const dateFrom = dayjs()
          .year(currentYear)
          .month(currentMonth)
          .date(dayIndex)
          .hour(dayjs(startScheduleTime).hour())
          .minute(dayjs(startScheduleTime).minute())
          .second(dayjs(startScheduleTime).second())
          .toISOString();

        const shiftEndDate = dayjs()
          .year(currentYear)
          .month(currentMonth)
          .date(dayIndex)
          .hour(dayjs(endScheduleTime).hour())
          .minute(dayjs(endScheduleTime).minute())
          .second(dayjs(endScheduleTime).second())
          .toISOString();

        return {
          userId: user?.id,
          facilityId: facilityId || '',
          positionId: user?.positionId,
          dateFrom,
          shiftEndDate,
          lunchDuration,
        };
      });

      changeCustomSchedule({
        variables: {
          inputs,
        },
      })
        .then(() => {
          successToast('Вы успешно предложили график');
          setCreateScheduleMenu(false);
          setIsCalendar(false);
          clearCustomShifts();
        })
        .catch(e => {
          errorToast(`Произошла ошибка: ${errorHandler(e)}`);
        });
    }
  };

  return (
    <div className="flex gap-8 items-center border-l-[1px] px-8">
      <div>
        Выбрано <span className="font-bold">{customShifts.length} смены</span>
      </div>
      <div className="flex gap-2">
        <button className="btn-primary" onClick={onSubmit}>
          Применить
        </button>
        <button className="btn-secondary" onClick={() => setIsCalendar(false)}>
          Отменить
        </button>
      </div>
    </div>
  );
};

export default CustomShiftsController;
