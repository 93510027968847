import Down from "assets/icons/sort/down";
import Up from "assets/icons/sort/up";

const Header: React.FC = () => {
  return (
    <div className="flex border-b-[1px] border-smena_gray-30 bg-smena_gray-5 Table-H1 uppercase sticky top-0">
      <div className="flex items-center gap-3 pt-2 pb-2 pl-4 pr-4 border-r-[1px] border-smena_gray-30 font-medium w-[200px] h-[36px]">
        Исполнитель
        <span className="flex flex-col gap-1">
          <Up />
          <Down />
        </span>
      </div>
      <div className="pt-2 pb-2 pl-4 pr-4 border-r-[1px] border-smena_gray-30 font-medium w-[150px] h-[36px]">
        Профессия
      </div>
      <div className="pt-2 pb-2 pl-4 pr-4 font-medium w-[110px] border-smena_gray-30 h-[36px]">Действие</div>
    </div>
  );
};

export default Header;
