import { ShiftStage } from 'generated/graphql';
import { ShiftFactFragment } from 'pages/moderationReport/api/fragments/generated/ShiftFact.fragment';

export const getShiftBgColorPast = (shift: ShiftFactFragment) => {
  const { stage, moderation } = shift;

  if (stage === ShiftStage.Refused) {
    return 'bg-smena_gray-40';
  }

  if (stage === ShiftStage.Finished) {
    if (moderation && !moderation.toPayment) {
      return 'bg-smena_red-light';
    }

    return 'bg-smena_green-light';
  }

  return 'bg-smena_yellow-light';
};
