import React from 'react';

const NMark: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
      <path
        d="M8.08008 5.97266V7.07129H3.89062V5.97266H8.08008ZM4.17627 2.5376V10.4624H2.82129V2.5376H4.17627ZM9.17871 2.5376V10.4624H7.81641V2.5376H9.17871Z"
        fill="white"
      />
    </svg>
  );
};

export default NMark;
