import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetFacilitiesByFacilityGroupOfFacilityIdQueryVariables = Types.Exact<{
  input: Types.GetFacilitiesByFacilityGroupOfFacilityIdInput;
}>;


export type GetFacilitiesByFacilityGroupOfFacilityIdQuery = { __typename?: 'Query', getFacilitiesByFacilityGroupOfFacilityId: Array<{ __typename?: 'Facility', id: string, name: string }> };


export const GetFacilitiesByFacilityGroupOfFacilityIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetFacilitiesByFacilityGroupOfFacilityId"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetFacilitiesByFacilityGroupOfFacilityIdInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getFacilitiesByFacilityGroupOfFacilityId"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetFacilitiesByFacilityGroupOfFacilityIdQuery__
 *
 * To run a query within a React component, call `useGetFacilitiesByFacilityGroupOfFacilityIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFacilitiesByFacilityGroupOfFacilityIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFacilitiesByFacilityGroupOfFacilityIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFacilitiesByFacilityGroupOfFacilityIdQuery(baseOptions: Apollo.QueryHookOptions<GetFacilitiesByFacilityGroupOfFacilityIdQuery, GetFacilitiesByFacilityGroupOfFacilityIdQueryVariables> & ({ variables: GetFacilitiesByFacilityGroupOfFacilityIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFacilitiesByFacilityGroupOfFacilityIdQuery, GetFacilitiesByFacilityGroupOfFacilityIdQueryVariables>(GetFacilitiesByFacilityGroupOfFacilityIdDocument, options);
      }
export function useGetFacilitiesByFacilityGroupOfFacilityIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFacilitiesByFacilityGroupOfFacilityIdQuery, GetFacilitiesByFacilityGroupOfFacilityIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFacilitiesByFacilityGroupOfFacilityIdQuery, GetFacilitiesByFacilityGroupOfFacilityIdQueryVariables>(GetFacilitiesByFacilityGroupOfFacilityIdDocument, options);
        }
export function useGetFacilitiesByFacilityGroupOfFacilityIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetFacilitiesByFacilityGroupOfFacilityIdQuery, GetFacilitiesByFacilityGroupOfFacilityIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFacilitiesByFacilityGroupOfFacilityIdQuery, GetFacilitiesByFacilityGroupOfFacilityIdQueryVariables>(GetFacilitiesByFacilityGroupOfFacilityIdDocument, options);
        }
export type GetFacilitiesByFacilityGroupOfFacilityIdQueryHookResult = ReturnType<typeof useGetFacilitiesByFacilityGroupOfFacilityIdQuery>;
export type GetFacilitiesByFacilityGroupOfFacilityIdLazyQueryHookResult = ReturnType<typeof useGetFacilitiesByFacilityGroupOfFacilityIdLazyQuery>;
export type GetFacilitiesByFacilityGroupOfFacilityIdSuspenseQueryHookResult = ReturnType<typeof useGetFacilitiesByFacilityGroupOfFacilityIdSuspenseQuery>;
export type GetFacilitiesByFacilityGroupOfFacilityIdQueryResult = Apollo.QueryResult<GetFacilitiesByFacilityGroupOfFacilityIdQuery, GetFacilitiesByFacilityGroupOfFacilityIdQueryVariables>;