import { useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { useStore } from 'store';

import { userQueryConfigFn } from 'utils/graphqlConfigs/userQueryConfig';
import { getFacilityGroupId } from 'utils/helpers/get/getFacilityGroupIdByFacilityId';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { ISO_DATE } from 'utils/helpers/constVariables';
import { useUnassignedWorkerFromFacilityMutation } from 'pages/facilities/api/mutations/generated/UnassignedWorkerFromFacility';
import { useUnassignedWorkerFromFacilityGroupMutation } from 'pages/facilities/api/mutations/generated/UnassignWorkerFromFacilityGroup';
import { useSelectFacilities } from 'utils/api/useSelectFacilities';
import Modal from 'ui/modal';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';
import { useGetFacilitiesByFacilityGroupOfFacilityIdQuery } from 'pages/facilityReport/api/queries/generated/GetFacilitiesByFacilityGroupOfFacilityId';

const StopCooperationAtAllFacilitiesPopup: React.FC = () => {
  const { facilityId } = useParams();
  const { stopCooperationDate, stopCooperationComment } = useStore();
  const { setShowStopCooperationAtAllFacilitiesPopup, showStopCooperationAtAllFacilitiesPopup, user } =
    useFacilityReportStore();
  const { data } = useGetFacilitiesByFacilityGroupOfFacilityIdQuery({
    variables: { input: { facilityId: facilityId || '', userId: user?.id } },
    skip: !user?.id,
  });
  const { facilities } = useSelectFacilities();
  const userConfig = userQueryConfigFn(user?.id);
  //wait workpost id
  const [unassignedWorkerFromFacility] = useUnassignedWorkerFromFacilityMutation({
    awaitRefetchQueries: true,
    refetchQueries: [userConfig],
  });
  //wait workpost id
  const [unassignedWorkerFromFacilityGroup] = useUnassignedWorkerFromFacilityGroupMutation({
    awaitRefetchQueries: true,
    refetchQueries: [userConfig],
  });

  const onCloseShowStopCooperationPopup = useCallback(
    () => setShowStopCooperationAtAllFacilitiesPopup(false),
    [setShowStopCooperationAtAllFacilitiesPopup],
  );

  const onChangeFacility = useCallback(() => {
    if (user?.id) {
      unassignedWorkerFromFacility({
        variables: {
          input: {
            userId: user.id,
            facilityId: facilityId || '',
            unassignAt: dayjs(stopCooperationDate).format(ISO_DATE),
            unassignComment: stopCooperationComment,
          },
        },
      })
        .then(() => successToast('Сотрудник откреплен от всех профессий объекта'))
        .catch(error => errorToast(errorHandler(error)));
    }
    onCloseShowStopCooperationPopup();
  }, [
    facilityId,
    onCloseShowStopCooperationPopup,
    stopCooperationComment,
    stopCooperationDate,
    unassignedWorkerFromFacility,
    user?.id,
  ]);

  const onChangeFacilityGroup = useCallback(() => {
    if (user?.id) {
      unassignedWorkerFromFacilityGroup({
        variables: {
          input: {
            userId: user.id,
            facilityGroupId: getFacilityGroupId(facilities, facilityId),
            unassignAt: dayjs(stopCooperationDate).format(ISO_DATE),
            unassignComment: stopCooperationComment,
          },
        },
      })
        .then(() => successToast('Сотрудник откреплен от всех профессий объекта всех объектов группы'))
        .catch(error => errorToast(errorHandler(error)));
    }
    onCloseShowStopCooperationPopup();
  }, [
    facilities,
    facilityId,
    onCloseShowStopCooperationPopup,
    stopCooperationComment,
    stopCooperationDate,
    unassignedWorkerFromFacilityGroup,
    user?.id,
  ]);

  return (
    <Modal
      title="Прекращение сотрудничества"
      open={showStopCooperationAtAllFacilitiesPopup}
      onCancel={onChangeFacilityGroup}
      onOk={onChangeFacility}
      okText="Нет, только на текущем"
      cancelText="Да, на всех объектах"
    >
      <div className="flex flex-col gap-y-4">
        <div className="Body1">Исполнитель прикреплен на других объектах группы:</div>
        <div className="flex flex-col">
          {data?.getFacilitiesByFacilityGroupOfFacilityId.map(facility => (
            <Link key={facility.id} to={`/facilities/${facility.id}`} className="text-primary" target="_blank">
              {facility.name}
            </Link>
          ))}
        </div>
        <div>Изменить статус активности и на этих объектах?</div>
      </div>
    </Modal>
  );
};

export default StopCooperationAtAllFacilitiesPopup;
